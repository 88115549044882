// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        "BJOWLIPNaScDtIkIIyBbCNPkxdDFXMUcUn7nTr89oM9cUXq16MRiF2gi9FmddnT_eGQX8UxPLHc9WlWbpK1etQ0",
      serviceWorkerRegistration,
    })
  );

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYTUaRHiTN-sQiJFvYEf7mfhvLHtrgVpg",
  authDomain: "family-gift-174ff.firebaseapp.com",
  databaseURL:
    "https://family-gift-174ff-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "family-gift-174ff",
  storageBucket: "family-gift-174ff.appspot.com",
  messagingSenderId: "1029271554250",
  appId: "1:1029271554250:web:afefe145419db67e548c33",
  measurementId: "G-845BGBQHJX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => {
      console.log("PROMISE FOREGROUND MESSAGE PAYLOAD : ", payload, resolve);
      resolve(payload);
    }));
