import { UserLightWithoutWishlist } from "./User";

export class CreateHouseDto {
  name: string;
  pictureUrl: string;
  constructor(name: string, pictureUrl: string){
    this.name = name;
    this.pictureUrl = pictureUrl;
  }
}

export class CreateSecretSantaDto {
  participants: number[];
  forbiddenGroups: number[][];
  constructor(participants: number[], forbiddenGroups: number[][]){
    this.participants = participants;
    this.forbiddenGroups = forbiddenGroups;
  }
}

export class JoinHouseDtp {
  listOfChildInThisHouse: number[];
  listOfGiftVisibleInHouse: number[];
  constructor(listOfChildInThisHouse: number[], listOfGiftVisibleInHouse: number[]){
    this.listOfChildInThisHouse = listOfChildInThisHouse;
    this.listOfGiftVisibleInHouse = listOfGiftVisibleInHouse;
  }
}

export class House {
  id: number;
  name: string;
  pictureUrl: string;
  code: string;
  secretSanta: SecretSantaEntry[];
  users: UserLightWithoutWishlist[];

  constructor(
    id: number,
    name: string,
    pictureUrl: string,
    code: string,
    secretSanta: SecretSantaEntry[],
    users: UserLightWithoutWishlist[]
  ) {
    this.id = id;
    this.name = name;
    this.pictureUrl = pictureUrl;
    this.code = code;
    this.secretSanta = secretSanta;
    this.users = users;
  }
}

export class HouseWithoutUsers {
    id: number;
    name: string;
    pictureUrl: string;
    code: string;
  
    constructor(
      id: number,
      name: string,
      pictureUrl: string,
      code: string,
    ) {
      this.id = id;
      this.name = name;
      this.pictureUrl = pictureUrl;
      this.code = code;
    }
}

export class SecretSantaEntry {
    giver: UserLightWithoutWishlist;
    receiver: UserLightWithoutWishlist;

    constructor(
        giver: UserLightWithoutWishlist,
        receiver: UserLightWithoutWishlist
    ) {
        this.giver = giver;
        this.receiver = receiver;
    }
}
