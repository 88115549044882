import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

function MyNetworkImage(props: {
  alt: String;
  url: String | undefined | null;
  cover: boolean;
  showBorder?: boolean;
}) {
  const [hasImage, setHasImage] = useState(
    props.url !== null &&
      props.url !== undefined &&
      (props.url as String).length > 0
  );
  const theme = useTheme();

  useEffect(() => {
    setHasImage(props.url ? true : false)
  }, [props.url])

  return (
    <Box
      sx={{
        zIndex: 1,
        display: "flex",
        position: "relative",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        justifyItems: "center",
      }}
    >
      {hasImage ? (
       <Box 
        sx={{
          height: "100%",
          width: props.cover ? "100%" : "auto"
        }}>
        <img
          key={`${props.url}`}
          srcSet={`${props.url}`}
          src={`${props.url}`}
          alt={`${props.alt}`}
          loading="lazy"
          draggable="false"
          onLoad={() => setHasImage(true)}
          onError={() => setHasImage(false)}
          style={{
            borderRadius: 16,
            margin: "auto",
            display: "inline-block",
            objectFit: props.cover ? "cover" : "contain",
            boxSizing:"border-box",
            width: "100%" ,
            height: "100%",
          }}
        />
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: "primary.light",
            borderRadius: "16px",
            boxSizing: "border-box",
            border: props.showBorder === false ? "" : "3px solid white",
            padding: props.showBorder === false ? "8%" : "4%",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <img
            key={`${props.alt}`}
            src="/assets/family_gift_logo_simple.svg"
            alt={`${props.alt}`}
            loading="eager"
            draggable="false"
            style={{
              boxSizing:"border-box",
              padding:"5%",
              margin: "auto",
              display: "inline-block",
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default MyNetworkImage;
