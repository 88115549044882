import React from "react";
import { House } from "../../../../../models/House";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function InfoSecretSanta(props: { house: House }) {

  const {t} = useTranslation();
  return (
    <div>
      <Typography>{t("secretSantaForX",{value: props.house.name})}</Typography>
    </div>
  );
}

export default InfoSecretSanta;
