import {
  Box,
  ButtonBase,
  Collapse,
  Divider,
  Rating,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../../../models/User";
import { AuthContext } from "../../../../api/AuthProvider";
import { Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Check, Favorite } from "@mui/icons-material";
import { House, HouseWithoutUsers } from "../../../../models/House";
import MyNetworkImage from "../../../ReusableComponents/MyNetworkImage";
import { AxiosError } from "axios";
import UploadableNetworkImage from "../../../ReusableComponents/UploadableNetworkImage";
import { apiClient } from "../../../../api/api";
import { getDecodedToken } from "../../../../localStorageUtils";
import { useMutation } from "react-query";
import { AddGiftDto, UrlBody } from "../../../../models/Gift";
import { useSnackbar } from "notistack";
import MyWaitingDialog from "../../../ReusableComponents/MyWaitingDialog";
import AvatarPicture from "../../../ReusableComponents/AvatarPicture";
import MySwitch from "../../../ReusableComponents/MySwitch";
import { PrimaryButton } from "../HouseHolds/HouseDetailPage";
import { useTranslation } from "react-i18next";

function AddGiftPage() {
  const location = useLocation();
  const auth = useContext(AuthContext);
  var navigate = useNavigate();
  const { t, i18n } = useTranslation();

  let userToAddGift = (location.state?.user ?? null) as User | null;
  let [giftName, setGiftName] = useState("");
  let [giftPrice, setGiftPrice] = useState<number | string>("");
  let [giftUrl, setGiftUrl] = useState("");
  let [giftAffiliatedUrl, setGiftAffiliatedUrl] = useState("");
  let [giftComment, setGiftComment] = useState("");
  let [applyRate, setApplyRate] = useState(false);
  let [rate, setRate] = useState(0);
  let [canBeTakenMultipleTime, setCanBeTakenMultipleTime] = useState(false);
  let [imageUrl, setImageUrl] = useState("");
  let [giftInfoFetch, setGiftInfosFetch] = useState(false);
  let [giftAddToList, setGiftAddToList] = useState(false);

  let addOnMyList = userToAddGift?.id == auth?.user?.id;

  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );

  document.title = t("addAnIdea");

  let [houseSelected, setHouseSelected] = useState<number[]>(
    (userToAddGift?.houses ?? []).map((item, _) => {
      return item.id;
    })
  );

  const addGiftSubmitForm = async (event: any) => {
    event.preventDefault();
    var value = giftPrice.toString().replace(",", ".");
    let actualPrice = parseFloat(parseFloat(value).toFixed(2));
    let myRate = null;
    if (applyRate) {
      myRate = rate;
    }
    var dto = new AddGiftDto(
      giftName,
      actualPrice,
      imageUrl,
      giftUrl,
      giftAffiliatedUrl,
      giftComment,
      myRate,
      houseSelected,
      canBeTakenMultipleTime
    );
    setGiftAddToList(true);
    apiClient
      .addGiftOnList(dto, auth?.token ?? "", userToAddGift?.id ?? 0)
      .then((data) => {
        auth?.refreshUser();
        setGiftAddToList(false);
        enqueueSnackbar(t("ideaCorrectlyAdded"), {
          variant: "success",
        });
        navigate(-1);
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const handleCanBeTakenSwitch = (event: boolean) => {
    setCanBeTakenMultipleTime(event);
  };

  const handleSwitch = (event: boolean) => {
    setApplyRate(event);
  };

  let theme = useTheme();
  const innerTheme = createTheme(theme);

  return userToAddGift ? (
    <Box
      sx={{
        margin: "auto",
        maxWidth: "1400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <MyWaitingDialog
        open={giftInfoFetch}
        message={t("retrievingInformations")}
      />
      <MyWaitingDialog
        open={giftAddToList}
        message={
          addOnMyList
            ? t("addIdeaOnYourList")
            : t("addingIdeaOnXList", { value: userToAddGift?.username })
        }
      />

      <Box
        sx={{
          width: "100%",
          padding: "8px",
          boxSizing: "border-box",
        }}
      >
        <form onSubmit={addGiftSubmitForm} style={{ width: "100%" }}>
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
                borderRadius: "16px",
                border: "solid 2px",
                margin: "8px",
                borderColor: "primary.light",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                  height: "100%",
                  padding: "8px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <AvatarPicture
                  url={userToAddGift.photoUrl}
                  alt={userToAddGift.username}
                />
              </Box>
              <Typography
                padding={"8px"}
                textAlign={"center"}
                fontWeight={"bold"}
                fontSize={20}
                color={"primary"}
              >
                {addOnMyList
                  ? t("addIdeaOnYourListTitle")
                  : t("addIdeaOnXListTitle", { value: userToAddGift?.username })}
              </Typography>
            </Box>

            <Box
              sx={{
                background:
                  "linear-gradient(75deg, #E1D5FF, #ffffff 50%,#FFDFEFFF)",
                borderColor: "primary.light",
                borderRadius: "16px",
                marginTop: "24px",
                boxSizing: "border-box",
                padding: "24px",
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "16px",
                  display: "flex",
                  width: isMobile ? "100%" : "50%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "200px", height: "200px" }}>
                  <UploadableNetworkImage
                    initialUrl={imageUrl}
                    alt={"Image upload"}
                    onUrlChange={(url) => {
                      setImageUrl(url);
                    }}
                  />
                </Box>
                <TextField
                  required
                  variant="outlined"
                  onChange={(e) => setGiftName(e.target.value)}
                  error={giftName.length == 0}
                  value={giftName}
                  fullWidth
                  color="primary"
                  type="text"
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  label={t("giftTitle")}
                  sx={{
                    marginTop: "16px",
                    background: "white",
                    borderRadius: "16px",
                  }}
                />

                <TextField
                  variant="outlined"
                  value={giftPrice}
                  onChange={(e) => {
                    var value = e.target.value.replace(",", ".");
                    let actualPrice = parseFloat(parseFloat(value).toFixed(2));
                    if (actualPrice) {
                      setGiftPrice(actualPrice);
                    } else {
                      setGiftPrice("");
                    }
                  }}
                  fullWidth
                  color="primary"
                  type="number"
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  label={t("giftPrice")}
                  sx={{
                    marginTop: "16px",
                    background: "white",
                    borderRadius: "16px",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "stretch",
                  }}
                >
                  <TextField
                    variant="outlined"
                    onChange={(e) => {
                      setGiftUrl(e.target.value);
                    }}
                    value={giftUrl}
                    fullWidth
                    color="primary"
                    type="url"
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                      },
                    }}
                    label={t("giftUrl")}
                    sx={{
                      marginTop: "16px",
                      background: "white",
                      borderRadius: "16px",
                    }}
                  />
                  <Button
                    onClick={async (event) => {
                      var token = getDecodedToken();
                      if (token && giftUrl.length !== 0) {
                        setGiftInfosFetch(true);
                        apiClient
                          .getProductInfos(new UrlBody(giftUrl), token)
                          .then((result) => {
                            var data = result.data;
                            setGiftName(data.name);
                            let actualPrice = parseFloat(
                              parseFloat(data.price).toFixed(2)
                            );
                            if (actualPrice) {
                              setGiftPrice(actualPrice);
                            } else {
                              setGiftPrice("");
                            }
                            setImageUrl(data.imageUrl);
                            setGiftAffiliatedUrl(data.affiliatedUrl);
                            setGiftInfosFetch(false);
                          })
                          .catch(() => {
                            enqueueSnackbar(t("errorRetrieving"), {
                              variant: "error",
                            });
                            setGiftInfosFetch(false);
                          });
                      } else {
                        enqueueSnackbar(t("errorInvalidLink"), {
                          variant: "warning",
                        });
                      }
                    }}
                    sx={{
                      margin: "16px 4px 4px 4px",
                      backgroundColor: "#ffffff",
                      textTransform: "none",
                      borderRadius: "16px",
                      boxSizing: "border-box",
                      border: "3px solid",
                      fontWeight: "bold",
                      borderColor: "primary.main",
                      "&:hover": {
                        bgcolor: "primary.light",
                      },
                    }}
                  >
                    {t("retrieve")}
                  </Button>
                </Box>

                <TextField
                  variant="outlined"
                  onChange={(e) => {
                    setGiftComment(e.target.value);
                  }}
                  value={giftComment}
                  fullWidth
                  multiline
                  minRows={3}
                  color="primary"
                  type="text"
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  label={t("additionalComments")}
                  sx={{
                    marginTop: "16px",
                    background: "white",
                    borderRadius: "16px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: isMobile ? "100%" : "50%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      fontWeight={applyRate ? "bold" : "normal"}
                      color={"primary"}
                      padding={"4px 24px 4px 4px"}
                      fontSize={20}
                      textAlign={"center"}
                    >
                      {t("rateThisGift")}
                    </Typography>
                    <MySwitch checked={applyRate} onChange={handleSwitch} />
                  </Box>

                  <Collapse
                    in={applyRate}
                    sx={{
                      bgcolor: innerTheme.palette.primary.light,
                      padding: "0px 16px",
                      paddingTop: "4px",
                      borderRadius: "150px",
                    }}
                  >
                    <Rating
                      name="simple-controlled"
                      precision={1}
                      value={rate}
                      defaultValue={3}
                      onChange={(event, newValue) => {
                        setRate(newValue ?? 0);
                      }}
                      max={5}
                      icon={<Favorite color="primary" />}
                      emptyIcon={<Favorite color="secondary" />}
                    />
                  </Collapse>
                </Box>
                <Divider
                  sx={{
                    marginTop: "16px",
                    width: "80%",
                  }}
                />
                <Box
                  sx={{
                    maxWidth: "800px",
                    marginTop: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      sx={{ flexGrow: 1 }}
                      fontWeight={canBeTakenMultipleTime ? "bold" : "normal"}
                      padding={"4px 24px 4px 4px"}
                      color={"primary"}
                      fontSize={20}
                      textAlign={"center"}
                    >
                      {t("takenMultipleTime")}
                    </Typography>
                    <MySwitch
                      checked={canBeTakenMultipleTime}
                      onChange={handleCanBeTakenSwitch}
                    />
                  </Box>
                  <Typography
                    sx={{ flexGrow: 1 }}
                    fontWeight={"normal"}
                    fontStyle={"oblique"}
                    color={"black"}
                    fontSize={15}
                    textAlign={"center"}
                  >
                    {t("explainGiftTakenMultipleTimes1")}
                  </Typography>
                  <Typography
                    sx={{ flexGrow: 1 }}
                    fontWeight={"normal"}
                    color={"black"}
                    fontStyle={"oblique"}
                    fontSize={15}
                    textAlign={"center"}
                  >
                    {t("explainGiftTakenMultipleTimes2")}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    marginTop: "16px",
                    width: "80%",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "16px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ flexGrow: 1 }}
                    fontWeight={"bold"}
                    color={"primary"}
                    textAlign={"center"}
                    fontSize={20}
                  >
                    {t("visibleHomes")}
                  </Typography>
                  <Typography
                    sx={{ flexGrow: 1 }}
                    fontWeight={"normal"}
                    color={"black"}
                    fontStyle={"oblique"}
                    fontSize={15}
                    textAlign={"center"}
                  >
                    {t("visibleHomesExplain")}
                    </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      padding: "8px",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {userToAddGift.houses
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item, _) => {
                        return (
                          <HouseVisibilityItem
                            house={item}
                            selected={
                              houseSelected?.find(
                                (itemId) => item?.id === itemId
                              ) != null
                            }
                            onClick={(event) => {
                              var selected =
                                houseSelected?.find(
                                  (itemId) => item?.id === itemId
                                ) != null;
                              if (selected)
                                setHouseSelected(
                                  houseSelected?.filter(
                                    (itemId) => itemId !== item.id
                                  )
                                );
                              else
                                setHouseSelected(
                                  houseSelected?.concat(item.id)
                                );
                            }}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </Box>
            <PrimaryButton
              sx={{
                maxWidth: "800px",
              }}
              type="submit"
            >
              { addOnMyList
                  ? t("addIdeaOnYourListTitle")
                  : t("addIdeaOnXListTitle", { value: userToAddGift?.username }) }
            </PrimaryButton>
          </Box>
        </form>
      </Box>
    </Box>
  ) : (
    <Typography>Error loading this page, please try again later</Typography>
  );
}

export function HouseVisibilityItem(props: {
  house: House | HouseWithoutUsers;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <ButtonBase
      sx={{
        borderRadius: "18px",
        opacity: props.selected ? "1" : "0.6",
        filter: props.selected ? "none" : "grayscale(100%)",
        transition: "all 0.3s",
        "&:hover": {
          scale: "1.1",
          zIndex: "150",
        },
      }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          display: "inline-block",
          flexDirection: "column",
          borderRadius: "18px",
          width: "120px",
          bgcolor: "#ffffff",
          border: "2px solid",
          borderColor: "primary.main",
          height: "170px",
          transition: "all 0.3s",
          "&:hover": {
            bgcolor: "primary.light",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "150px",
            boxSizing: "border-box",
            border: "3px solid",
            borderColor: "primary.main",
            position: "absolute",
            bgcolor: "#ffffff",
            zIndex: "130",
            right: 8,
            top: 4,
            alignItems: "center",
            opacity: props.selected ? "100%" : "0%",
            transition: "all 0.3s",
          }}
        >
          <Check sx={{ marginTop: "0", marginBottom: "0" }} color="primary" />
        </Box>
        <Box
          sx={{
            aspectRatio: "1/1",
            width: "100%",
          }}
        >
          <MyNetworkImage
            url={props.house.pictureUrl}
            alt={props.house.name}
            cover={true}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyItems: "center",
            alignItems: "center",
            height: "50px",
          }}
        >
          <Typography
            width={"100%"}
            textAlign={"center"}
            fontSize={14}
            fontWeight={"bold"}
            color={"primary"}
          >
            {props.house.name}
          </Typography>
        </Box>
      </Box>
      ;
    </ButtonBase>
  );
}

export const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default AddGiftPage;
