import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { NavLink, useNavigate } from "react-router-dom";
import { postData } from "../../api/apiService";

function ContactPage() {
  const {t, i18n} = useTranslation();
  var languageCodeUsed = i18n.language.split("-")[0];
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sendCopy, setSendCopy] = useState(true);
  const handleChangeCopy = (event) => {
    setSendCopy(!sendCopy);
  };
  
  const mutation = useMutation((data) => postData('/contactTheDevelopper', data));
  var navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(email, subject, message, sendCopy, languageCodeUsed);
    var data = {
      email: email,
      subject: subject,
      content: message,
      sendCopy: sendCopy,
      languageCode: languageCodeUsed
    };
    mutation.mutate(data);
  };

  return (
    <Box
      sx={{
        margin: "auto",
        marginTop: "16px",
        borderRadius: "40px",
        bgcolor: "primary.light",
        padding: "20px",
        width: "auto",
        maxWidth: "800px",
        alignContent: "center",
        justifyContent: "center",
        justifyItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        textAlign={"center"}
        color={"primary"}
        margin="8px"
        padding="8px"
        fontWeight={"600"}
      >
        {t("contactMe")}
      </Typography>

      <Typography
        variant="p"
        textAlign={"center"}
        whiteSpace={"pre-line"}
        color={"dark"}
        margin="8px"
        lineHeight={"22px"}
        display={"inline-block"}
        padding="8px"
        style={{ wordWrap: "break-word" }}
        fontWeight={"400"}
      >
        {t("contactMeExplain")}
      </Typography>
      {mutation.isLoading ? (
        <Typography
          variant="h4"
          textAlign={"center"}
          color={"primary"}
          margin="8px"
          padding="8px"
          fontWeight={"600"}
        >
          {t("sendingMessage")}
        </Typography>
      ) : mutation.isSuccess ? (
        <Box>
          <Typography
            variant="h4"
            textAlign={"center"}
            color={"primary"}
            margin="8px"
            padding="8px"
            fontWeight={"600"}
          >
            {t("messageSendingSuccess")}
          </Typography>
          <Button
          onClick={() => {
            navigate(-1)
          }} 
          sx={{
            marginTop: "24px",
          }}
          variant="contained"
          color="primary"
          >{t("goBackHome")}
          </Button>
        </Box>
      ) : mutation.isError ? (
        <Box>
          <Typography
            variant="h4"
            textAlign={"center"}
            color={"primary"}
            margin="8px"
            padding="8px"
            fontWeight={"600"}
          >
            {t("messageSendingError")}
          </Typography>
          <Typography
            variant="h5"
            textAlign={"center"}
            color={"primary"}
            margin="8px"
            padding="8px"
            fontWeight={"500"}
          >
            {t("sorryForTheError")}
          </Typography>
          <Typography
            variant="h5"
            textAlign={"center"}
            color={"primary"}
            margin="8px"
            padding="8px"
            fontWeight={"600"}
          >
            family.gift.app@gmail.com
          </Typography>
          <NavLink to="/">
          <Button
          sx={{
            marginTop: "24px",
          }}
          variant="contained"
          color="primary"
          >{t("goBackHome")}</Button></NavLink>
        </Box>
      ):
      (
        <form onSubmit={handleSubmit}>
          <TextField
            required
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
            color="primary"
            type="email"
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            label={t("yourMailAddress")}
            sx={{
              marginTop:"16px",
              background: "white",
              borderRadius: "16px",
            }}
          />

          <TextField
            required
            variant="outlined"
            label={t("yourSubject")}
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            color="primary"
            type="text"
            sx={{
              marginTop:"16px",
              background: "white",
              borderRadius: "16px",
            }}
          />

          <TextField
            label={t("yourMessage")}
            required
            multiline
            minRows={5}
            variant="outlined"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            fullWidth
            color="primary"
            type="text"
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            sx={{
              marginTop:"16px",
              background: "white",
              borderRadius: "16px",
            }}
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            sx={{
              width: "auto",
              maxWidth: "800px",
            }}
          >
            <Checkbox
              color="primary"
              checked={sendCopy}
              onChange={handleChangeCopy}
            />
            <Typography
              variant="p"
              textAlign={"center"}
              whiteSpace={"pre-line"}
              color={sendCopy ? "primary" : "dark"}
              display={"inline-block"}
              style={{ wordWrap: "break-word" }}
              fontWeight={sendCopy ? "bold" : "400"}
            >
              {t("iWantACopyOfTheMail")}
            </Typography>
          </Box>
          <Button
            sx={{
              marginTop: "24px",
            }}
            variant="contained"
            color="primary"
            type="submit"
          >
            {t("sendMessage")}
          </Button>
        </form>
      )}
    </Box>
  );
}

export default ContactPage;
