import {
  Box,
  ButtonBase,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Gift, UpdateGiftDto } from "../../../../models/Gift";
import MyWaitingDialog, {
  MyPaperPropsDialog,
} from "../../../ReusableComponents/MyWaitingDialog";
import { OutlinedButton } from "../HouseHolds/HouseDetailPage";
import UploadableNetworkImage from "../../../ReusableComponents/UploadableNetworkImage";
import { Check, Favorite } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { HouseWithoutUsers } from "../../../../models/House";
import MyNetworkImage from "../../../ReusableComponents/MyNetworkImage";
import { apiGiftClient } from "../../../../api/api";
import { AuthContext } from "../../../../api/AuthProvider";
import { enqueueSnackbar } from "notistack";
import MySwitch from "../../../ReusableComponents/MySwitch";
import { useTranslation } from "react-i18next";

function EditGiftDialog(props: {
  open: boolean;
  closeDialog: (newGift: Gift | null) => void;
  gift: Gift;
}) {
  let [giftName, setGiftName] = useState(props.gift.name);
  let [giftPicture, setGiftPicture] = useState(props.gift.photoUrl ?? "");
  let [giftUrl, setGiftUrl] = useState(props.gift.giftUrl ?? "");
  let [giftPrice, setGiftPrice] = useState<number | string>(
    props.gift.price ?? ""
  );
  let [giftComment, setGiftComment] = useState(props.gift.giftComment ?? "");
  let [applyRate, setApplyRate] = useState(
    props.gift.rate != null && props.gift.rate > 0
  );
  let [rate, setRate] = useState(props.gift.rate);
  let [canBeTakenMultipleTime, setCanBeTakenMultipleTime] = useState(
    props.gift.canBeTakenMultipleTime
  );
  let [houseSelected, setHouseSelected] = useState<number[]>(
    (props.gift.listHouseVisible ?? []).map((item, _) => {
      return item.id;
    })
  );
  const handleCanBeTakenSwitch = (event: boolean) => {
    setCanBeTakenMultipleTime(event);
  };
  const handleSwitch = (event: boolean) => {
    setApplyRate(event);
  };
  let [editInProgress, setEditInProgress] = useState(false);
  let theme = useTheme();
  const innerTheme = createTheme(theme);
  const auth = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  return (
    <Dialog PaperProps={MyPaperPropsDialog} open={props.open} scroll="body">
      <MyWaitingDialog open={editInProgress} message={t("updatingYourIdea")} />
      <DialogTitle id="responsive-dialog-title">
        <Typography fontWeight={"bold"} color={"primary"} textAlign={"center"}>
          {t("editX", { value: props.gift.name })}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          overflow={"visible"}
        >
          <Box width={"150px"} height={"150px"} overflow={"visible"}>
            <UploadableNetworkImage
              initialUrl={giftPicture}
              alt={"Upload image"}
              onUrlChange={(url) => {
                setGiftPicture(url);
              }}
            />
          </Box>
        </Box>

        <TextField
          required
          variant="outlined"
          value={giftName}
          onChange={(e) => {
            setGiftName(e.target.value);
          }}
          fullWidth
          color="primary"
          type="text"
          InputProps={{
            style: {
              borderRadius: "16px",
            },
          }}
          label={t("giftTitle")}
          sx={{
            marginTop: "16px",
            background: "white",
            borderRadius: "16px",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
        <TextField
          variant="outlined"
          value={giftUrl}
          onChange={(e) => {
            setGiftUrl(e.target.value);
          }}
          fullWidth
          color="primary"
          type="url"
          InputProps={{
            style: {
              borderRadius: "16px",
            },
          }}
          label={t("giftUrl")}
          sx={{
            marginTop: "16px",
            background: "white",
            borderRadius: "16px",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
        <TextField
          variant="outlined"
          value={giftPrice}
          onChange={(e) => {
            var value = e.target.value.replace(",", ".");
            let actualPrice = parseFloat(parseFloat(value).toFixed(2));
            if (actualPrice) {
              setGiftPrice(actualPrice);
            } else {
              setGiftPrice("");
            }
          }}
          fullWidth
          color="primary"
          type="number"
          InputProps={{
            style: {
              borderRadius: "16px",
            },
          }}
          label={t("giftPrice")}
          sx={{
            marginTop: "16px",
            background: "white",
            borderRadius: "16px",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
        <TextField
          variant="outlined"
          onChange={(e) => {
            setGiftComment(e.target.value);
          }}
          value={giftComment}
          fullWidth
          multiline
          minRows={3}
          color="primary"
          type="text"
          InputProps={{
            style: {
              borderRadius: "16px",
            },
          }}
          label={t("additionalComments")}
          sx={{
            marginTop: "16px",
            background: "white",
            borderRadius: "16px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#ffffff",
            boxSizing: "border-box",
            marginTop: "16px",
            borderRadius: "16px",
            padding: "8px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Typography
              fontWeight={applyRate ? "bold" : "normal"}
              color={"primary"}
              padding={"4px 24px 4px 4px"}
              fontSize={16}
              textAlign={"center"}
            >
              {t("rateThisGift")}
            </Typography>
            <MySwitch checked={applyRate} onChange={handleSwitch} />
          </Box>

          <Collapse
            in={applyRate}
            sx={{
              bgcolor: innerTheme.palette.primary.light,
              padding: "0px 16px",
              paddingTop: "4px",
              borderRadius: "150px",
            }}
          >
            <Rating
              name="simple-controlled"
              precision={1}
              value={rate}
              defaultValue={3}
              onChange={(event, newValue) => {
                setRate(newValue ?? 0);
              }}
              max={5}
              icon={<Favorite color="primary" />}
              emptyIcon={<Favorite color="secondary" />}
            />
          </Collapse>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#ffffff",
            boxSizing: "border-box",
            marginTop: "16px",
            borderRadius: "16px",
            padding: "8px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              fontWeight={canBeTakenMultipleTime ? "bold" : "normal"}
              padding={"4px 24px 4px 4px"}
              color={"primary"}
              fontSize={16}
              textAlign={"center"}
            >
              {t("takenMultipleTime")}
            </Typography>
            <MySwitch
              checked={canBeTakenMultipleTime}
              onChange={handleCanBeTakenSwitch}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#ffffff",
            boxSizing: "border-box",
            marginTop: "16px",
            borderRadius: "16px",
            padding: "8px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ flexGrow: 1 }}
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
            fontSize={20}
          >
            {t("visibleHomes")}
          </Typography>
          <Typography
            sx={{ flexGrow: 1 }}
            fontWeight={"normal"}
            color={"black"}
            fontStyle={"oblique"}
            fontSize={15}
            textAlign={"center"}
          >
            {t("visibleHomesExplain")}
          </Typography>
          <Box
            sx={{
              width: "100%",
              padding: "8px",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            {props.gift.owner.houses
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .map((item, _) => {
                return (
                  <HouseVisibilityItem
                    key={item.id}
                    house={item}
                    selected={
                      houseSelected?.find((itemId) => item?.id == itemId) !=
                      null
                    }
                    onClick={(event) => {
                      var selected =
                        houseSelected?.find((itemId) => item?.id == itemId) !=
                        null;
                      if (selected)
                        setHouseSelected(
                          houseSelected?.filter((itemId) => itemId !== item.id)
                        );
                      else setHouseSelected(houseSelected?.concat(item.id));
                    }}
                  />
                );
              })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          variant="outlined"
          onClick={() => {
            props.closeDialog(null);
          }}
        >
          {t("cancel")}
        </OutlinedButton>
        <OutlinedButton
          color="primary"
          variant="outlined"
          onClick={() => {
            setEditInProgress(true);
            var value = giftPrice.toString().replace(",", ".");
            let actualPrice = parseFloat(parseFloat(value).toFixed(2));
            let myRate = null;
            if (applyRate) {
              myRate = rate;
            } else {
              myRate = 0;
            }

            const updateGiftDto = new UpdateGiftDto(
              giftName,
              actualPrice,
              giftPicture,
              giftUrl,
              giftComment,
              myRate as number,
              houseSelected,
              canBeTakenMultipleTime
            );
            apiGiftClient
              .updateGift(auth?.token ?? "", props.gift.id, updateGiftDto)
              .then((response) => {
                var newGift = response.data;
                setEditInProgress(false);
                props.closeDialog(newGift);
                if (newGift.owner.id == auth?.user?.id) {
                  auth.refreshUser();
                }
                enqueueSnackbar("Idée mise à jour !", { variant: "success" });
              })
              .catch((error) => {
                setEditInProgress(false);
                enqueueSnackbar("Erreur lors de l'update de votre cadeau", {
                  variant: "error",
                });
              });
          }}
          //disabled={errorGift}
        >
          {t("validate")}
        </OutlinedButton>
      </DialogActions>
    </Dialog>
  );
}

function HouseVisibilityItem(props: {
  house: HouseWithoutUsers;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <ButtonBase
      sx={{
        borderRadius: "18px",
        opacity: props.selected ? "1" : "0.6",
        filter: props.selected ? "none" : "grayscale(100%)",
        transition: "all 0.3s",
        "&:hover": {
          scale: "1.1",
          zIndex: "150",
        },
      }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          display: "inline-block",
          flexDirection: "column",
          borderRadius: "17px",
          width: "100px",
          bgcolor: "#ffffff",
          border: "1px solid",
          borderColor: "primary.main",
          height: "150px",
          transition: "all 0.3s",
          "&:hover": {
            bgcolor: "primary.light",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "150px",
            boxSizing: "border-box",
            border: "2px solid",
            borderColor: "primary.main",
            position: "absolute",
            bgcolor: "#ffffff",
            zIndex: "130",
            right: 8,
            top: 4,
            alignItems: "center",
            opacity: props.selected ? "100%" : "0%",
            transition: "all 0.3s",
          }}
        >
          <Check sx={{ marginTop: "0", marginBottom: "0" }} color="primary" />
        </Box>
        <Box
          sx={{
            aspectRatio: "1/1",
            width: "100%",
          }}
        >
          <MyNetworkImage
            url={props.house.pictureUrl}
            alt={props.house.name}
            cover={true}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyItems: "center",
            alignItems: "center",
            height: "50px",
          }}
        >
          <Typography
            width={"100%"}
            textAlign={"center"}
            fontSize={14}
            fontWeight={"bold"}
            color={"primary"}
          >
            {props.house.name}
          </Typography>
        </Box>
      </Box>
      ;
    </ButtonBase>
  );
}

export default EditGiftDialog;
