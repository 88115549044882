import { getCurrency } from "@brixtol/currency-codes";
import { Theme } from "@emotion/react";
import { SxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export enum TagSize {
  S, M, L
}
function PriceTag(props: {
  priceToShow: number;
  big: TagSize;
  line: boolean;
  sx: SxProps<Theme>;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);


    var height = props.big ? 90 : 35;
    var width = props.big ? 200 : 90;
    var cornerRadius = props.big ? 16 : 8;
    var appendixSize = props.big ? 50 : 20;
    var fontSize = props.big ? 30:15;

    switch(props.big){
      case TagSize.S : {
        var height = 25;
        var width = 70;
        var cornerRadius = 6;
        var appendixSize = 15;
        var fontSize = 12;
        break;
      }
      case TagSize.M : {
        var height = 35;
        var width = 90;
        var cornerRadius = 8;
        var appendixSize = 20;
        var fontSize = 15;
        break;
      }
      case TagSize.L : {
        var height = 70;
        var width = 150;
        var cornerRadius = 14;
        var appendixSize = 40;
        var fontSize = 30;
        break;
      }

    }

  useEffect(() => {
    if (canvasRef.current) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');  
        if (context) {
            context.fillStyle = '#E1D5FFFF';
            context.strokeStyle = '#ffffff';
            context.lineWidth = 2;
            context.beginPath();
            context.moveTo(appendixSize + cornerRadius, 0);
            context.lineTo(width - cornerRadius, 0)
            context.lineTo(width - cornerRadius, 0)
            context.quadraticCurveTo(width, 0, width, cornerRadius)
            context.lineTo(width, height - cornerRadius)
            context.quadraticCurveTo(width, height, width - cornerRadius, height)
            context.lineTo(appendixSize + cornerRadius, height)
            context.quadraticCurveTo(appendixSize, height, appendixSize - cornerRadius, height - cornerRadius)
            context.lineTo(cornerRadius, height/2 + cornerRadius)
            context.quadraticCurveTo(0, height/2, cornerRadius, height/2 - cornerRadius);
            context.lineTo(appendixSize - cornerRadius, cornerRadius)
            context.quadraticCurveTo(appendixSize, 0, appendixSize + cornerRadius, 0)
            context.closePath()
            context.fill(); 
            context.stroke();

            context.beginPath();
            context.moveTo(appendixSize + cornerRadius, cornerRadius)
            context.lineTo(appendixSize + cornerRadius, height - cornerRadius)
            context.lineJoin="round";
            context.fillStyle = '#FFFFFF';
            context.lineWidth = 3;
            context.stroke();

            context.beginPath();
            context.arc((appendixSize + cornerRadius)/2, height/2, cornerRadius/2, 0, 2 * Math.PI);
            context.fill();
            
        }
    }       
},[props.big]);
  const {t, i18n} = useTranslation();
  return (
    props.priceToShow ? 
    <Box 
    className={"priceTag"}
        sx={props.sx} 
        
      style={{
        filter:props.line ? "grayscale(100%)" : "none",

        width:width,
        height:height
    }}
    >
      <canvas ref={canvasRef} width={width} height={height} />
      <Box
      sx={{
            position:"absolute",
            height:height,
            width:width - appendixSize*1.3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            top:"0",
            bottom:"0",
            right:"0"
        }}>
        <Typography
        sx={{
          textDecorationLine: props.line ? "line-through" : "none",
          textDecorationThickness:"0.2rem"
        }}
        textAlign={"center"}
        fontSize={fontSize}
        fontWeight={"bold"}
        color={"primary"}
      > {getPriceWithCurrency(i18n.language, props.priceToShow)}
        </Typography>
      </Box>
    </Box> : <></>
  );
}

export function getPriceWithCurrency(language: string, price: number) : string {
  
  var countryCode = new Intl.Locale(language).region??"fr";
  
  return new Intl.NumberFormat(language, {
    style:"currency",
    currency: getCurrency(countryCode),
    unitDisplay:"short"
  }).format(price)
}

export default PriceTag;
