import React, { useContext, useEffect, useState } from "react";
import { CreateSecretSantaDto, House } from "../../../../../models/House";
import { Box, ButtonBase, Divider, Grid, Typography } from "@mui/material";
import { UserLightWithoutWishlist } from "../../../../../models/User";
import AvatarPicture from "../../../../ReusableComponents/AvatarPicture";
import { Check } from "@mui/icons-material";
import { OutlinedButton, PrimaryButton } from "../HouseDetailPage";
import ForbiddenGroupsPage from "./ForbiddenGroupsPage";
import { clone } from "./ForbiddenGroupsPage";
import { apiClient, apiHouseClient } from "../../../../../api/api";
import { AuthContext } from "../../../../../api/AuthProvider";
import MyWaitingDialog from "../../../../ReusableComponents/MyWaitingDialog";
import { useNavigate, useNavigation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function CreateSecretSanta(props: { house: House }) {
  const {t} = useTranslation();

  var [participants, setParticipants] = useState<UserLightWithoutWishlist[]>(
    props.house.users
  );
  var [forbiddenGroups, setForbiddenGroups] = useState<
    UserLightWithoutWishlist[][]
  >([]);

  var [forbiddenGroupsPageOpen, setForbiddenGroupsPageOpen] = useState(false);

  useEffect(() => {
    const onHashChange = () => {
      setForbiddenGroupsPageOpen(window.location.hash === "#forbidden_groups");
    };
    window.addEventListener("hashchange", onHashChange);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, []);

  useEffect(() => {
    var lists = clone(forbiddenGroups);
    props.house.users.forEach((user, i) => {
      var participe =
        participants.find((item) => item.id == user.id) != undefined;
      if (!participe) {
        lists = lists.filter((list) => {
          return list.find((item) => user.id == item.id) == undefined;
        });
      }
    });
    setForbiddenGroups(clone(lists));
  }, [participants]);

  return (
    <>
      {forbiddenGroupsPageOpen ? (
        <ForbiddenGroupsPage
          house={props.house}
          participants={participants}
          forbidenGroups={clone(forbiddenGroups)}
          onValidate={(list) => {
            setForbiddenGroups(list);
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={20}
            color={"primary"}
            padding={"8px"}
          > {t("creationOfSecretSanta",{value:props.house.name})}
          </Typography>
          <SelectParticipants
            house={props.house}
            participants={participants}
            setParticipants={setParticipants}
          />
          {participants.length > 2 ? (
            <>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ paddingTop: 2, paddingLeft: 4, paddingRight: 4 }}
              />

              <ForbiddenGroupInfos forbidenGroups={forbiddenGroups} />
              <StartSecretSanta
                house={props.house}
                participants={participants}
                forbidenGroups={forbiddenGroups}
              />
            </>
          ) : (
            <>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ paddingTop: 2 }}
              />
              <Typography textAlign={"center"}>
                {t("youHaveToSelectThreePersons")}
              </Typography>
            </>
          )}
        </Box>
      )}
    </>
  );
}

function StartSecretSanta(props: {
  house: House;
  participants: UserLightWithoutWishlist[];
  forbidenGroups: UserLightWithoutWishlist[][];
}) {

  const {t} = useTranslation();
  const auth = useContext(AuthContext);
  const [secretSantaCreation, setSecretSantaCreation] = useState(false);
  const navigation = useNavigate();
  
  const startSecretSanta = () => {
    setSecretSantaCreation(true);
    var participantsId = props.participants.map((item) => item.id);
    var forbiddenGroupsid: number[][] = [];
    props.forbidenGroups.forEach((list) => {
      var listIds = list.map((item) => item.id)
      forbiddenGroupsid.push(listIds)
    })
    var token = auth?.token??""; 
    let createSantaDto = new CreateSecretSantaDto(participantsId, forbiddenGroupsid);
    apiHouseClient.createSecretSanta(token, props.house.id, createSantaDto).then((response) => {
      setSecretSantaCreation(false);
      auth?.refreshUser();
      enqueueSnackbar(t("secretSantaCreationSuccess"), {variant: "success"});
      navigation(-1);
    }).catch((error) => {
      setSecretSantaCreation(false);
      enqueueSnackbar(t("secretSantaCreationFail"), {variant: "error"});
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginTop={"24px"}
      maxWidth={"1200px"}
    >
      <MyWaitingDialog open={secretSantaCreation} message={t("creationOfSecretSantaInProgress")}/>

      <Typography textAlign={"center"}>
        {t("createSecretSantaExplain")}
      </Typography>
      <PrimaryButton sx={{ maxWidth: "500px" }} onClick={startSecretSanta}>
        {t("startSecretSanta")}
      </PrimaryButton>
    </Box>
  );
}

function ForbiddenGroupInfos(props: {
  forbidenGroups: UserLightWithoutWishlist[][];
}) {
  const {t} = useTranslation();
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography
        textAlign={"center"}
        fontWeight={"bold"}
        fontSize={20}
        color={"primary"}
        padding={"8px"}
      >
        {t("forbiddenGroups")}
      </Typography>
      <Typography textAlign={"center"} padding={"8px"}>
        {t("forbiddenGroupsExplanation")}
      </Typography>
      {props.forbidenGroups.map((item, i) => {
        return (
          <Typography key={i} color={"primary"} padding={1} fontWeight={"bold"}>
            {t("xWontExchangeGifts",{value: item.map((item) => item.username).join(", ")})}
          </Typography>
        );
      })}
      <OutlinedButton
        sx={{ maxWidth: "500px" }}
        onClick={(event) => {
          event.preventDefault();
          window.location.hash = "#forbidden_groups";
        }}
      >
        {t("defineForbiddenGroups")}
      </OutlinedButton>
    </Box>
  );
}

function SelectParticipants(props: {
  house: House;
  participants: UserLightWithoutWishlist[];
  setParticipants: React.Dispatch<
    React.SetStateAction<UserLightWithoutWishlist[]>
  >;
}) {

  const {t} = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
        marginTop: "16px",
      }}
    >
      <Typography
        textAlign={"center"}
        fontWeight={"bold"}
        fontSize={18}
        color={"primary"}
        padding={"8px"}
      >
        {t("participants")}
      </Typography>
      <Typography textAlign={"center"} padding={"8px"}>
        {t("selectParticipants")}
      </Typography>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        spacing={1}
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
      >
        {props.house.users
          .sort(function (a, b) {
            if (a.username < b.username) {
              return -1;
            }
            if (a.username > b.username) {
              return 1;
            }
            return 0;
          })
          .map((item, id) => {
            return (
              <Grid item key={item.id} xs={4} sm={3} md={2} lg={1} xl={1}>
                <UserItemSecretSanta
                  user={item}
                  participants={props.participants}
                  setParticipants={props.setParticipants}
                />
              </Grid>
            );
          })}
      </Grid>
      <OutlinedButton
        sx={{ maxWidth: "500px", marginTop: "16px" }}
        onClick={(event) => {
          if (props.house.users.length == props.participants.length) {
            props.setParticipants([]);
          } else {
            props.setParticipants(props.house.users);
          }
        }}
      >
        {props.house.users.length == props.participants.length
          ? t("unselectEveryone")
          : t("selectEveryone")}
      </OutlinedButton>
    </Box>
  );
}

function UserItemSecretSanta(props: {
  user: UserLightWithoutWishlist;
  participants: UserLightWithoutWishlist[];
  setParticipants: React.Dispatch<
    React.SetStateAction<UserLightWithoutWishlist[]>
  >;
}) {
  function isInTheList(): boolean {
    for (let i = 0; i < props.participants.length; i++) {
      let temp = props.participants[i];
      if (temp.id === props.user.id) {
        return true;
      }
    }
    return false;
  }

  const [selected, setSelected] = useState(isInTheList());

  useEffect(() => {
    setSelected(isInTheList());
  }, [props.participants]);

  function handleclick() {
    if (selected) {
      props.setParticipants(
        props.participants?.filter((itemTemp) => itemTemp.id !== props.user.id)
      );
    } else {
      props.setParticipants(props.participants.concat(props.user));
    }
  }

  return (
    <ButtonBase
      onClick={handleclick}
      sx={{
        aspectRatio: "2/3",
        width: "100%",
        display: "flex",
        bgcolor: "primary.light",
        opacity: selected ? "1" : "0.7",
        filter: selected ? "none" : "grayscale(100%)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent:"space-evenly",
        borderRadius: "16px",
        padding: "8px",
        transition: "all 0.3s",
        "&:hover": {
          scale: "1.2",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "150px",
          padding: "4px 5px 2px 5px",
          boxSizing: "border-box",
          border: "2px solid",
          borderColor: "primary.main",
          position: "absolute",
          bgcolor: "#ffffff",
          zIndex: "130",
          right: 8,
          top: 4,
          alignItems: "center",
          opacity: selected ? "100%" : "0%",
          transition: "all 0.3s",
        }}
      >
        <Check
          sx={{ marginTop: "0", marginBottom: "0" }}
          fontSize={"small"}
          color="primary"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          aspectRatio:"1/1",
        }}
      >
        <AvatarPicture url={props.user.photoUrl} alt={props.user.username} />
      </Box>
      <Typography fontSize={16} fontWeight={"bold"} color={"primary"}>{props.user.username}</Typography>
    </ButtonBase>
  );
}

export default CreateSecretSanta;
