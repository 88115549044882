import { useTheme } from '@emotion/react';
import { Switch, createTheme } from '@mui/material'
import React from 'react'

function MySwitch(props:{checked: boolean, onChange: (value: boolean) => void}) {
    let theme = useTheme();
    const innerTheme = createTheme(theme);

  return (
    <Switch
    sx={{
      width: "60px",
      height: "30px",
      padding: 0,
      display: "flex",
      "&:active": {
        "& .MuiSwitch-thumb": {
          width: "35px",
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          transform: "translateX(20px)",
        },
      },
      "& .MuiSwitch-switchBase": {
        padding: "2px",
        "&.Mui-checked": {
          transform: "translateX(30px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "primary",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 26,
        height: 26,
        borderRadius: 60,
        transition: innerTheme.transitions.create(["width"], {
          duration: 200,
        }),
      },
      "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: "lightgrey",
        boxSizing: "border-box",
      },
    }}
    checked={props.checked}
    onChange={(event) => {
        //event.preventDefault();
        props.onChange(event.target.checked);
    }}
  />
  )
}

export default MySwitch