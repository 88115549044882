import { useContext, useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import { AuthContext } from "../../../../api/AuthProvider";
import MyWaitingDialog, {
  MyPaperPropsDialog,
} from "../../../ReusableComponents/MyWaitingDialog";
import UploadableNetworkImage from "../../../ReusableComponents/UploadableNetworkImage";
import { enqueueSnackbar } from "notistack";
import { apiClient } from "../../../../api/api";
import { User } from "../../../../models/User";
import { OutlinedButton } from "../HouseHolds/HouseDetailPage";
import { UpdateUserDto } from "../../../../models/apiBody/BodyLoginWithMail";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
} from "@mui/x-date-pickers";
import { AxiosError } from "axios";
function EditProfileDialog(props: {
  open: boolean;
  onClose: (user: User | null) => void;
  user: User;
}) {
  let [userName, setUserName] = useState(props.user.username);
  let [userPicture, setUserPicture] = useState(props.user.photoUrl ?? "");
  const [birthday, setBirthday] = useState<Dayjs | null>(dayjs(props.user.birthdayDate));

  let [editInProgress, setEditInProgress] = useState(false);

  let theme = useTheme();
  const innerTheme = createTheme(theme);
  const auth = useContext(AuthContext);
  const today = dayjs();
  const { t, i18n } = useTranslation();
  var languageCodeUsed = i18n.language.split("-")[0];

  return (
    <Dialog
      PaperProps={MyPaperPropsDialog}
      open={props.open}
      scroll="body"
      fullWidth
    >
      <MyWaitingDialog
        open={editInProgress}
        message={t("updateProfileMessage")}
      />
      <DialogTitle id="responsive-dialog-title">
        <Typography fontWeight={"bold"} color={"primary"} textAlign={"center"}>
          {t("editMyProfile")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          overflow={"visible"}
        >
          <Box width={"150px"} height={"150px"} overflow={"visible"}>
            <UploadableNetworkImage
              initialUrl={userPicture}
              alt={"Upload your profile picture"}
              onUrlChange={(url) => {
                setUserPicture(url);
              }}
            />
          </Box>
        </Box>

        <TextField
          required
          error={(userName ?? "").length == 0}
          variant="outlined"
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          fullWidth
          color="primary"
          type="text"
          InputProps={{
            style: {
              borderRadius: "16px",
            },
          }}
          label={t("yourName")}
          sx={{
            marginTop: "16px",
            background: "white",
            borderRadius: "16px",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />

<LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={languageCodeUsed}
          >
            <DatePicker
              localeText={{
                toolbarTitle: t("yourBirthdate"),
                okButtonLabel: t("validate"),
                cancelButtonLabel: t("cancel"),
                clearButtonLabel: t("clear"),
              }}
              maxDate={today}
              formatDensity="spacious"
              label={t("yourBirthdate")}
              value={birthday}
              slotProps={{
                actionBar: {
                  actions: ["clear", "cancel", "accept"],
                },
                textField: {
                  InputProps: {
                    style: {
                      borderRadius: "16px",
                    },
                  },
                  fullWidth: true,
                  sx: {
                    marginTop: "16px",
                    background: "white",
                    borderRadius: "16px",
                  },
                },
                toolbar: {
                  hidden: false,
                },
              }}
              onChange={(newValue) => setBirthday(newValue)}
            />
          </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          variant="outlined"
          onClick={() => {
            props.onClose(null);
          }}
        >
          {t("cancel")}
        </OutlinedButton>
        <OutlinedButton
          color="primary"
          variant="outlined"
          onClick={() => {
            setEditInProgress(true);
            const birthdayString = birthday?.format();
            apiClient
              .updateUser(
                new UpdateUserDto(userName, userPicture, birthdayString??""),
                auth?.token ?? ""
              )
              .then((response) => {
                auth?.refreshUser()
                var newUser = response.data;
                setEditInProgress(false);
                props.onClose(newUser);
                enqueueSnackbar(t("profileUpdated"), { variant: "success" });
              })
              .catch((error: AxiosError) => {
                setEditInProgress(false);
                enqueueSnackbar(
                  t("profileUpdateError",{value: error.response?.status}),
                  {
                    variant: "error",
                  }
                );
              });
          }}
          disabled={(userName ?? "").length == 0}
        >
          {t("validate")}
        </OutlinedButton>
      </DialogActions>
    </Dialog>
  );
}

export default EditProfileDialog;
