import { AppBar, Button, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import DrawerComponent from './DrawerComponent'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';


function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const MyButtonStyle = styled(NavLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "32px",
    fontSize: 16,
    margin: "auto",
    padding: "16px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "white",
      color: theme.palette.primary.main,
      fontSize: 16,
    },
  }));

  return (
    <div style={{ width: '100%', marginTop: "32px", textAlign: 'center'  }}>
        <MyButtonStyle to="/privacy">{t("privacyPolicy")}</MyButtonStyle>
    </div>
  )
}

export default Footer