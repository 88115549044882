import React from "react";
import {
  Gift,
  GiftStatus,
  giftTakenStatus,
  showPriceTag,
} from "../../models/Gift";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  Grid,
  Rating,
  Typography,
  createTheme,
} from "@mui/material";
import PriceTag, { TagSize } from "./PriceTag";
import MyNetworkImage from "./MyNetworkImage";
import { Favorite } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function MyGiftItem(props: { gift: Gift }) {
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const showPrice = showPriceTag(props.gift);
  const giftStatus = giftTakenStatus(props.gift);
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      lg={2}
      xl={2}
      key={props.gift.id}
      sx={{
        padding: "4px",
      }}
    >
      <ButtonBase
        sx={{
          aspectRatio: "0.6",
          width: "100%",
          overflow: "hidden",
          transition: "all 0.3s",
          "&:hover": {
            scale: "1.1",
            zIndex: 120,
            borderRadius: "16px",
          },
        }}
        onClick={() => {
          navigate(`/app/gift/${props.gift.id}`, {
            state: { gift: props.gift },
          });
        }}
      >
        <Box
          sx={{
            textTransform: "none",
            alignItems: "center",
            justifyItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
            borderRadius: "16px",
            clipPath: "border-box",
            border: "solid 1px",
            borderColor: "primary.light",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              borderRadius: "16px 16px 16px 16px",
              width: "100%",
              overflow: "hidden",
              position: "relative",
              display: "flex",
              flexGrow: 1,
              flexBasis: 0,
            }}
          >
            <MyNetworkImage
              url={props.gift.photoUrl}
              alt={props.gift.name}
              cover={true}
            />

            <PriceTag
              priceToShow={props.gift.price ?? 0}
              big={TagSize.M}
              line={false}
              sx={{
                rotate: "12deg",
                position: "absolute",
                right: "6px",
                top: "6px",
                zIndex: 10,
              }}
            />
          </Box>
          <Box
            sx={{
              paddingTop: "8px",
              paddingBottom: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              justifyContent: "center",
              position: "relative",
              borderRadius: "0px 0px 16px 16px",
              width: "100%",
            }}
          >
            {props.gift.rate !== null && props.gift.rate != 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                  left: 0,
                  right: 0,
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <Box
                  sx={{
                    bgcolor: innerTheme.palette.primary.light,
                    padding: "0px 16px",
                    paddingTop: "4px",
                    borderRadius: "1500px",
                  }}
                >
                  <Rating
                    readOnly
                    precision={1}
                    value={props.gift.rate}
                    defaultValue={props.gift.rate}
                    max={props.gift.rate}
                    icon={<Favorite color="primary" />}
                    emptyIcon={<Favorite color="error" />}
                  />
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <Typography
              fontSize={16}
              fontWeight={"bold"}
              color={"primary"}
              textAlign={"center"}
              textOverflow={"ellipsis"}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
            >
              {props.gift.name}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    </Grid>
  );
}

export default MyGiftItem;
