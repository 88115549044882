import React, { useContext, useTransition } from "react";
import {
  UserLightWithoutWishlist,
  daysBeforeNextBirthday,
} from "../../../models/User";
import { Box, ButtonBase, SvgIconPropsColorOverrides, Typography, createTheme } from "@mui/material";
import AvatarPicture from "../../ReusableComponents/AvatarPicture";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../api/AuthProvider";
import { CakeOutlined, CakeRounded } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

function UserItem(props: { user: UserLightWithoutWishlist }) {

  const {t, i18n} = useTranslation();

  const time = new Intl.DateTimeFormat(i18n.language, {
    month: "long",
    day: "2-digit",
  }).format(new Date(props.user.birthdayDate));

  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const daysBeforeBirthday = daysBeforeNextBirthday(props.user);
  const theme = useTheme();
  let colorDays: string;
  
  switch(true){
    case (daysBeforeBirthday == null) : colorDays = "#ffffff"; break;
    case ((daysBeforeBirthday??5000) <= 30) : colorDays = "#845ac0"; break;
    case ((daysBeforeBirthday??5000) <= 15) : colorDays = "#ED7F10"; break;
    case ((daysBeforeBirthday??5000) === 0) : colorDays = "#ff0000"; break;
    default: colorDays = "#ffffff"; break;
  }

  return (
    <div>
      <ButtonBase
        sx={{
          width: "100%",
          aspectRatio: "3 / 4",
          transition: "all 0.3s",
          "&:hover": {
            scale: "1.1",
            zIndex: 120,
            borderRadius: "16px",
          },
        }}
        onClick={() => {
          if (props.user.id === auth?.user?.id) {
            navigate("/app/myList");
          } else {
            navigate(`/app/user/${props.user.id}`);
          }
        }}
      >
        <Box
          sx={{
            textTransform: "none",
            alignItems: "center",
            padding: "4px",
            justifyItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "100%",
            height: "100%",
            background: "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
            borderRadius: "16px",
            clipPath: "border-box",
            border: "solid 1px",
            borderColor: "primary.light",
          }}
        >
          { (daysBeforeBirthday??5000) <= 30 ?
            (<Box
              sx={{
                position: "absolute",
                top: 4,
                right: 4,
                padding: "6px",
                borderRadius: "12px",
                bgcolor: "#ffffff",
                zIndex: 1532,
                flexGrow:1,
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CakeOutlined sx={{
                fill: colorDays
              }} />
              <Typography fontSize={12} color={colorDays} fontWeight={"bold"}>
                {t("xDays", {value: daysBeforeBirthday})}
              </Typography>
            </Box>) : <></>
            }
          <Box
            sx={{
              padding: "8px",
              aspectRatio:"1/1",
              maxHeight:"50%",
              display: "flex",
              flex:"1",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
             
            }}
          >
        
            <AvatarPicture
              alt={props.user.username}
              url={props.user.photoUrl}
            />
          </Box>
          <Box
          sx={{
            height:'auto',
            display: "flex",
            flex:"0",
            flexDirection: "column",
            
          }}>
            <Typography fontSize={16} fontWeight={"bold"} color={"primary"}>
              {props.user.username}
            </Typography>
            {(props.user.birthdayDate ?? "").length !== 0 ? (
              <Typography fontSize={14} fontStyle={"italic"}>
                {t("birthdayOn",{value:time})}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </ButtonBase>
    </div>
  );
}

export default UserItem;
