import React from 'react'
import MyCarousel from './Carousel/MyCarousel'
import { Grid, Typography } from '@mui/material'
import { useMediaQuery, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import InfoCard from './InfoCard';
import { useTranslation } from 'react-i18next';

function HomePage() {

  const { t } = useTranslation();

  const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const MyImage = styled('img')({
      width: '100%',
      height: isMobile ? '50px':'50px',
      marginTop: '32px',
      objectFit: 'contain',
      objectPosition: 'top',
      overflow: 'hidden'
  })

var cardItems = [
  {
      title: t('cardItemTitle1'),
      subtitle: t('cardItemSubtitle1'),
      icon: "/assets/unlimited.svg",
    },
  {
    title: t('cardItemTitle2'),
    subtitle: t('cardItemSubtitle2'),
    icon: "./assets/time.svg",

  },
  {
    title: t('cardItemTitle3'),
    subtitle: t('cardItemSubtitle3'),
    icon: "/assets/silence.svg",
  },
  {
    title: t('cardItemTitle4'),
    subtitle: t('cardItemSubtitle4'),
    icon: "/assets/improt.svg",
  },
  {
    title: t('cardItemTitle5'),
    subtitle: t('cardItemSubtitle5'),
    icon: "/assets/notification.svg",
  },
  {
    title: t('cardItemTitle6'),
    subtitle: t('cardItemSubtitle6'),
    icon: "/assets/lock.svg",
  }]
  return (
    <div className='container-page'>
       <MyImage
            src = "/assets/family_gift_logo_simple.svg" // Replace with your image URL
            alt = "Sample"
          />
          
        <Typography 
          variant='h2'
          textAlign={"center"} 
          color={'primary'}
          margin='8px'
          padding='8px'
          fontWeight={'600'}>
            Family Gift
        </Typography>
        <Typography 
          variant='h4'
          textAlign={"center"} 
          color={'primary'}
          padding='4px'fontWeight={'600'}>
            {t('slogan')}</Typography>
         <MyCarousel />

        <Typography 
          variant='h4'
          textAlign={"center"} 
          color={'primary'}
          marginTop='64px'
          padding='8px'
          fontWeight={'600'}>{t("whatsFamilyGift")}</Typography>
        <Typography 
          variant='h6'
          textAlign={"center"} 
          marginTop='8px'
          color={'primary'}
          padding='8px'
          sx={{
            maxWidth: "800px",
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
          >
            {t("familyGiftSmallExplanation")}
            </Typography>
        <Grid 
          container
          marginTop={'64px'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          >
          {
            cardItems.map((item, i) => <InfoCard key={i} item={item}  xs={1}/>)
          }
        </Grid>
    </div>
  )
}

export default HomePage