import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { House, SecretSantaEntry } from "../../../../models/House";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { apiClient, apiHouseClient } from "../../../../api/api";
import { AuthContext } from "../../../../api/AuthProvider";
import MyNetworkImage from "../../../ReusableComponents/MyNetworkImage";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  createTheme,
  useMediaQuery,
  InputAdornment,
  IconButton,
  TextField,
  ButtonBase,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { ArrowCircleDown, Check, ContentPaste } from "@mui/icons-material";
import UserItem from "../../Items/UserItem";
import {
  UserLightWithoutWishlist,
  daysBeforeNextBirthday,
} from "../../../../models/User";
import MyWaitingDialog, {
  MyPaperPropsDialog,
} from "../../../ReusableComponents/MyWaitingDialog";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { AxiosError, AxiosResponse } from "axios";
import { UserLightItem } from "./JoinHouseholdPage";
import { ExcludeHouseDto } from "../../../../models/apiBody/BodyLoginWithMail";
import EditHouseDialog from "./EditHouseDialog";
import { styled } from "@mui/material/styles";
import AvatarPicture from "../../../ReusableComponents/AvatarPicture";
import { useTranslation } from "react-i18next";
import ErrorWidget from "../../../ReusableComponents/ErrorWidget";

function HouseDetailPage() {
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  var auth = useContext(AuthContext);
  let { houseId } = useParams();
  const location = useLocation();

  let [house, setHouse] = useState<House | null | undefined>(
    location.state?.house ?? null
  );

  let [hasSecretSantaInProgress, setHasSecretSantaInProgress] = useState(false);
  
  const [errorCode, setErrorCode] = useState(200);
  function getErrorMessage(errorCode: number): string {
    switch(errorCode){
      case 404: return t("householdNotFound"); 
      case 401: return t("errorAuthenticating"); 
      case 403: return t("yourNotPartOfThisHousehold")
      default: return t("unknownError"); 
    }
  }
  const { data, isLoading } = useQuery({
    queryKey: ["house", houseId],
    queryFn: () => apiClient.getHouseById(auth?.token ?? "", houseId).catch((error: AxiosError) => {
      setErrorCode(error.response?.status??200)
    }),
    refetchOnWindowFocus: true,
  });

  const handleRefetch = () => {
    queryClient.refetchQueries(["house", houseId]);
  };

  useEffect(() => {
    if (data) {
      setHouse(data);
      document.title = t("householdX", { value: data?.name });

    } else {
      document.title = "Family Gift";
    }
  }, [data]);

  useEffect(() => {
    setHasSecretSantaInProgress((house?.secretSanta ?? []).length > 0);
  }, [house]);


  return (
    <Box
      sx={{
        maxWidth: "1800px",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      
      {house != null && house != undefined ? (
        isMobile ? (
          <Box>
            <Box>
        <Typography
          textAlign={"center"}
          paddingTop={2}
          color={"primary"}
          fontSize={24}
          fontWeight={"bold"}
        >
          {house?.name}
        </Typography>
        <Typography
          textAlign={"center"}
          fontSize={18}
          fontStyle={"oblique"}
          paddingBottom={2}
        >
          {t("members", { count: house?.users.length })}
        </Typography>
      </Box>
            <HouseImage house={house} />
            {hasSecretSantaInProgress ? (
              <SecretSantaInProgress
                house={house}
                handleRefetch={handleRefetch}
              />
            ) : (
              <></>
            )}

            <HouseMember house={house} />
            {hasSecretSantaInProgress ? (
              <></>
            ) : (
              <SecretSantaStart house={house} />
            )}
            <HouseGestion
              house={house}
              setHouse={setHouse}
              refetchHouse={handleRefetch}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
              }}
            >
              <HouseImage house={house} />
              <HouseGestion
                house={house}
                setHouse={setHouse}
                refetchHouse={handleRefetch}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
              }}
            >
              {hasSecretSantaInProgress ? (
                <SecretSantaInProgress
                  house={house}
                  handleRefetch={handleRefetch}
                />
              ) : (
                <></>
              )}

              <HouseMember house={house} />
              {hasSecretSantaInProgress ? (
                <></>
              ) : (
                <SecretSantaStart house={house} />
              )}
            </Box>
          </Box>
        )
      ) : (
        <ErrorWidget errorCode={errorCode} errorMessage={getErrorMessage(errorCode)}/>
      )}
    </Box>
  );
}

function removeUserFromList(
  arr: UserLightWithoutWishlist[],
  value: UserLightWithoutWishlist
) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i].id === value.id) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

function HouseMember(props: { house: House }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: "16px",
      }}
    >
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("householdMembers", { count: props.house.users.length })}
      </Typography>
      <Typography textAlign={"center"} fontSize={16}>
        {t("clickOnMember")}
      </Typography>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"flex-start"}
        sx={{ margin: "auto", transition: "all 0.3s" }}
      >
        {props.house.users
          .sort(function (a, b) {
            return (
              (daysBeforeNextBirthday(a) ?? 0) -
              (daysBeforeNextBirthday(b) ?? 0)
            );
          })
          .map((item, i) => (
            <Grid
              item
              xs={6}
              sm={3}
              md={4}
              lg={3}
              xl={3}
              padding={1}
              key={item.id}
            >
              <UserItem key={item.id} user={item} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

function SecretSantaInProgress(props: {
  house: House;
  handleRefetch: () => void;
}) {
  var auth = useContext(AuthContext);
  var user = auth?.user;
  var userIdSecretSanta: number[] = [];
  userIdSecretSanta.push(user?.id ?? 0);
  user?.children?.forEach((child) => userIdSecretSanta.push(child.id));
  var secretSantaEntry = props.house.secretSanta.filter((entry) =>
    userIdSecretSanta.includes(entry.giver.id)
  );
  var participants = props.house.secretSanta
    .map((item) => item.giver)
    .sort((a, b) => {
      return a.id - b.id;
    });

  var [infoSecretSanta, setInfoSecretSanta] = useState(false);
  var [stopSecretSanta, setStopSecretSanta] = useState(false);
  var [deletingSecretSanta, setDeletingSecretSanta] = useState(false);

  function handleClickOpenSecretSantaInfoDialog() {
    window.location.hash = "#secret_santa_infos";
  }
  function handleClose() {
    window.history.back();
  }

  useEffect(() => {
    const onHashChange = () => {
      setInfoSecretSanta(window.location.hash === "#secret_santa_infos");
    };
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);
  const { t } = useTranslation();
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <MyWaitingDialog
        open={deletingSecretSanta}
        message={t("stoppingSecretSanta")}
      />
      <Dialog
        PaperProps={MyPaperPropsDialog}
        open={stopSecretSanta}
        onClose={() => setStopSecretSanta(false)}
        scroll="body"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("stopThisSecretSanta")}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <DialogContentText>
            <Typography textAlign={"center"}>
              {t("stopSecretSantaExplain")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            variant="outlined"
            autoFocus
            onClick={() => setStopSecretSanta(false)}
          >
            {t("no")}
          </OutlinedButton>
          <OutlinedButton
            variant="outlined"
            color="error"
            autoFocus
            onClick={() => {
              setDeletingSecretSanta(true);
              apiHouseClient
                .stopSecretSanta(auth?.token ?? "", props.house.id)
                .then((response: AxiosResponse<House>) => {
                  auth?.refreshUser();
                  props.handleRefetch();
                  setDeletingSecretSanta(false);
                  setStopSecretSanta(false);
                  handleClose();
                  enqueueSnackbar(t("secretSantaStopped"), {
                    variant: "success",
                  });
                })
                .catch((error) => {
                  setDeletingSecretSanta(false);
                  setStopSecretSanta(false);
                  enqueueSnackbar(t("errorStoppingSecretSanta"), {
                    variant: "error",
                  });
                });
            }}
          >
            {t("yes")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={MyPaperPropsDialog}
        open={infoSecretSanta}
        onClose={handleClose}
        scroll="body"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("informationsAboutSecretSanta")}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <DialogContentText>
            <Typography textAlign={"center"}>
              {t("participantsList")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {participants.map((item) => {
                return (
                  <Box
                    sx={{
                      padding: "8px",
                      borderRadius: "16px",
                      border: "2px solid",
                      borderColor: "primary.main",
                      margin: "8px",
                      bgcolor: "#ffffff",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box width={"70px"} height={"70px"}>
                      <AvatarPicture url={item.photoUrl} alt={item.username} />
                    </Box>
                    <Typography
                      fontSize={16}
                      fontWeight={"bold"}
                      color={"primary"}
                      padding={1}
                    >
                      {item.username}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </DialogContentText>
          <Divider sx={{ margin: "16px" }} />
          <DialogContentText>
            <Typography
              textAlign={"left"}
              fontSize={18}
              fontWeight={"bold"}
              color={"primary"}
            >
              {t("stopSecretSantaTitle")}
            </Typography>
            <Typography fontSize={16}>
              {t("stopSecretSantaDescription")}
            </Typography>
            <OutlinedButton
              sx={{ width: "100%" }}
              color="error"
              onClick={() => {
                setStopSecretSanta(true);
              }}
            >
              {t("stopSecretSantaTitle")}
            </OutlinedButton>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton variant="outlined" autoFocus onClick={handleClose}>
            {t("close")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {" "}
        {t("secretSanta")}
      </Typography>
      <Typography textAlign={"center"} fontSize={16}>
        {t("ongoingSecretSanta")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          justifySelf: "center",
          alignContent: "center",
        }}
      >
        {secretSantaEntry.map((entry) => {
          return (
            <SecretSantaEntryForMe entry={entry} key={entry.receiver.id} />
          );
        })}
      </Box>
      <OutlinedButton onClick={handleClickOpenSecretSantaInfoDialog}>
        {t("secretSantaManagement")}
      </OutlinedButton>
    </Box>
  );
}

function SecretSantaEntryForMe(props: { entry: SecretSantaEntry }) {
  var theme = useTheme();
  var innerTheme = createTheme(theme);
  var navigate = useNavigate();

  const goOnReceiverList = () => {
    navigate(`/app/user/${props.entry.receiver.id}`);
  };

  const { t } = useTranslation();

  return (
    <ButtonBase
      sx={{
        margin: "4px",
        borderRadius: "16px",
        bgcolor: innerTheme.palette.primary.light,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px",
        transition: "all 0.2s",
        zIndex: "1",
        border: "2px solid white",
        ":hover": {
          scale: "1.2",
          zIndex: "30",
        },
      }}
      onClick={goOnReceiverList}
    >
      <Typography fontWeight={"bold"} fontSize={14} color={"primary"}>
        {props.entry.giver.username}
      </Typography>
      <Box width={"50px"} height={"50px"}>
        <AvatarPicture
          url={props.entry.giver.photoUrl}
          alt={props.entry.giver.username}
        />
      </Box>
      <Box padding={2}>
        <Typography fontSize={16}>{t("mustOfferGiftTo")}</Typography>
        <ArrowCircleDown color="primary" fontSize="large" />
      </Box>

      <Box width={"60px"} height={"60px"}>
        <AvatarPicture
          url={props.entry.receiver.photoUrl}
          alt={props.entry.receiver.username}
        />
      </Box>
      <Typography fontWeight={"bold"} fontSize={16} color={"primary"}>
        {props.entry.receiver.username}
      </Typography>
    </ButtonBase>
  );
}

function SecretSantaStart(props: { house: House }) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "column",
        marginTop: "16px",
        padding: "16px",
      }}
    >
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("secretSanta")}
      </Typography>
      <Typography textAlign={"center"} fontSize={16}>
        {t("youCanCreateSecretSanta")}
      </Typography>

      <OutlinedButton
        variant="outlined"
        onClick={(event) => {
          event.preventDefault();
          navigate("secretSanta");
        }}
      >
        {t("startNewSecretSanta")}
      </OutlinedButton>
    </Box>
  );
}

function HouseGestion(props: {
  house: House;
  setHouse: Dispatch<SetStateAction<House | null | undefined>>;
  refetchHouse: () => void;
}) {
  const { t } = useTranslation();

  var auth = useContext(AuthContext);
  var [loadingExitHouse, setLoadingExitHouse] = useState(false);
  var { enqueueSnackbar } = useSnackbar();
  var navigate = useNavigate();

  var [askExitHouse, setAskExitHouse] = useState(false);
  var [shareHouseDialog, setShareHouseDialog] = useState(false);
  var [editHouseDialog, setEditHouseDialog] = useState(false);
  var [excludeHouseDialog, setExcludeHouseDialog] = useState(false);
  const [excludableUsers, setExcludableUsers] = useState<
    UserLightWithoutWishlist[]
  >([]);

  useEffect(() => {
    var userExcludable = [...props.house?.users] ?? [];
    if (auth?.user) {
      userExcludable = removeUserFromList(userExcludable, auth?.user);
      auth.user.children.forEach((item) => {
        userExcludable = removeUserFromList(userExcludable, item);
      });
    }
    setExcludableUsers(userExcludable);
  }, [props.house]);

  function leaveHouse() {
    setLoadingExitHouse(true);
    let token = auth?.token ?? "";
    apiClient
      .leaveHousehold(token, props.house.code)
      .then(() => {
        auth?.refreshUser();
        setLoadingExitHouse(false);
        enqueueSnackbar(t("houseLeftSuccess"), {
          variant: "success",
        });
        navigate(`/app/households`, {
          replace: true,
        });
      })
      .catch((error: AxiosError) => {
        setLoadingExitHouse(false);
        enqueueSnackbar(
          t("houseLeftError", { status: error.response?.status }),
          {
            variant: "warning",
          }
        );
      });
  }

  useEffect(() => {
    const onHashChange = () => {
      setShareHouseDialog(window.location.hash === "#share");
      setAskExitHouse(window.location.hash === "#leave");
      setEditHouseDialog(window.location.hash === "#edit");
      setExcludeHouseDialog(window.location.hash === "#exclude");
    };
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  function handleClickOpenShareDialog() {
    window.location.hash = "#share";
  }

  function handleClickOpenEditDialog() {
    window.location.hash = "#edit";
  }
  function handleClickOpenExcludeDialog() {
    setUserToExclude(null);
    window.location.hash = "#exclude";
  }
  function handleClickOpenLeaveDialog() {
    window.location.hash = "#leave";
  }

  function handleClose() {
    window.history.back();
  }

  const [userToExclude, setUserToExclude] = useState<number | null>(null);
  const [exclusionInProgress, setExclusionInProgress] =
    useState<boolean>(false);

  const shareLink =
    "https://family-gift.fr/app/households/join/" + props.house.code;
  return (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "column",
        marginTop: "16px",
        padding: "16px",
      }}
    >
      <EditHouseDialog
        house={props.house}
        onClose={(newHouse) => {
          handleClose();
          if (newHouse) {
            props.setHouse(newHouse);
          }
        }}
        open={editHouseDialog}
      />

      <Dialog
        PaperProps={MyPaperPropsDialog}
        open={askExitHouse}
        onClose={handleClose}
      >
        <img
          key={"Family Gift"}
          src="/assets/family_gift_logo_simple.svg"
          alt={"Family Gift"}
          loading="eager"
          draggable="false"
          style={{
            margin: "auto",
            display: "inline-block",
            width: "80px",
            height: "80px",
          }}
        />
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("confirmLeaveHouse")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography textAlign={"center"}>{t("leaveHouseInfo")}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton variant="outlined" autoFocus onClick={handleClose}>
            {t("no")}
          </OutlinedButton>
          <OutlinedButton
            color="error"
            variant="outlined"
            onClick={() => {
              leaveHouse();
            }}
            autoFocus
          >
            {t("yes")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={MyPaperPropsDialog}
        open={shareHouseDialog}
        onClose={handleClose}
      >
        <img
          key={"Family Gift"}
          src="/assets/family_gift_logo_simple.svg"
          alt={"Family Gift"}
          loading="eager"
          draggable="false"
          style={{
            margin: "auto",
            display: "inline-block",
            width: "80px",
            height: "80px",
          }}
        />
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("inviteMembers")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography textAlign={"center"}>
              {t("inviteMembersInfo")}
            </Typography>

            <TextField
              variant="outlined"
              value={shareLink}
              fullWidth
              color="primary"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        navigator.clipboard.writeText(shareLink);
                        enqueueSnackbar(t("linkCopied"), {
                          variant: "success",
                        });
                      }}
                      edge="end"
                    >
                      <ContentPaste />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "16px",
                },
              }}
              label={t("shareHouseLink")}
              sx={{
                marginTop: "16px",
                background: "white",
                borderRadius: "16px",
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            color="primary"
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
            autoFocus
          >
            {t("ok")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={MyPaperPropsDialog}
        open={excludeHouseDialog}
        onClose={() => {
          handleClose();
        }}
        scroll="body"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          overflow={"visible"}
        >
          <img
            key={"Family Gift"}
            src="/assets/family_gift_logo_simple.svg"
            alt={"Family Gift"}
            loading="eager"
            draggable="false"
            style={{
              margin: "auto",
              display: "inline-block",
              width: "80px",
              height: "80px",
            }}
          />
          <DialogTitle id="responsive-dialog-title">
            <Typography
              fontWeight={"bold"}
              color={"primary"}
              textAlign={"center"}
            >
              {t("excludeMember")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography textAlign={"justify"}>
                {t("excludeMemberInfo")}
              </Typography>
              <Grid
                container
                justifyContent={"center"}
                alignItems={"flex-start"}
                sx={{ margin: "auto", transition: "all 0.3s" }}
              >
                {excludableUsers.map((item, i) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={3}
                      padding={1}
                      key={item.id}
                    >
                      <ButtonBase
                        sx={{
                          textTransform: "none",
                          alignItems: "center",
                          justifyItems: "center",
                          display: "flex",
                          position: "relative",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          opacity:
                            userToExclude == item.id || userToExclude == null
                              ? "1"
                              : "0.6",
                          filter:
                            userToExclude == item.id || userToExclude == null
                              ? "none"
                              : "grayscale(100%)",
                          width: "100%",
                          height: "auto",
                          overflow: "hidden",
                          background:
                            "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
                          borderRadius: "16px",
                          clipPath: "border-box",
                          border: "solid 1px",
                          borderColor: "primary.light",
                          transition: "all 0.3s",
                          scale: userToExclude == item.id ? "1.2" : "1",
                          "&:hover": {
                            scale: "1.2",
                            zIndex: 120,
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          var selected = userToExclude == item.id;
                          if (selected) setUserToExclude(null);
                          else setUserToExclude(item.id);
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "150px",
                            padding: "4px 5px 2px 5px",
                            boxSizing: "border-box",
                            border: "2px solid",
                            borderColor: "primary.main",
                            position: "absolute",
                            bgcolor: "#ffffff",
                            zIndex: "130",
                            right: 8,
                            top: 4,
                            alignItems: "center",
                            opacity:
                              userToExclude == item.id && userToExclude != null
                                ? "100%"
                                : "0%",
                            transition: "all 0.3s",
                          }}
                        >
                          <Check
                            sx={{ marginTop: "0", marginBottom: "0" }}
                            fontSize={"medium"}
                            color="primary"
                          />
                        </Box>
                        <UserLightItem key={item.id} user={item} />
                      </ButtonBase>
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Box>

        <DialogActions>
          <OutlinedButton
            onClick={() => {
              handleClose();
            }}
          >
            {t("cancel")}
          </OutlinedButton>
          <OutlinedButton
            color="error"
            variant="outlined"
            onClick={() => {
              handleClose();
              if (userToExclude) {
                setExclusionInProgress(true);
                apiClient
                  .excludeHousehold(
                    auth?.token ?? "",
                    new ExcludeHouseDto(props.house.id, userToExclude)
                  )
                  .then(() => {
                    setUserToExclude(null);
                    props.refetchHouse();
                    auth?.refreshUser();
                    enqueueSnackbar(t("userExcludedSuccess"), {
                      variant: "success",
                    });
                    setExclusionInProgress(false);
                  })
                  .catch((error: AxiosError) => {
                    enqueueSnackbar(
                      t("userExcludedError", {
                        status: error.response?.status,
                      }),
                      { variant: "error" }
                    );
                    setExclusionInProgress(false);
                  });
              }
            }}
            disabled={userToExclude == null}
          >
            {t("confirm")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>

      <MyWaitingDialog
        message={t("exclusionInProgress")}
        open={exclusionInProgress}
      />

      <MyWaitingDialog
        message={t("leavingInProgress")}
        open={loadingExitHouse}
      />

      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("householdManagement")}
      </Typography>
      <OutlinedButton
        sx={{ width: "100%" }}
        onClick={() => {
          handleClickOpenShareDialog();
        }}
      >
        {t("inviteMembers")}
      </OutlinedButton>
      <OutlinedButton
        sx={{ width: "100%" }}
        onClick={handleClickOpenEditDialog}
      >
        {t("editThisHousehold")}
      </OutlinedButton>
      <OutlinedButton
        sx={{ width: "100%" }}
        onClick={handleClickOpenExcludeDialog}
      >
        {t("excludeMember")}
      </OutlinedButton>
      <OutlinedButton
        sx={{ width: "100%" }}
        onClick={handleClickOpenLeaveDialog}
        color="error"
      >
        {t("leaveThisHousehold")}
      </OutlinedButton>
    </Box>
  );
}

export const OutlinedButton = styled(Button)(({ theme }) => ({
  position: "relative",
  minWidth: "120px",
  margin: "8px",
  padding: "8px 24px",
  boxSizing: "border-box",
  borderRadius: "50px",
  backgroundColor: "white",
  textTransform: "none",
  fontSize: 16,
  border: "2px solid",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    border: "2px solid",
  },
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  position: "relative",
  minWidth: "120px",
  padding: "8px 24px",
  margin: "8px",
  WebkitTextFillColor: "white",
  boxSizing: "border-box",
  borderRadius: "500px",
  backgroundColor: theme.palette.primary.main,
  textTransform: "none",
  fontSize: 16,
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  fontWeight: "bold",
  ":disabled": {
    borderColor: "lightgrey",
    backgroundColor: "lightgrey",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    WebkitTextFillColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.light,
  },
}));

export const PrimaryErrorButton = styled(Button)(({ theme }) => ({
  position: "relative",
  minWidth: "120px",
  padding: "8px",
  margin: "8px",
  WebkitTextFillColor: "white",
  boxSizing: "border-box",
  borderRadius: "500px",
  backgroundColor: "firebrick",
  textTransform: "none",
  fontSize: 16,
  border: "2px solid",
  borderColor: "firebrick",
  fontWeight: "bold",
  ":disabled": {
    borderColor: "lightgrey",
    backgroundColor: "lightgrey",
  },
  "&:hover": {
    backgroundColor: "orangered",
    borderColor: "orangered",
  },
}));

function HouseImage(props: { house: House }) {
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "40vh",
        aspectRatio: isMobile ? "4/2" : "1 / 1",
      }}
    >
      <MyNetworkImage
        url={props.house?.pictureUrl}
        alt={props.house?.name ?? ""}
        cover={true}
      />
    </Box>
  );
}

export default HouseDetailPage;
