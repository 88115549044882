import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import MyNetworkImage from "./MyNetworkImage";
import { Box, ButtonBase, createTheme } from "@mui/material";
import {
  AddAPhoto,
  AddAPhotoOutlined,
  CameraAltOutlined,
} from "@mui/icons-material";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useTheme } from "@emotion/react";


function UploadableNetworkImage(props: {
  initialUrl: string;
  alt: string;
  onUrlChange: (url: string) => void;
}) {
  let [url, setUrl] = useState(props.initialUrl);
  let [loading, setLoading] = useState(false);
  
  const theme = useTheme();
  const innerTheme = createTheme(theme);

  useEffect(() => {
    if(url !== props.initialUrl){
      setUrl(props.initialUrl);
    }
  }, [props.initialUrl])
  
  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && (e.target.files??[]).length == 0) {
      return;
    }
    else {
        const file = (e.target.files??[])[0];
        setLoading(true)
        const { name } = file;       
        
        let storage = getStorage();
        var currentDate = new Date().getMilliseconds();
        let storageRef = ref(storage, `${currentDate}${name}`);
        console.log("handle file 4", file);
        let snapshot = await uploadBytes(storageRef, file);
        let newUrl = await getDownloadURL(snapshot.ref);
        if(newUrl){
            setUrl(newUrl);
            props.onUrlChange(newUrl);
        }
        setLoading(false);
    }
  };

  function resizeImage(file: File) {
    
  }

  

  return (
    <Fragment>
      <input
        hidden
        accept="image/*"
        className={"input"}
        id="icon-button-photo"
        onChange={handleFileUpload}
        type="file"
      />
      <label htmlFor="icon-button-photo">
        <ButtonBase
          sx={{
            aspectRatio: "1",
            width: "100%",
            opacity: loading ? "0.5" : "1",
            borderRadius:"150px",
            bgcolor:"transparent",
            transition: "all 0.3s",
            "&:hover": {
              scale: "1.1",
              zIndex: 120,
              borderRadius: "16px",
            },
          }}
          onClick={() => {
            document.getElementById("icon-button-photo")?.click();
            console.log("Click add an image");
          }}
        >
          <Box
            sx={{
              textTransform: "none",
              alignItems: "center",
              justifyItems: "center",
              aspectRatio:"1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
              
            }}
          >
            <Box
              sx={{
                aspectRatio:"1",
                overflow: "hidden",
                bgcolor:"primary.light",
                borderRadius: "1600px",
                clipPath: "border-box",
                border: "solid 4px",
                borderColor: "primary.main",
                position: "relative",
                display: "flex",
                flexGrow: 1,
                flexBasis: 0,
              }}
            >
              <MyNetworkImage url={url} alt={props.alt} cover={true} showBorder={false} />
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: "150px",
              padding: "4px 5px 2px 5px",
              boxSizing: "border-box",
              border: "3px solid",
              borderColor: "primary.main",
              position: "absolute",
              bgcolor: "#ffffff",
              right: 8,
              bottom: 8,
              alignItems: "center",
            }}
          >
            <AddAPhotoOutlined
              sx={{ marginTop: "0", marginBottom: "0" }}
              color="primary"
            />
          </Box>
        </ButtonBase>
      </label>
    </Fragment>
  );
}

export default UploadableNetworkImage;
