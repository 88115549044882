import React, {
  Children,
  Component,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  deleteLocalStorageAfterLogout,
  getAppleRefreshToken,
  getAppleidentifier,
  getDecodedMail,
  getDecodedPassword,
  getDecodedToken,
  getUser,
  isUserConnected,
  lsLoginProviderKey,
  lsTokenExpiration,
  saveAppleElement,
  saveLoginState,
  saveUser,
} from "../localStorageUtils";
import { apiClient } from "./api";
import {
  BodyLoginWithFacebook,
  BodyLoginWithGoogle,
  BodyLoginWithMail,
  BodyRefreshTokenWithApple,
  BodySigninWithApple,
  LoginProvider,
} from "../models/apiBody/BodyLoginWithMail";
import {
  GoogleLogin,
  TokenResponse,
  googleLogout,
  useGoogleLogin,
  useGoogleOAuth,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FacebookLogin, {
  FacebookLoginClient,
} from "@greatsumini/react-facebook-login";
import AppleLogin from "react-apple-login";
import {
  useScript,
  appleAuthHelpers,
  AppleAuthResponse,
} from "react-apple-signin-auth";
import { User } from "../models/User";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { fbLogin, fbLogout, getFacebookLoginStatus } from "../utils/FacebookSDK";

interface ITokenContext {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<ITokenContext["token"]>>;
  userConnected: Boolean;
  setUserConnected: React.Dispatch<React.SetStateAction<ITokenContext["userConnected"]>>;
  userReconnected: Boolean;
  setUserReconnected: React.Dispatch<React.SetStateAction<ITokenContext["userReconnected"]>>;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<ITokenContext["user"]>>;
  refreshUser: () => void;
  logoutAndGoHome: () => void
 }

export const AuthContext = createContext<ITokenContext | null>(null);

const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {

  const navigate = useNavigate();

  const [token, setToken] = useState<ITokenContext["token"]>(getDecodedToken());
  const [userConnected, setUserConnected] = useState<ITokenContext["userConnected"]>(isUserConnected());
  const [user, setUser] = useState<ITokenContext["user"]>(getUser());
  const [userReconnected, setUserReconnected] = useState<ITokenContext["userReconnected"]>(!haveToRefreshToken());

  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  function haveToRefreshToken() {
    var cTime = new Date().getTime();
    var expToken = Number(localStorage.getItem(lsTokenExpiration));
    return cTime >= expToken;
  }

  const {t} = useTranslation();


  const value = useMemo(
    () => ({ token, setToken, userConnected, setUserConnected, userReconnected, setUserReconnected, user, setUser, refreshUser, logoutAndGoHome }),
    [token, userConnected, userReconnected, user, refreshUser, logoutAndGoHome]
  );

  const refreshTokenMail = async () => {

    let mail = getDecodedMail();
    let password = getDecodedPassword();
    if (mail !== undefined && password !== undefined) {
      await apiClient
        .login(new BodyLoginWithMail(mail, password))
        .then((response) => {
          saveLoginState(
            LoginProvider.MailPassword,
            mail as string,
            password as string,
            response.data.token
          );
          setToken(response.data.token);
          setUserReconnected(true);
          setUserConnected(true);
        })
        .catch((error) => {
          logout();
        });
    } else {
      logout();
    }
  };

  const appIdFacebook = "718434003792762";
  useEffect(() => {
    console.log("check reauth")

    if (userConnected) {
          console.log("check reauth")

      if (haveToRefreshToken()) {
        setUserReconnected(false);
        console.log("REFRESH TOKEN MDR : userConnected", userConnected)
        console.log("REFRESH TOKEN MDR : userReConnected", userReconnected)
        enqueueSnackbar(t("reconnecting"))
        switch (localStorage.getItem(lsLoginProviderKey)) {
          case LoginProvider.MailPassword:
            refreshTokenMail();
            break;
          case LoginProvider.Google:
            break;
          case LoginProvider.Facebook:
            reloginWithFacebook();
            break;
          case LoginProvider.Apple:
            reloginWithApple();
            break;
          default:
            setUserConnected(false);
            setUserReconnected(false);
        }
      } else {
        setUserConnected(true);
        setUserReconnected(true);
      }
    }
  }, [userConnected, navigate]);


  function refreshUser() {
    if (token !== null) {
      apiClient.getUserWithToken(token).then((data) => {
        setUser(data.data);
        saveUser(data.data);
      });
    }
  }

  useEffect(() => {
    console.log("REFRESH USER AFTER RECONNECT");
    if (userReconnected) {
      refreshUser();
    }
  }, [userReconnected]);

  async function logout() {
      deleteLocalStorageAfterLogout();
      setToken(null);
      setUser(null);
      setUserConnected(false);
      setUserReconnected(false);
      navigate("/login");
      await fbLogout();   
    
  }

  async function logoutAndGoHome() {
    deleteLocalStorageAfterLogout();
    setToken(null);
    setUser(null);
    setUserConnected(false);
    setUserReconnected(false);
    navigate("/");
    await fbLogout();   
  }

  async function reloginWithApple() {
    apiClient
      .refreshWithApple(
        new BodyRefreshTokenWithApple(
          getAppleidentifier(),
          getAppleRefreshToken()
        )
      )
      .then((apiToken) => {
        saveLoginState(LoginProvider.Apple, "", "", apiToken.data.token);
        saveAppleElement(
          getAppleidentifier() as string,
          apiToken.data.refreshToken
        );
        console.log("response ok", apiToken);
        setUserReconnected(true);
        setUserConnected(true);
      })
      .catch((error) => {
        console.log("error refetching apple", error);
        logout();
      });
  }

  async function reloginWithFacebook() {
    var userId = -1;
    var accessToken = "";
    var logged = await getFacebookLoginStatus();
    if(logged.status == "connected"){
      console.log("LOGGED ANSWER", logged);
      userId = Number(logged.authResponse.userID);
      accessToken = logged.authResponse.accessToken;
    } else {
      var loginStatus = await fbLogin();
      userId = Number(loginStatus.authResponse.userID);
      accessToken = loginStatus.authResponse.accessToken;
    }
  
    console.log("LOGIN STATUS", loginStatus, logged);

    apiClient
            .loginWithFacebook(
              new BodyLoginWithFacebook(
                userId,
                accessToken
              )
            )
            .then((apiToken) => {
              console.log(apiToken);
              saveLoginState(
                LoginProvider.Facebook,
                "",
                "",
                apiToken.data.token
              );
              console.log("response ok", apiToken);
              setUserConnected(true);
              setUserReconnected(true);
            })
            .catch((error) => {
              logout();
            });
  }

  useGoogleOneTapLogin({
    auto_select: true,
    cancel_on_tap_outside: false,
    disabled:
      !haveToRefreshToken() ||
      localStorage.getItem(lsLoginProviderKey) != LoginProvider.Google,
    promptMomentNotification: (notification) => {
      console.log("Notification dismissed reason : ", notification);
      if (notification.isDismissedMoment()) {
        var reasonDismiss = notification.getDismissedReason();
        switch (reasonDismiss) {
          case "cancel_called":
            logout();
            break;
        }
      } else if (notification.isSkippedMoment()) {
        var reason = notification.getSkippedReason();
        switch (reason) {
          case "auto_cancel":
          case "issuing_failed":
          case "user_cancel":
            logout();
            break;
        }
      }
    },
    onError: () => {
      deleteLocalStorageAfterLogout();
      setUserReconnected(false);
      setUserConnected(false);
      googleLogout();
      console.log("Login Failed");
    },
    onSuccess: async (token) => {
      console.log("token retour border", token);
      await apiClient
        .loginWithGoogle(new BodyLoginWithGoogle(token.credential))
        .then((response) => {
          console.log("relogin google", response);
          saveLoginState(
            LoginProvider.Google,
            token.clientId?.toString() as string,
            "",
            response.data.token
          );
          console.log("response ok", response);
          setUserConnected(true);
          setUserReconnected(true);
        })
        .catch((error) => {
          logout();
          setUserConnected(false);
          setUserReconnected(false);
        });
    },
  });

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
