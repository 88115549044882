import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState } from "react";
import { SettingsContext } from "../../../utils/SettingsProvider";
import MySwitch from "../../ReusableComponents/MySwitch";
import { useTheme } from "@emotion/react";
import {
  getFcmToken,
  saveAddGiftNotifications,
  saveBirthdayNotifications,
  saveDeleteGiftNotifications,
  saveGeneralNotifications,
  saveParticipationsStorage,
  saveSecretSantaNotifications,
} from "../../../localStorageUtils";
import SortOrderMenu from "../../ReusableComponents/SortOrderMenu";
import { OutlinedButton, PrimaryErrorButton } from "../Pages/HouseHolds/HouseDetailPage";
import { useNavigate } from "react-router-dom";
import { useNotificationPermission } from "../../../utils/UsePermission";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../ReusableComponents/LanguageDropdown";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { AuthContext } from "../../../api/AuthProvider";
import { apiClient, apiFcmTokenClient } from "../../../api/api";
import { enqueueSnackbar } from "notistack";
import MyWaitingDialog, { MyPaperPropsDialog } from "../../ReusableComponents/MyWaitingDialog";

function SettingsPage() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );

  document.title = t("settings")
  return (
    <Box
      sx={{
        maxWidth: "1400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "100px",
      }}
    >
      <Typography
        fontSize={26}
        fontWeight={"bold"}
        color={"primary"}
        textAlign={"center"}
      >
        {t("appSettings")}
      </Typography>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Functionnality />
          <Divider orientation="horizontal" flexItem sx={{ paddingTop: 2 }} />
          <NotificationsSettings />
          <Divider orientation="horizontal" flexItem sx={{ paddingTop: 2 }} />
          <AboutTheApp />
          <Divider orientation="horizontal" flexItem sx={{ paddingTop: 2 }} />
          <DeleteAccountComponent />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "50%",
              padding: 2,
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Functionnality />
            <Divider orientation="horizontal" flexItem sx={{ paddingTop: 2 }} />

            <AboutTheApp />
          </Box>
          <Divider orientation="vertical" flexItem sx={{ marginTop: "24px" }} />

          <Box
            sx={{
              display: "flex",
              width: "50%",
              padding: 2,

              flexDirection: "column",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <NotificationsSettings />
            <Divider orientation="horizontal" flexItem sx={{ paddingTop: 2 }} />

            <DeleteAccountComponent />
          </Box>
        </Box>
      )}
    </Box>
  );
}

function NotificationsSettings() {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);
  const notificationPerm = useNotificationPermission();

  return (
    <Box
      sx={{
        padding: "8px",
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyItems:"start",
        flexDirection: "column",
      }}
    >
      <Typography
        fontWeight={"bold"}
        color={"primary"}
        fontSize={20}
        paddingTop={4}
      >
        {t("notificationsTitle")}
      </Typography>
      {notificationPerm != "granted" ? (
        notificationPerm == "default" ? (
          <Box
            sx={{
              border: "2px solid primry.main",
              boxSizing: "border-box",
              width: "100%",
              borderRadius: "24px",
              bgcolor: "primary.light",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography padding={2} fontStyle={"italic"} textAlign={"justify"}>
              {t("notificationsNotAllowedMessage")}
            </Typography>
            <Box width={"90%"}>
              <OutlinedButton
                onClick={() => {
                  window.Notification.requestPermission();
                }}
              >
                {t("grantPermissionButton")}
              </OutlinedButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              border: "2px solid red",
              borderRadius: "24px",
              bgcolor: "#ff000010",
            }}
          >
            <Typography
              padding={2}
              fontStyle={"italic"}
              textAlign={"justify"}
              color={"error"}
            >
              {t("notificationsBlockedMessage")}
            </Typography>
          </Box>
        )
      ) : (
        <></>
      )}
      <Box
        sx={{
          marginTop: "16px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("generalMessagesTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("generalMessagesDescription")}
          </Typography>
        </Box>

        <MySwitch
          checked={settings?.generalNotifications ?? false}
          onChange={(value) => {
            saveGeneralNotifications(value);
            settings?.setGeneralNotifications(value);
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "16px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("birthdayTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("birthdayDescription")}
          </Typography>
        </Box>

        <MySwitch
          checked={settings?.birthdayNotifications ?? false}
          onChange={(value) => {
            saveBirthdayNotifications(value);
            settings?.setBirthdayNotifications(value);
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "16px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("addGiftTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("addGiftDescription")}
          </Typography>
        </Box>

        <MySwitch
          checked={settings?.addGiftNotifications ?? false}
          onChange={(value) => {
            saveAddGiftNotifications(value);
            settings?.setAddGiftNotifications(value);
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "16px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("deleteGiftTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("deleteGiftDescription")}
          </Typography>
        </Box>

        <MySwitch
          checked={settings?.deleteGiftNotifications ?? false}
          onChange={(value) => {
            saveDeleteGiftNotifications(value);
            settings?.setDeleteGiftNotifications(value);
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "16px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("secretSantaTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("secretSantaDescription")}
          </Typography>
        </Box>

        <MySwitch
          checked={settings?.secretSantaNotifications ?? false}
          onChange={(value) => {
            saveSecretSantaNotifications(value);
            settings?.setSecretSantaNotifications(value);
          }}
        />
      </Box>
    </Box>
  );
}

function Functionnality() {
  const settings = useContext(SettingsContext);
  const { t } = useTranslation();

  const handleChange = (value: boolean) => {
    settings?.setHideParticipations(value);
    saveParticipationsStorage(value);
  };
  return (
    <Box
      sx={{
        padding: "8px",
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        fontWeight={"bold"}
        color={"primary"}
        fontSize={20}
        paddingTop={4}
      >
        {t("functionalityTitle")}
      </Typography>
      <Box
        sx={{
          paddingTop: 2,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("selectLanguageTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("selectLanguageDescription")}
          </Typography>
        </Box>

        <LanguageDropdown />
      </Box>
      <Box
        sx={{
          paddingTop: 2,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("hideParticipationsTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("hideParticipationsDescription")}
          </Typography>
        </Box>

        <MySwitch
          checked={settings?.hideParticipation ?? false}
          onChange={handleChange}
        />
      </Box>
      <Box
        sx={{
          paddingTop: 2,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={"bold"} color={"primary"} fontSize={16}>
            {t("sortListsTitle")}
          </Typography>
          <Typography fontStyle={"italic"}>
            {t("sortListsDescription")}
          </Typography>
        </Box>
        <SortOrderMenu large={false} />
      </Box>
    </Box>
  );
}

function DeleteAccountComponent() {
  const { t } = useTranslation();
  var auth = useContext(AuthContext);

  var [askDeleteUser, setAskDeleteUser] = useState(false);
  var [deletionInProgress, setDeletionInProgress] = useState(false);
  function deleteUser() {
    setAskDeleteUser(false);
    setDeletionInProgress(true);
    var token = auth?.token ?? "";
    apiFcmTokenClient
      .deleteToken(token, getFcmToken() ?? "")
      .then(() => {
        apiClient
          .deleteUser(token)
          .then(() => {
            enqueueSnackbar(t("successfullyDeletedAccount"), {
              variant: "success",
            });
            setDeletionInProgress(false);
            auth?.logoutAndGoHome();
          })
          .catch((error) => {
            setDeletionInProgress(false);
            enqueueSnackbar(t("errorDeletingAccount"), { variant: "error" });
          });
      })
      .catch((error) => {
        setDeletionInProgress(false);
        enqueueSnackbar(t("errorDeletingAccount"), { variant: "error" });
      });
  }
  return <Box
      sx={{
        padding: "8px",
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
<Dialog PaperProps={MyPaperPropsDialog} open={askDeleteUser}>
        <img
          key={"Family Gift"}
          src="/assets/family_gift_logo_simple.svg"
          alt={"Family Gift"}
          loading="eager"
          draggable="false"
          style={{
            margin: "auto",
            display: "inline-block",
            width: "80px",
            height: "80px",
          }}
        />
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("deleteYourAccount")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              textAlign={"center"}
              color={"primary"}
              fontWeight={"bold"}
            >
              {t("deleteAccountExplain")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            variant="outlined"
            autoFocus
            onClick={() => {
              setAskDeleteUser(false);
            }}
          >
            {t("no")}
          </OutlinedButton>
          <PrimaryErrorButton
            color="primary"
            variant="outlined"
            onClick={() => {
              setAskDeleteUser(false);
              deleteUser();
            }}
            autoFocus
          >
            {t("yes")}
          </PrimaryErrorButton>
        </DialogActions>
      </Dialog>
      <MyWaitingDialog
        open={deletionInProgress}
        message={t("deletingYourAccount")}
      />
  <Typography
        fontWeight={"bold"}
        color={"primary"}
        fontSize={18}
        paddingTop={2}
      >
        {t("deleteYourAccount")}
      </Typography>
      <Typography fontStyle={"italic"}>{t("deleteAccountExplain")}</Typography>
      <OutlinedButton
        endIcon={<DeleteForeverOutlined />}
        
        color="error"
        onClick={() => {
          setAskDeleteUser(true);
        }}
      >
        {t("deleteMyAccount")}
      </OutlinedButton>
    </Box>
}

function AboutTheApp() {
  const { t } = useTranslation();
  var auth = useContext(AuthContext);

  const navigate = useNavigate();
 

  return (
    <Box
      sx={{
        padding: "8px",
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      
      <Typography
        fontWeight={"bold"}
        color={"primary"}
        fontSize={20}
        paddingTop={4}
      >
        {t("aboutTitle2")}
      </Typography>
      <Typography
        fontWeight={"bold"}
        color={"primary"}
        fontSize={18}
        paddingTop={2}
      >
        {t("privacyPolicyTitle")}
      </Typography>
      <Typography fontStyle={"italic"}>
        {t("privacyPolicyDescription")}
      </Typography>
      <OutlinedButton
        onClick={() => {
          navigate("privacy");
        }}
      >
        {t("viewPrivacyPolicy")}
      </OutlinedButton>
      <Typography
        fontWeight={"bold"}
        color={"primary"}
        fontSize={18}
        paddingTop={2}
      >
        {t("contactMeTitle")}
      </Typography>
      <Typography fontStyle={"italic"}>{t("contactMeDescription")}</Typography>
      <OutlinedButton
        onClick={() => {
          navigate("contact");
        }}
      >
        {t("contactMeButton")}
      </OutlinedButton>
    </Box>
  );
}

export default SettingsPage;
