import { Box, Paper, Typography, styled } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";

function CarouselTutorial(props: {
  items: {
    title: string;
    subtitle: string;
    icon: string;
  }[];
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transition:"all 0.2s"

      }}
    >
      <Carousel
        sx={{
          marginRight:"auto",
          marginLeft:"auto",
          boxSizing:"border-box",
          width: "100%",
          transition:"all 0.2s"
          
        }}
        className="carousel"
        animation={"slide"}
        indicators={true}
        cycleNavigation={true}
        interval={10000}
        duration={500}
        stopAutoPlayOnHover={true}
        swipe={true}
        navButtonsAlwaysVisible={true}
      >
        {props.items.map((item, i) => (
          <CarouselTutorialItem key={i} item={item} />
        ))}
      </Carousel>
    </Box>
  );
}

function CarouselTutorialItem(props: {
  item: {
    title: string;
    subtitle: string;
    icon: string;
  };
}) {
  return (
    <Box sx={{
        boxSizing:"border-box",
        paddingLeft:"50px",
        paddingRight:"50px",
        paddingTop:"16px",
        paddingBottom:"16px",
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        justifyItems:"center",
        width:"100%",
        height:"100%",
        transition:"all 0.2s"

      }}>
    <Paper
      sx={{
        boxSizing:"border-box",
        bgcolor:"primary.light",
        padding:"32px",
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        justifyItems:"center",
        width:"100%",
        height:"100%",
        transition:"all 0.2s"

      }}
    >
        
      <img src={props.item.icon} style={{
        padding:"16px",
        height:"150px",
        aspectRatio:"1/1"
      }}/>
      <Typography
        fontSize={20}
        padding={"8px"}
        textAlign={"center"}
        fontWeight={"bold"}
        color={"primary"}
      >
        {props.item.title}
      </Typography>
      <Typography
        fontSize={16}
        padding={"8px"}
        textAlign={"center"}
        color={"primary"}
      >
        {props.item.subtitle}
      </Typography>
    </Paper>
    </Box>
  );
}

export default CarouselTutorial;
