import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Typography, createTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const innerTheme = createTheme(theme);

  const TypoTitle = styled(Typography) (({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    fontSize: 18,
    margin:"0px",
    paddingTop: "32px",
    paddingBottom:"4px",
  }));

  const TypoContent = styled(Typography) (({ theme }) => ({
    color: theme.palette.black,
    alignItems: "center",
    whiteSpace:"pre-line",
    fontSize: 16,
    margin:"0px",
    paddingTop: "4px",
    paddingBottom:"8px",
  }));

  return (
    <Box
      sx={{
        margin: "auto",
        marginTop: "16px",
        borderRadius: "40px",
        padding: "20px",
        width: "auto",
        maxWidth: "1200px",
        display:"flex",
        flexDirection:"column"
      }}
    >
      <Typography
        variant="h4"
        textAlign={"center"}
        color={"primary"}
      >
        {t("privacyPolicy")}
      </Typography>
      <Typography
        variant="p"
        fontStyle={"italic"}
        style={{ wordWrap: "break-word" }}
        color={"black"}
        paddingTop="16px"
        paddingBottom="16px"
      >
        {t("privacyPolicyLastUpdate")}
      </Typography>
      <TypoContent>
        {t("ppIntro")}
      </TypoContent>

      <TypoTitle>{t("ppWichInfoTheAppUse")}</TypoTitle>
      <TypoContent>{t("ppUsedInformation")}</TypoContent>

      <TypoTitle>{t("ppHowYourDataAreUsed")}</TypoTitle>
      <TypoContent>{t("ppHowYourDataAreUsedExplain")}</TypoContent>

      <TypoTitle>{t("ppAutomaticallyRetrievedInfo")}</TypoTitle>
      <TypoContent>{t("ppAutomaticallyRetrievedInfoExplain")}</TypoContent>

      <TypoTitle>{t("doesTheAppUseGeoloc")}</TypoTitle>
      <TypoContent>{t("doesTheAppUseGeolocNo")}</TypoContent>

      <TypoTitle>{t("doesTierceHaveDatas")}</TypoTitle>
      <TypoContent>{t("doesTierceHaveDatasExplain")}</TypoContent>

      <TypoTitle>{t("affiliatedLink")}</TypoTitle>
      <TypoContent>{t("affiliatedLinkExplanation")}</TypoContent>

      <TypoTitle>{t("partenaire")}</TypoTitle>
      <TypoContent>{t("partenaireExplanation")}</TypoContent>
      
      <TypoTitle>{t("howToDeleteMyDatas")}</TypoTitle>
      <TypoContent>{t("howToDeleteMyDatasExplain")}</TypoContent>

      <TypoTitle>{t("optionsToStopCollect")}</TypoTitle>
      <TypoContent>{t("optionsToStopCollectExplain")}</TypoContent>

      <TypoTitle>{t("ppUpdated")}</TypoTitle>
      <TypoContent>{t("ppUpdatedExplain")}</TypoContent>

      <TypoTitle>{t("ppYourConsent")}</TypoTitle>
      <TypoContent>{t("ppYourConsentExplain")}</TypoContent>

    </Box>
  );
}

export default PrivacyPolicy;
