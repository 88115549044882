

      export const getFacebookLoginStatus = () => {
        return new Promise((resolve, reject) => {
          window.FB.getLoginStatus((response) => {
            resolve(response);
          });
        });
      };

      export const fbLogin = () => {
        return new Promise((resolve, reject) => {
          window.FB.login((response) => {
            resolve(response)        
          }, {
            scope: 'email, public_profile',
            auth_type: 'rerequest'
          })
        })
      }

      export const fbLogout = () => {
        console.log("logout facebook");
        return new Promise((resolve, reject) => {
          console.log("logout facebook");
          window.FB.logout((response) => {
            console.log("logout facebook answer", response);
            resolve(response)        
          })
        })
      }