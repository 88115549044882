import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

function AboutPage() {
  const { t } = useTranslation();

  return (
    <div className="container-page">
      <Typography
        variant="h4"
        textAlign={"justify"}
        color={"primary"}
        margin="8px"
        padding="8px"
        fontWeight={"600"}
      >
        {t("whyFamilyGift")}
      </Typography>

      <Typography
        variant="p"
        textAlign={"left"}
        whiteSpace={"pre-line"}
        color={"dark"}
        margin="8px"
        lineHeight={"22px"}
        display={"inline-block"}
        padding="8px"
        style={{ wordWrap: "break-word" }}
        fontWeight={"400"}
      >
        {t("whyFamilyGiftSubtitle")}
      </Typography>
      <Typography
        variant="h4"
        textAlign={"justify"}
        color={"primary"}
        margin="8px"
        padding="8px"
        fontWeight={"600"}
      >
        {t("whatsFamilyGiftTitle")}
      </Typography>
      <Typography
        variant="p"
        textAlign={"left"}
        whiteSpace={"pre-line"}
        color={"dark"}
        margin="8px"
        display={"inline-block"}
        padding="8px"
        style={{ wordWrap: "break-word" }}
        lineHeight={"22px"}
        fontWeight={"400"}
      >
        {t("whatsFamilyGiftSubtitle")}
      </Typography>
      <Typography
        variant="h4"
        textAlign={"justify"}
        color={"primary"}
        margin="8px"
        padding="8px"
        fontWeight={"600"}
      >
        {t("aboutTitle")}
      </Typography>
      <Typography
        variant="p"
        textAlign={"left"}
        whiteSpace={"pre-line"}
        color={"dark"}
        margin="8px"
        display={"inline-block"}
        padding="8px"
        style={{ wordWrap: "break-word" }}
        lineHeight={"22px"}
        fontWeight={"400"}
      >
        {t("aboutSubtitle")}
      </Typography>

    </div>
  );
}

export default AboutPage;
