import axios, { AxiosError } from "axios";
import { HouseWithoutUsers } from "./House";
import { Participation } from "./Participation";
import { User, UserLightWithoutWishlist } from "./User";

export class UrlBody {
  url: string;
  constructor(url: string) {
    this.url = url;
  }
}

export class ProductInfos {
  name: string;
  price: string;
  imageUrl: string;
  url: string;
  affiliatedUrl: string;
  constructor(
    name: string,
    price: string,
    imageUrl: string,
    url: string,
    affiliatedUrl: string
  ) {
    this.name = name;
    this.price = price;
    this.imageUrl = imageUrl;
    this.url = url;
    this.affiliatedUrl = affiliatedUrl;
  }
}

export class AddGiftDto {
  name: string;
  price: number;
  photoUrl: string;
  giftUrl: string;
  giftAffiliatedUrl: string;
  giftComment: string;
  rate: number | null;
  listOfHouseIds: number[];
  canBeTakenMultipleTime: boolean;

  constructor(
    name: string,
    price: number,
    photoUrl: string,
    giftUrl: string,
    giftAffiliatedUrl: string,
    giftComment: string,
    rate: number | null,
    listOfHouseIds: number[],
    canBeTakenMultipleTime: boolean
  ) {
    this.name = name;
    this.price = price;
    this.photoUrl = photoUrl;
    this.giftUrl = giftUrl;
    this.giftAffiliatedUrl = giftAffiliatedUrl;
    this.giftComment = giftComment;
    this.rate = rate;
    this.listOfHouseIds = listOfHouseIds;
    this.canBeTakenMultipleTime = canBeTakenMultipleTime;
  }
}

export class UpdateGiftDto {
  name: string;
  price: number;
  photoUrl: string;
  giftUrl: string;
  giftComment: string;
  rate: number | null;
  listHouseId: number[];
  canBeTakenMultipleTime: boolean;

  constructor(
    name: string,
    price: number,
    photoUrl: string,
    giftUrl: string,
    giftComment: string,
    rate: number | null,
    listHouseId: number[],
    canBeTakenMultipleTime: boolean
  ) {
    this.name = name;
    this.price = price;
    this.photoUrl = photoUrl;
    this.giftUrl = giftUrl;
    this.giftComment = giftComment;
    this.rate = rate;
    this.listHouseId = listHouseId;
    this.canBeTakenMultipleTime = canBeTakenMultipleTime;
  }
}

export class Gift {
  id: number;
  name: string;
  price: number;
  photoUrl: string | null;
  giftUrl: string | null;
  affiliatedUrl: string | null;
  giftComment: string | null;
  priceLeft: number;
  owner: UserLightWithoutWishlist;
  addedBy: UserLightWithoutWishlist;
  participations: Participation[];
  rate: number | null | undefined;
  listHouseVisible: HouseWithoutUsers[];
  canBeTakenMultipleTime: boolean;

  constructor(
    id: number,
    name: string,
    price: number,
    photoUrl: string | null,
    giftUrl: string | null,
    affiliatedUrl: string | null,
    giftComment: string | null,
    priceLeft: number,
    owner: UserLightWithoutWishlist,
    addedBy: UserLightWithoutWishlist,
    participations: Participation[],
    rate: number | null | undefined,
    listHouseVisible: HouseWithoutUsers[],
    canBeTakenMultipleTime: boolean
  ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.photoUrl = photoUrl;
    this.giftUrl = giftUrl;
    this.affiliatedUrl = affiliatedUrl;
    this.giftComment = giftComment;
    this.priceLeft = priceLeft;
    this.owner = owner;
    this.addedBy = addedBy;
    this.participations = participations;
    this.rate = rate;
    this.listHouseVisible = listHouseVisible;
    this.canBeTakenMultipleTime = canBeTakenMultipleTime;
  }
}

export function getAffiliatedLink(gift: Gift): string | null {
  if (gift.affiliatedUrl) return gift.affiliatedUrl;
  else if (gift.giftUrl) return gift.giftUrl;
  else return null;
}

export function getHostName(url: string): string {
  var tmp = document.createElement("a");
  tmp.href = url;
  return tmp.hostname;
}

export const giftComparatorPrice = (a: Gift, b: Gift) => {
  if (a.price < b.price) {
    return 1;
  } else if (a.price > b.price) {
    return -1;
  }
  return 0;
};

export const giftComparatorRate = (a: Gift, b: Gift) => {
  if ((a.rate ?? 0) < (b.rate ?? 0)) {
    return 1;
  } else if ((a.rate ?? 0) > (b.rate ?? 0)) {
    return -1;
  } else return 0;
};

export const giftComparatorOldToRecent = (a: Gift, b: Gift) => {
  if (a.id < b.id) {
    return -1;
  } else if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export const giftComparatorRecentToOld = (a: Gift, b: Gift) => {
  if (a.id < b.id) {
    return 1;
  } else if (a.id > b.id) {
    return -1;
  }
  return 0;
};

export const giftComparatorAlpha = (a: Gift, b: Gift) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export enum GiftStatus {
  Fully_Taken = "fully_taken",
  Free = "free",
  Partially_taken = "partially_taken",
}

export let getNumberRoundedTwoDecimals = (number: number): number =>
  parseFloat(number.toFixed(2));
export let showPriceTag = (gift: Gift): boolean =>
  gift.price !== null && gift.price !== undefined && gift.price > 0;

function hasAFullyTakenParticipation(gift: Gift): boolean {
  var result = false;
  gift.participations.forEach((item, _) => {
    if (item.participationType == 2 && !result) result = true;
  });
  return result;
}

export let getMyParticipationForThisGift = (
  gift: Gift,
  me: User | null | undefined
): Participation | null => {
  var value = null;
  gift.participations.forEach((item, _) => {
    if (item.user.id == me?.id) value = item;
  });
  return value;
};

export let isThereOtherParticipationsThanMine = (
  gift: Gift,
  me: User | null | undefined
): boolean => {
  var myParticipation = getMyParticipationForThisGift(gift, me);
  var numberOfParticipation = myParticipation
    ? gift.participations.length - 1
    : gift.participations.length;

  return numberOfParticipation > 0;
};

export let getTimesGiftIsTaken = (gift: Gift): number => {
  if (gift.price == 0) return gift.participations.filter((item) => item.participationType == 2).length;
  else {
    var amount = 0.0;
    gift.participations.forEach((item) => {
      amount = item.amount + amount;
    });
    return Math.floor(amount / gift.price);
  }
};

export let getSumOfParticipations = (gift: Gift): number => {
  if (gift.price == 0) return 0.0;
  else {
    var amount = 0.0;
    gift.participations.forEach((item) => {
      amount = item.amount + amount;
    });
    return getNumberRoundedTwoDecimals(amount);
  }
};

export let giftTakenStatus = (gift: Gift): GiftStatus => {
  if (showPriceTag(gift)) {
    if (gift.participations.length == 0) return GiftStatus.Free;
    else if (
      getNumberRoundedTwoDecimals(gift.priceLeft) == 0.0 ||
      hasAFullyTakenParticipation(gift)
    )
      return GiftStatus.Fully_Taken;
    else return GiftStatus.Partially_taken;
  } else {
    if (gift.participations.length == 0) return GiftStatus.Free;
    else if (hasAFullyTakenParticipation(gift)) return GiftStatus.Fully_Taken;
    else return GiftStatus.Partially_taken;
  }
};
