import { daysUntilToday, getBirthdayDate } from "../models/User";
import { TFunction } from "i18next";

export class NotificationInfos {
  title: string;
  textBody: string;
  deeplink: string;

  constructor(title: string, textBody: string, deeplink: string) {
    this.title = title;
    this.textBody = textBody;
    this.deeplink = deeplink;
  }
}

export function getNotificationInfosFromPayload(
  payload: any,
  t: TFunction<"translation", undefined>
): NotificationInfos {
  var title: string = "";
  var body: string = "";
  var icon: string = "";
  var deeplink: string = "";
  var datas: any = payload.data;
  var category: string = datas.category;

  if (category == "ajout2") {
    var giftId: number = datas.giftId;
    var giftName: string = datas.giftName;
    var ownerId: number = datas.ownerId;
    var ownerName: string = datas.ownerName;
    var addedById: number = datas.addedById;
    var addedByName: string = datas.addedByName;
    var giftImageUrl: string = datas.giftImageUrl;
    var ownerIsAddedBy = ownerId == addedById;
    deeplink = `https://family-gift.fr/app/gift/${giftId}`;
    title = t("giftAdded");
    body = ownerIsAddedBy
      ? t("xAddedXonHisList", {addedBy: addedByName, gift: giftName})
      : t("xAddedXonXList", {addedBy: addedByName, gift: giftName, owner: ownerName});
  } 
  else if (category == "suppression2") {
    var giftId: number = datas.giftId;
    var giftName: string = datas.giftName;
    var ownerId: number = datas.ownerId;
    var ownerName: string = datas.ownerName;
    var addedById: number = datas.addedById;
    var addedByName: string = datas.addedByName;
    var giftImageUrl: string = datas.giftImageUrl;
    var ownerIsAddedBy = ownerId == addedById;
    deeplink = `https://family-gift.fr/app/user/${ownerId}`;
    title = t("giftDeleted");
    body = ownerIsAddedBy
    ? t("xDeletedXonHisList", {addedBy: addedByName, gift: giftName})
    : t("xDeletedXonXList", {addedBy: addedByName, gift: giftName, owner: ownerName});
  } 
  else if (category == "price_updated") {
    var giftId: number = datas.gift_id;
    var giftName: string = datas.gift_name;
    var owner: string = datas.gift_owner;
    deeplink = `https://family-gift.fr/app/gift/${giftId}`;
    title = t("yourParticipationForXedited", {addedBy: owner, gift: giftName});
    body = t("yourParticipationForXedited", {gift: giftName});
  } 
  else if (category == "help_required") {
    var giftId: number = datas.gift_id;
    var giftName: string = datas.gift_name;
    var owner: string = datas.gift_owner;
    var asker: string = datas.asker_name;
    deeplink = `https://family-gift.fr/app/gift/${giftId}`;
    title = t("askerNeedHelpTitle", {asker: asker});
    body = t("askerNeedHelpBody", {asker: asker, gift: giftName, owner: owner});
  } 
  else if (category == "createdSecretSanta") {
    var house: string = datas.house;
    var houseId: number = datas.houseId;
    var creator: string = datas.creator;
    deeplink = `https://family-gift.fr/app/households/${houseId}`;
    title = t("xCreatedSecretSanta",{creator: creator});
    body = t("secretSantaCreationBody", {house: house});
  } 
  else if (category == "deletedSecretSanta") {
    var house: string = datas.house;
    var houseId: number = datas.houseId;
    var deletor: string = datas.deletor;
    deeplink = `https://family-gift.fr/app/households/${houseId}`;
    title = t("xDeletedSecretSanta", {deletor: deletor});
    body = t("secretSantaDeletionBody", {deletor: deletor, house: house});
  } 
  else if (category == "userExcluded") {
    var house: string = datas.house;
    var excludedBy: string = datas.excludedBy;
    var userExcluded: string = datas.userExcluded;
    var userExcludedId: number = Number.parseInt(datas.userExcludedId);
    var userNotifiedId: number = Number.parseInt(datas.userNotifiedId);

    deeplink = `https://family-gift.fr/app/myList`;
    title = t("exclusionFromX", {house: house});
    body =
      userExcludedId == userNotifiedId
        ? t("youExcludedBy", {excludedBy: excludedBy,house: house})
        : t("XExcludedBy", {excludedBy: excludedBy,userExcluded: userExcluded, house: house});
  } 
  else if (category == "birthday2") {
    var userId: number = Number.parseInt(datas.userId);
    var userName: string = datas.userName;
    var dataStr: string = datas.userBirthdayDate;
    var date = new Date(dataStr);
    var daysUntilBirthday = daysUntilToday(date);
    var isBirthdayToday = daysUntilBirthday === 0;
    if (daysUntilBirthday != null) {
      deeplink = `https://family-gift.fr/app/user/${userId}`;
      title = isBirthdayToday
        ? t("todayIsTheDay")
        : t("birthdaySoon");
      body = isBirthdayToday
        ? t("itsXbirthday", {userName: userName})
        : daysUntilBirthday > 8
        ? t("itsXbirthday2weeks", {userName: userName})
        : t("itsXbirthday1week", {userName: userName});
    }
  }

  return new NotificationInfos(title, body, deeplink);
}
