import axios from "axios";

//export const baseUrl = "https://dev.api.family-gift.fr:8443/family_gift/"
//export const baseUrl = "http://192.168.1.25:8080/family_gift/"
export const baseUrl = "https://api2.family-gift.fr/family_gift/"
//export const baseUrl = "http://localhost:8080/family_gift/"

const apiClient = axios.create({
    baseURL: baseUrl,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
      },
});

export const fetchData = async (endpoint) => {
  try {
    const response = await apiClient.get(endpoint);
    console.log("RESPONSE PASSWORD : ", response)
    return response.data;
  } catch (error) {
    throw error; // Propagate the error to the caller
  }
};

export const postData = async (endpoint, data) => {
  try{
  const response = await apiClient.post(endpoint, data);
  return response.data;
} catch (error) {
  throw error;
}
};

