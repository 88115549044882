import React, { FormEvent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../api/AuthProvider";
import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  ListItem,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import { House } from "../../../../models/House";
import { useTheme } from "@emotion/react";
import MyNetworkImage from "../../../ReusableComponents/MyNetworkImage";
import { NavigationOutlined } from "@mui/icons-material";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { IconHouseUser } from "../../WebAppNavBar/WebAppNavBar";
import MyFab from "../../../ReusableComponents/MyFab";
import CarouselTutorial from "../../../ReusableComponents/CarouselTutorial";
import { useTranslation } from "react-i18next";
import { MyPaperPropsDialog } from "../../../ReusableComponents/MyWaitingDialog";
import { OutlinedButton } from "./HouseDetailPage";

function MyHousesPage() {
  let navigate = useNavigate();
  var auth = useContext(AuthContext);
  const {t} = useTranslation();
  document.title = t("myHouseholds", {count:auth?.user?.houses.length});

  var carouselItemsEmptyList = [
    {
      title: t("title1EmptyHouse"),
      subtitle:t("subtitle1EmptyHouse"),
      icon: "../assets/home.svg",
    },
    {
      title:t("title2EmptyHouse"),
      subtitle:t("subtitle2EmptyHouse"),
      icon: "../assets/incognito.svg",
    },
    {
      title:t("title3EmptyHouse"),
      subtitle:t("subtitle3EmptyHouse"),
      icon: "../assets/santa-claus.svg",
    },
  ];
  const [askCreateOrJoin, setAskCreateOrJoin] = useState(false);
  const [askHouseCode, setAskHouseCode] = useState(false);
  const [houseCode, setHouseCode] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const regex = /^[a-zA-Z0-9]{7}$/;

  useEffect(()=>{
    setIsFormInvalid(!regex.test(houseCode));
  }, [houseCode])

  return (
    <div className="list-page">
      
      <Dialog PaperProps={MyPaperPropsDialog} open={askCreateOrJoin} onClose={()=>{setAskCreateOrJoin(false)}}>
        <DialogTitle fontSize={20} fontWeight={"bold"} color={"primary"}>{t("whatDoYouWantToDo")}</DialogTitle>
        <DialogActions sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyItems:"stretch"}}>
        <OutlinedButton
          variant="outlined"
          sx={{width: "100%"}}
          onClick={() => {
            setAskCreateOrJoin(false);
            setAskHouseCode(true)
          }}
        >{t("joinExistingHousehold")}
        </OutlinedButton>
        <OutlinedButton
          color="primary"
          variant="outlined"
          sx={{width: "100%"}}

          onClick={() => {
            setAskCreateOrJoin(false);
            navigate("create")
          }}
        >
          {t("createHousold")}
        </OutlinedButton>
        </DialogActions>
      </Dialog>
      <Dialog PaperProps={MyPaperPropsDialog} open={askHouseCode} onClose={()=>{setAskHouseCode(false)}}>
        <DialogTitle fontSize={20} fontWeight={"bold"} color={"primary"}>{t("pleaseEnterCode")}</DialogTitle>
        <DialogContent><Typography fontStyle={"italic"} fontSize={16}>{t("warningCodeHousehold")}</Typography></DialogContent>
        <TextField 
        required
        variant="outlined"
        error={isFormInvalid}
        onChange={(e) => {setHouseCode(e.target.value)}}
        value={houseCode}
        fullWidth
        color="primary"
        type="text"
        helperText={isFormInvalid ? t("codeInvalid") : null}
        InputProps={{
          style: {
            borderRadius: "16px",
          },
        }}
        label={t("yourHouseholdCode")}
        sx={{
          marginTop:"16px",
          background: "white",
          borderRadius: "16px",
        }}
        ></TextField>
        <DialogActions sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyItems:"stretch"}}>
        <OutlinedButton
          type="submit"
          disabled={isFormInvalid}
          variant="outlined"
          sx={{width: "100%"}}
          onClick={() => {
            setAskHouseCode(false);
            navigate(`join/${houseCode}`)
          }}
        >
          {t("validate")}
        </OutlinedButton>
        </DialogActions>
      </Dialog>
      <MyFab
        onClick={() => {
          setAskCreateOrJoin(true)
        }}
        Icon={IconHouseUser}
        text={t("createHousehold")}
      />
      <Typography variant="h4" color="primary" padding={"8px"}>
        {t("myHouseholds", {count:auth?.user?.houses.length})}
      </Typography>
      {(auth?.user?.houses ?? []).length > 0 ? (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"flex-start"}
          sx={{ margin: "auto", transition: "all 0.3s" }}
        >
          {(auth?.user?.houses ?? []).map((item, i) => (
            <HouseItem key={item.id} house={item} />
          ))}
        </Grid>
      ) : (
        <CarouselTutorial items={carouselItemsEmptyList} />
      )}

      <Box height={"70px"}></Box>
    </div>
  );
}

export function HouseItem(props: { house: House }) {
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  let navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={6}
      sx={{
        padding: "4px",
        aspectRatio: "3 / 2",
      }}
    >
      <ButtonBase
        sx={{
          zIndex: 13,
          width: "100%",
          aspectRatio: "3 / 2",
          transition: "all 0.3s",

          "&:hover": {
            zIndex: 130,
            scale: "1.1",
          },
        }}
        onClick={() => {
          navigate(`${props.house.id}`, { state: { house: props.house } });
        }}
      >
        <Box
          sx={{
            textTransform: "capitalize",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            background: "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
            borderRadius: "16px",
            clipPath: "border-box",
            border: "solid 1px",
            borderColor: "primary.light",
          }}
        >
          {props.house.secretSanta.length > 0 ? <Box sx={{
            display:"flex",
            flexDirection:"column",
            width:"25%",
            position:"absolute",
            padding:"4px",
            borderRadius:"12px",
            top:"1rem",
            right:"1rem",
            bgcolor:"primary.light",
            alignItems:"center",
            zIndex:"300"
          }}>
            <img src="../assets/family_gift_logo_simple.svg" style={{width:"25px", height:"25px"}}/>
            <Typography alignItems={"center"} fontWeight={"bold"} fontSize={13} padding={"4px"} textTransform={"none"} color={"primary"}>{t("secretSantaInProgress")}</Typography>
          </Box> : <></>}
          <MyNetworkImage
            url={props.house.pictureUrl}
            alt={props.house.name}
            cover={true}
          />
          <Box
            sx={{
              padding: "6px",
              position: "absolute",
              width: "100%",
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 1200,
              background: "#ffffffce",
              borderRadius: "0px 0px 16px 16px",
            }}
          >
            <Typography fontWeight={"bold"} fontSize={20} color={"primary"}>
              {props.house.name}
            </Typography>
            <Typography
              color={"dark"}
              variant="subtitle2"
              fontStyle={"oblique"}
            >
              {t("xMembers", {count: props.house.users.length})}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    </Grid>
  );
}

export default MyHousesPage;
