import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  LinearProgress,
  Rating,
  Typography,
  createTheme,
  useMediaQuery,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Collapse,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "../../../../api/AuthProvider";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Gift,
  GiftStatus,
  getAffiliatedLink,
  getHostName,
  getMyParticipationForThisGift,
  getNumberRoundedTwoDecimals,
  getSumOfParticipations,
  getTimesGiftIsTaken,
  giftTakenStatus,
  isThereOtherParticipationsThanMine,
} from "../../../../models/Gift";
import { useQuery } from "react-query";
import { apiClient, apiGiftClient } from "../../../../api/api";
import MyNetworkImage from "../../../ReusableComponents/MyNetworkImage";
import AvatarPicture from "../../../ReusableComponents/AvatarPicture";
import { Favorite } from "@mui/icons-material";
import PriceTag, {
  TagSize,
  getPriceWithCurrency,
} from "../../../ReusableComponents/PriceTag";
import {
  Participation,
  ParticipationGiftDto,
} from "../../../../models/Participation";
import { HouseWithoutUsers } from "../../../../models/House";
import { enqueueSnackbar } from "notistack";
import MyWaitingDialog, {
  MyPaperPropsDialog, MyWaitingAnimation, MyWaitingPage,
} from "../../../ReusableComponents/MyWaitingDialog";
import { OutlinedButton } from "../HouseHolds/HouseDetailPage";
import EditGiftDialog from "./EditGiftDialog";
import { SettingsContext } from "../../../../utils/SettingsProvider";
import { areTheyInCommonSecretSante } from "../../../../models/User";
import { useTranslation } from "react-i18next";
import useSessionStorage from "../../../../utils/useSessionStorage";
import { AxiosError } from "axios";
import ErrorWidget from "../../../ReusableComponents/ErrorWidget";

function GiftPage() {
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("md"));
  var auth = useContext(AuthContext);
  const { t } = useTranslation();

  let { giftId } = useParams();
  const location = useLocation();

  let [gift, setGift] = useState<Gift>(location.state?.gift ?? null);

  const [errorCode, setErrorCode] = useState(200);

  function getErrorMessage(errorCode: number): string {
    switch(errorCode){
      case 404: return t("giftNotFound"); 
      case 401: return t("errorAuthenticating"); 
      case 403: return t("giftUnauthorized"); 
      default: return t("unknownError"); 
    }
  }
  const { data, isLoading, error } = useQuery({
    queryKey: ["gift", giftId],
    queryFn: () => apiClient.getGiftById(auth?.token ?? "", giftId).catch((error: AxiosError) => {
      setErrorCode(error.response?.status??200)
    }),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (data !== undefined) {
      setGift(data);
      document.title = gift?.name ?? "";
    }
    else {
      document.title = "Family Gift";
    }
  }, [data]);

  return (
    <Box
      sx={{
        maxWidth: "1600px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "100px",
      }}
    >
      <Typography
        fontSize={26}
        fontWeight={"bold"}
        color={"primary"}
        textAlign={"center"}
      >
        {gift?.name}
      </Typography>
      <Divider
        sx={{
          marginTop: "16px",
          width: "100%",
        }}
      />
      {isLoading ? 
        <MyWaitingPage/> : gift != null && gift != undefined ? (
        isMobile ? (
          <Box
            sx={{
              background:
                "linear-gradient(15deg, #E1D5FF 5%, #ffffff 50%,#FFDFEFFF 100%)",
              border: "solid 2px",
              borderColor: "primary.light",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              alignContent: "center",
              width: "100%",
              marginTop: "16px",
            }}
          >
            <GiftImageInfos gift={gift} />
            <GiftPrice gift={gift} />
            <GiftParticipation gift={gift} setGift={setGift} />
            <Collapse 
              in={giftTakenStatus(gift) == GiftStatus.Partially_taken &&
                getMyParticipationForThisGift(gift, auth?.user) != null }
             >
                <AskForHelpForGift gift={gift} />
              </Collapse>
            <HouseVisibilityGift gift={gift} />
            <GiftGestion gift={gift} setGift={setGift} />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginRight: "24px",
                marginLeft: "auto",
                width: "40%",
                background:
                  "linear-gradient(15deg, #E1D5FF 5%, #ffffff 50%,#FFDFEFFF 100%)",
                marginTop: "16px",
                border: "solid 2px",
                borderColor: "primary.light",
                borderRadius: "16px",
              }}
            >
              <GiftPrice gift={gift} />
              <GiftImageInfos gift={gift} />
            </Box>

            <Box
              sx={{
                alignItems: "stretch",
                justifyContent: "space-evenly",
                background:
                  "linear-gradient(-15deg, #E1D5FF 5%, #ffffff 50%,#FFDFEFFF 100%)",
                marginTop: "16px",
                border: "solid 2px",
                borderColor: "primary.light",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                width: "60%",
              }}
            >
              <GiftParticipation gift={gift} setGift={setGift} />
              <Collapse 
              in={giftTakenStatus(gift) == GiftStatus.Partially_taken &&
                getMyParticipationForThisGift(gift, auth?.user) != null }
             >
              
                <AskForHelpForGift gift={gift} />
              
              </Collapse>
              <HouseVisibilityGift gift={gift} />
              <GiftGestion gift={gift} setGift={setGift} />
            </Box>
          </Box>
        )
      ) : (
        <ErrorWidget errorCode={errorCode} errorMessage={getErrorMessage(errorCode)}/>
      )}
    </Box>
  );
}

function AskForHelpForGift(props: { gift: Gift }) {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  return (
    <Box width={"100%"} paddingTop={2} display={"flex"} flexDirection={"column"} alignItems={"center"}>
     <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("askForHelp")}
      </Typography>
      <Typography
        textAlign={"center"} fontStyle={"oblique"} fontSize={16}
      >
        {t("askForHelpBody")}
      </Typography>
      <OutlinedButton 
      onClick={() =>{
          apiClient.askForHelp(auth?.token??"", props.gift.id)
          .then(() => enqueueSnackbar(t("helpAsked"), {variant: "success"}))
          .catch(() => enqueueSnackbar(t("helpAskedFailed"), {variant: "error"}))
      }}  
      sx={{paddingLeft:4, paddingRight:4}}>{t("askForHelpButton")}</OutlinedButton>
    </Box>
  );
}

function HouseVisibilityGift(props: { gift: Gift }) {
  const { t } = useTranslation();
  return (
    <Box
      key={props.gift.id}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        width: "100%",
        flexDirection: "column",
        marginTop: "16px",
      }}
    >

      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        paddingTop={2}
        fontWeight={"bold"}
      >
        {t("visibleHomes")}
      </Typography>
      {props.gift.listHouseVisible.length == 0 ? (
        <Typography textAlign={"center"} fontStyle={"oblique"} fontSize={16}>
          {t("noHouseholdVisible")}
        </Typography>
      ) : (
        <Typography textAlign={"center"} fontStyle={"oblique"} fontSize={16}>
          {t("giftVisibleInTheseHouseholds")}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        {props.gift.listHouseVisible
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((item, _) => {
            return <HouseVisibilityItem key={item.id} house={item} />;
          })}
      </Box>
    </Box>
  );
}

function HouseVisibilityItem(props: { house: HouseWithoutUsers }) {
  return (
    <Box
      sx={{
        display: "inline-block",
        flexDirection: "column",
        margin: "8px",
        borderRadius: "17px",
        width: "150px",
        bgcolor: "#ffffff",
        alignItems: "center",
        justifyItems: "center",
        alignContent: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        border: "2px solid",
        borderColor: "primary.main",
        height: "200px",
      }}
    >
      <Box
        sx={{
          aspectRatio: "1/1",
          width: "100%",
        }}
      >
        <MyNetworkImage
          url={props.house.pictureUrl}
          alt={props.house.name}
          cover={true}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          alignItems: "center",
          height: "50px",
        }}
      >
        <Typography
          width={"100%"}
          textAlign={"center"}
          fontSize={14}
          fontWeight={"bold"}
          color={"primary"}
        >
          {props.house.name}
        </Typography>
      </Box>
    </Box>
  );
}

function GiftParticipation(props: {
  gift: Gift;
  setGift: Dispatch<SetStateAction<Gift>>;
}) {
  let { t, i18n } = useTranslation();
  let auth = useContext(AuthContext);
  let settings = useContext(SettingsContext);
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const progress = props.gift.canBeTakenMultipleTime
    ? ((getSumOfParticipations(props.gift) -
        getTimesGiftIsTaken(props.gift) * props.gift.price) /
        props.gift.price) *
      100
    : ((props.gift.price - props.gift.priceLeft) / props.gift.price) * 100;
  return props.gift.owner.id !== auth?.user?.id ? (
    <Box
      sx={{
        borderRadius: "16px",
        boxSizing: "border-box",
        padding: "16px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        width: "100%",
        flexDirection: "column",
        marginTop: "16px",
      }}
    >
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("participations")}
      </Typography>

      {props.gift.price ? (
        props.gift.canBeTakenMultipleTime ? (
          <Box sx={{ position: "relative", width: "100%" }}>
            <Typography
              fontStyle={"italic"}
              color={"primary"}
              textAlign={"center"}
            >
              {t("giftCanBeTakenMultipleTimes")}
            </Typography>
            <Typography
              fontStyle={"italic"}
              color={"primary"}
              textAlign={"center"}
            >
              {t("giftTakenXTimes", { value: getTimesGiftIsTaken(props.gift) })}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            >
              <Typography>
                {props.gift.price * getTimesGiftIsTaken(props.gift)} €
              </Typography>
              <Typography>
                {props.gift.price * (getTimesGiftIsTaken(props.gift) + 1)} €
              </Typography>
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  zIndex: "3",
                }}
                color={"#ffffff"}
                fontWeight={"bold"}
                fontSize={18}
              >
                {t("xRemaining", {value: getPriceWithCurrency(i18n.language,props.gift.price * getNumberRoundedTwoDecimals(getTimesGiftIsTaken(props.gift) + 1) - getSumOfParticipations(props.gift) )})}
          
          </Typography>
              <LinearProgress
                variant="determinate"
                color={"primary"}
                value={progress}
                sx={{
                  marginLeft: "16px",
                  marginRight: "16px",
                  position: "relative",
                  height: "30px",
                  width: "100%",
                  borderRadius: "50px",
                  zIndex: "2",
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ position: "relative", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            >
              <Typography>0 €</Typography>
              <Typography>{props.gift.price} €</Typography>
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  zIndex: "3",
                }}
                color={"#ffffff"}
                fontWeight={"bold"}
                fontSize={18}
              >
                {t("xRemaining", {value: getPriceWithCurrency(i18n.language, props.gift.priceLeft)})}
              </Typography>
              <LinearProgress
                variant="determinate"
                color={"primary"}
                value={progress}
                sx={{
                  marginLeft: "16px",
                  marginRight: "16px",
                  position: "relative",
                  height: "30px",
                  width: "100%",
                  borderRadius: "50px",
                  zIndex: "2",
                }}
              />
            </Box>
          </Box>
        )
      ) : (
        <></>
      )}
      {props.gift.participations.length == 0 ? (
        <Typography padding={1} fontStyle={"italic"}>
          {t("noOneParticipatesYet")}
        </Typography>
      ) : (
        <Box
          sx={{
            marginTop: "16px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          {!settings?.hideParticipation &&
          !areTheyInCommonSecretSante(auth?.user, props.gift.owner) ? (
            props.gift.participations.map((item, _) => (
              <ParticipationItem
                key={props.gift.id + item.user.id}
                participation={item}
              />
            ))
          ) : (
            <>
              {getMyParticipationForThisGift(props.gift, auth?.user) ? (
                <ParticipationItem
                  key={props.gift.id + (auth?.user?.id ?? 0)}
                  participation={
                    getMyParticipationForThisGift(props.gift, auth?.user)!
                  }
                />
              ) : (
                <></>
              )}
              {isThereOtherParticipationsThanMine(props.gift, auth?.user) ? (
                <HideParticipationItem
                  causeHiding={
                    !settings?.hideParticipation ? "secret_santa" : "settings"
                  }
                />
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      )}
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        marginTop={"8px"}
        fontWeight={"bold"}
      >
        {t("yourParticipation")}
      </Typography>
      <ParticipationButtons gift={props.gift} setGift={props.setGift} />
    </Box>
  ) : (
    <></>
  );
}

function ParticipationButtons(props: {
  gift: Gift;
  setGift: Dispatch<SetStateAction<Gift>>;
}) {
  let auth = useContext(AuthContext);
  let myParticipation = getMyParticipationForThisGift(props.gift, auth?.user);
  let [sendParticipation, setSendParticipation] = useState(false);
  let [partialParticipation, setPartialParticipation] = useState(false);
  let [participationTemps, setParticipationTemps] = useState<number | string>(
    myParticipation?.amount ?? ""
  );
  let [errorPart, setErrorPart] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    myParticipation = getMyParticipationForThisGift(props.gift, auth?.user);
    setParticipationTemps(myParticipation?.amount ?? "");
  }, [props.gift]);

  useEffect(() => {
    if (!props.gift.canBeTakenMultipleTime) {
      let participationMax =
        props.gift.priceLeft + (myParticipation?.amount ?? 0);
      setErrorPart(
        parseFloat(participationTemps.toString()) > participationMax
      );
    } else setErrorPart(false);
  }, [participationTemps]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyItems: "center",
        justifyContent: "center",
      }}
    >
      <MyWaitingDialog
        open={sendParticipation}
        message={t("sendingYourParticipation")}
      />
      <Dialog PaperProps={MyPaperPropsDialog} open={partialParticipation}>
        <img
          key={"Family Gift"}
          src="/assets/family_gift_logo_simple.svg"
          alt={"Family Gift"}
          loading="eager"
          draggable="false"
          style={{
            margin: "auto",
            display: "inline-block",
            width: "80px",
            height: "80px",
          }}
        />
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("whatIsAmountParticipation")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.gift.canBeTakenMultipleTime ? (
              <></>
            ) : (
              <Typography
                textAlign={"center"}
                color={errorPart ? "error" : "primary"}
                fontWeight={"bold"}
              >
                {t("maxAmount", {
                  value: getPriceWithCurrency(
                    i18n.language,
                    props.gift.price
                      ? props.gift.priceLeft + (myParticipation?.amount ?? 0)
                      : 0
                  ),
                })}
              </Typography>
            )}
          </DialogContentText>
          <TextField
            variant="outlined"
            value={participationTemps}
            error={errorPart}
            onChange={(e) => {
              var value = e.target.value.replace(",", ".");
              let actualPrice = parseFloat(parseFloat(value).toFixed(2));
              if (actualPrice) {
                setParticipationTemps(actualPrice);
              } else {
                setParticipationTemps("");
              }
            }}
            fullWidth
            color="primary"
            type="number"
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            label={t("yourParticipation")}
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            variant="outlined"
            autoFocus
            onClick={() => {
              setPartialParticipation(false);
            }}
          >
            {t("cancel")}
          </OutlinedButton>
          <OutlinedButton
            color="primary"
            variant="outlined"
            onClick={() => {
              setPartialParticipation(false);
              setSendParticipation(true);
              let actualPrice = parseFloat(
                parseFloat(participationTemps.toString()).toFixed(2)
              );
              let body;
              console.log("actual price : ", actualPrice);
              if (actualPrice == 0 || Number.isNaN(actualPrice)) {
                setPartialParticipation(false);
                setSendParticipation(false);
              } else {
                body = new ParticipationGiftDto(props.gift.id, 1, actualPrice);
                console.log("body : ", body);
                apiClient
                  .participateToGift(body, auth?.token ?? "")
                  .then((data) => {
                    props.setGift(data.data);
                    setSendParticipation(false);
                    if (
                      getMyParticipationForThisGift(data.data, auth?.user) ==
                      null
                    ) {
                      enqueueSnackbar(
                        "Votre participation n'a pas été prise en compte car il semble que quelqu'un l'ait pris avant vous",
                        { variant: "error" }
                      );
                    } else {
                      enqueueSnackbar("Participation enregistrée !", {
                        variant: "success",
                      });
                    }
                  });
              }
            }}
            disabled={errorPart}
            autoFocus
          >
            {t("validate")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>
      {giftTakenStatus(props.gift) == GiftStatus.Fully_Taken &&
      getMyParticipationForThisGift(props.gift, auth?.user) == null &&
      !props.gift.canBeTakenMultipleTime ? (
        <Typography>{t("giftAlreadyTakenYouCant")}</Typography>
      ) : (
        <>
          <ParticipationButtonItem
            onClick={(event) => {
              setSendParticipation(true);
              let amountTemp = props.gift.price
                ? props.gift.canBeTakenMultipleTime
                  ? props.gift.price
                  : props.gift.priceLeft + (myParticipation?.amount ?? 0)
                : 0;
              let body = new ParticipationGiftDto(props.gift.id, 2, amountTemp);
              apiClient
                .participateToGift(body, auth?.token ?? "")
                .then((data) => {
                  props.setGift(data.data);
                  setSendParticipation(false);
                  if (
                    getMyParticipationForThisGift(data.data, auth?.user) == null
                  ) {
                    enqueueSnackbar(t("someoneFaster"), { variant: "error" });
                  } else {
                    enqueueSnackbar(t("registeredParticipation"), {
                      variant: "success",
                    });
                  }
                });
            }}
            selected={myParticipation?.participationType == 2}
            labelSelected={t("iTakeThis")}
            labelUnselected={t("iWantToTotallyTakeThis")}
            participation={myParticipation}
          />
          <ParticipationButtonItem
            onClick={(event) => {
              let amountTemp = props.gift.price
                ? props.gift.priceLeft + (myParticipation?.amount ?? 0)
                : 0;
              if (props.gift.price) {
                setPartialParticipation(true);
              } else {
                setPartialParticipation(false);
                setSendParticipation(true);
                let actualPrice = parseFloat(
                  parseFloat(participationTemps.toString()).toFixed(2)
                );
                let body = new ParticipationGiftDto(props.gift.id, 1, 0);
                console.log("body : ", body);
                apiClient
                  .participateToGift(body, auth?.token ?? "")
                  .then((data) => {
                    props.setGift(data.data);
                    setSendParticipation(false);
                    if (
                      getMyParticipationForThisGift(data.data, auth?.user) ==
                      null
                    ) {
                      enqueueSnackbar(t("someoneFaster"), { variant: "error" });
                    } else {
                      enqueueSnackbar(t("registeredParticipation"), {
                        variant: "success",
                      });
                    }
                  });
              }
            }}
            selected={myParticipation?.participationType == 1}
            labelSelected={t("iPartiallyParticipate", {
              count: myParticipation?.amount ?? 0,
              value: getPriceWithCurrency(
                i18n.language,
                myParticipation?.amount ?? 0
              ),
            })}
            labelUnselected={t("iWantToParticipateBuNotAlone")}
            participation={myParticipation}
          />
          <ParticipationButtonItem
            selected={myParticipation == null}
            labelSelected={t("dontParticipate")}
            labelUnselected={t("dontWantToParticipateAnymore")}
            participation={myParticipation}
            onClick={(event) => {
              setSendParticipation(true);
              let body = new ParticipationGiftDto(props.gift.id, 0, 0);
              apiClient
                .participateToGift(body, auth?.token ?? "")
                .then((data) => {
                  props.setGift(data.data);
                  setSendParticipation(false);
                  enqueueSnackbar(t("registeredParticipation"), {
                    variant: "success",
                  });
                });
            }}
          />
        </>
      )}
    </Box>
  );
}

function ParticipationButtonItem(props: {
  selected: boolean;
  labelSelected: string;
  labelUnselected: string;
  participation: Participation | null;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  let theme = useTheme();
  let innerTheme = createTheme(theme);

  return (
    <Button
      onClick={(e) => props.onClick(e)}
      sx={{
        margin: "4px",
        backgroundColor: props.selected ? "primary.main" : "#ffffff",
        aspectRatio: "6/2",
        textTransform: "none",
        minWidth: "160px",
        borderRadius: "16px",
        boxSizing: "border-box",
        border: "3px solid",
        borderColor: "primary.main",
        width: "40%",
        maxWidth: "280px",
        "&:hover": {
          bgcolor: props.selected ? "primary.main" : "primary.light",
        },
      }}
    >
      <Typography
        color={props.selected ? "#ffffff" : "primary"}
        fontWeight={"bold"}
        fontSize={14}
      >
        {props.selected ? props.labelSelected : props.labelUnselected}
      </Typography>
    </Button>
  );
}

function ParticipationItem(props: { participation: Participation }) {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        height: "175px",
        bgcolor: "#ffffff",
        borderRadius: "16px",
        margin: "4px",
        padding: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
      }}
    >
      <Box height={"120px"} width={"120px"}>
        <AvatarPicture
          url={props.participation.user.photoUrl}
          alt={props.participation.user.username}
        />
      </Box>
      <Typography
        marginTop={"4px"}
        padding={"4px"}
        color={"primary"}
        fontWeight={"bold"}
      >
        {props.participation.user.username}
      </Typography>
      {props.participation.amount != 0.0 ? (
        <Typography fontWeight={"bold"} fontSize={14}>
          {getPriceWithCurrency(i18n.language, props.participation.amount)}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
}

function HideParticipationItem(props: {
  causeHiding: "settings" | "secret_santa";
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: "175px",
        maxWidth: "400px",
        bgcolor: "#ffffff",
        borderRadius: "16px",
        margin: "4px",
        padding: "8px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={"../../assets/lock_primary.svg"}
        style={{
          padding: "16px",
          height: "75px",
          aspectRatio: "1/1",
        }}
      />

      <Typography
        marginTop={"4px"}
        padding={"4px"}
        color={"primary"}
        fontWeight={"bold"}
        textAlign={"center"}
      >
        {props.causeHiding == "settings"
          ? t("settingsHideParticipations")
          : t("hideParticipationSecretSanta")}
      </Typography>
    </Box>
  );
}

function GiftPrice(props: { gift: Gift }) {
  let auth = useContext(AuthContext);
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const { t, i18n } = useTranslation();
  return props.gift.price ? (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "row",
        marginTop: "16px",
        padding: "16px",
      }}
    >
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("thisGiftCosts")}
      </Typography>
      <PriceTag
        line={false}
        big={TagSize.M}
        priceToShow={props.gift.price}
        sx={{
          paddingLeft: "8px",
          rotate: "12deg",
        }}
      />
    </Box>
  ) : (
    <></>
  );
}

function GiftImageInfos(props: { gift: Gift }) {
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  const [linkOpened, setLinkOpened] = useSessionStorage(
    "link_opened",
    []
  );
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        padding: "16px",
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxHeight: "40vh",
          aspectRatio: isMobile ? "4/2" : "1 / 1",
        }}
      >
        <MyNetworkImage
          url={props.gift?.photoUrl}
          alt={props.gift?.name ?? ""}
          cover={false}
        />
      </Box>

      <Box
        sx={{
          margin: "auto",
          maxWidth: "400px",
          padding: "8px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography fontSize={18} margin={2} fontWeight={"bold"} color={"primary"}>
          {t("giftForX", { value: props.gift.owner.username })}
        </Typography>
        <Box width={100} height={100} marginBottom={2}>
          <AvatarPicture
            url={props.gift.owner.photoUrl}
            alt={props.gift.owner.username}
          />
        </Box>
        {props.gift.rate !== null && props.gift.rate != 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyItems: "center",
              justifyContent: "center",
              marginTop: "8px",
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Box
              sx={{
                bgcolor: innerTheme.palette.primary.light,
                padding: "0px 16px",
                paddingTop: "4px",
                borderRadius: "1500px",
              }}
            >
              <Rating
                readOnly
                precision={1}
                value={props.gift.rate}
                defaultValue={props.gift.rate}
                max={props.gift.rate}
                icon={<Favorite color="primary" />}
                emptyIcon={<Favorite color="error" />}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        {getAffiliatedLink(props.gift) ? (
          <Box sx={{width:"100%"}} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Link to={getAffiliatedLink(props.gift) ?? ""} target="_blank">
              <OutlinedButton onClick={(event) => {
                if (getAffiliatedLink(props.gift)) {
                  (linkOpened as string[]).push(getAffiliatedLink(props.gift)??"");
                  setLinkOpened(linkOpened)
                }
            }}>{t("goToWebsite")}</OutlinedButton>
            </Link>
            <Typography textAlign={"center"}>
              {getHostName(props.gift.giftUrl ?? "")}
            </Typography>
            {linkOpened.includes(getAffiliatedLink(props.gift)) ? <Typography fontSize={14} marginTop={1} fontStyle={"italic"}>{t("youAlreadyOpenedThisLinkDuringSession")}</Typography> : <></>}

          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box
      marginTop={2}
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          alignContent: "center",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography fontSize={16} fontWeight={"bold"} color={"primary"} fontStyle={"italic"}>
          {t("giftAddedByX", { value: props.gift.addedBy.username })}
        </Typography>
        <Box marginLeft={1}  width={40} height={40}>
          <AvatarPicture
            url={props.gift.addedBy.photoUrl}
            alt={props.gift.addedBy.username}
          />
        </Box>
      </Box>
      {(props.gift.giftComment ?? "").length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "16px",
            marginTop: "16px",
            maxWidth: "100%",
            bgcolor: "#ffffff",
            border: "1px solid",
            borderColor: "primary.main",
            alignItems: "center",
            padding: "16px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              alignContent: "center",
              justifyItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Typography
              padding={"4px 4px 4px 8px"}
              fontWeight={"bold"}
              fontSize={14}
              color={"primary"}
            >
              {t("commentFromX", { value: props.gift.addedBy.username })}
            </Typography>
            <Box marginLeft={1} width={30} height={30}>
              <AvatarPicture
                url={props.gift.addedBy.photoUrl}
                alt={props.gift.addedBy.username}
              />
            </Box>
          </Box>
          <Typography
            sx={{ marginTop: "16px" }}
            textAlign={"center"}
            fontSize={16}
            fontStyle={"italic"}
          >
            {props.gift.giftComment}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

function GiftGestion(props: {
  gift: Gift;
  setGift: Dispatch<SetStateAction<Gift>>;
}) {
  let auth = useContext(AuthContext);
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const giftIsMine = auth?.user?.id == props.gift.owner.id;
  const [askDeleteGift, setAskDeleteGift] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [editGift, setEditGift] = useState(false);

  useEffect(() => {
    const onHashChange = () => {
      setEditGift(window.location.hash === "#edit");
    };
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  function handleClickOpen() {
    window.location.hash = "#edit";
  }

  function handleClose() {
    window.history.back();
  }

  return auth?.user?.id == props.gift.addedBy.id ? (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "column",
        marginTop: "16px",
        padding: "16px",
      }}
    >
      <EditGiftDialog
        open={editGift}
        closeDialog={(newGift) => {
          handleClose();
          setEditGift(false);
          if (newGift) {
            props.setGift(newGift);
          }
        }}
        gift={props.gift}
      />
      <MyWaitingDialog
        open={deleteInProgress}
        message={t("deleteGiftInProgress")}
      />
      <Dialog PaperProps={MyPaperPropsDialog} open={askDeleteGift}>
        <img
          key={"Family Gift"}
          src="/assets/family_gift_logo_simple.svg"
          alt={"Family Gift"}
          loading="eager"
          draggable="false"
          style={{
            margin: "auto",
            display: "inline-block",
            width: "80px",
            height: "80px",
          }}
        />
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("deleteThisIdea")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              textAlign={"center"}
              color={"error"}
              fontWeight={"bold"}
            >
              {t("thisActionIsIrreversible")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            variant="outlined"
            autoFocus
            onClick={() => {
              setAskDeleteGift(false);
            }}
          >
            {t("no")}
          </OutlinedButton>
          <OutlinedButton
            color="error"
            variant="outlined"
            onClick={() => {
              setAskDeleteGift(false);
              setDeleteInProgress(true);
              apiGiftClient
                .deleteGift(auth?.token ?? "", props.gift.id)
                .then(() => {
                  auth?.refreshUser();
                  setDeleteInProgress(false);
                  enqueueSnackbar("Cadeau correctement supprimé !", {
                    variant: "success",
                  });
                  navigate(-1);
                })
                .catch((error) => {
                  setDeleteInProgress(false);
                });
            }}
            autoFocus
          >
            {t("yes")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("giftGestion")}
      </Typography>
      <OutlinedButton sx={{ width: "100%" }} onClick={handleClickOpen}>
        {t("editThisGift")}
      </OutlinedButton>
      <OutlinedButton
        sx={{ width: "100%" }}
        onClick={(e) => {
          setAskDeleteGift(true);
        }}
      >
        {giftIsMine
          ? t("deleteThisGiftMe")
          : t("deleteThisGiftOther", { value: props.gift.owner.username })}
      </OutlinedButton>
    </Box>
  ) : (
    <></>
  );
}

export default GiftPage;
