import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { LoginType } from "./LoginPage";
import {
  Visibility,
  VisibilityOff,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import {
  BodyLoginWithFacebook,
  BodyLoginWithGoogle,
  BodyLoginWithMail,
  BodySigninWithApple,
  FeedbackAppleLogin,
  LoginProvider,
} from "../../../models/apiBody/BodyLoginWithMail";
import { apiClient } from "../../../api/api";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import {
  CredentialResponse,
  GoogleLogin,
} from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import AppleLogin from "react-apple-login";
import {
  deleteLocalStorageAfterLogout,
  getDecodedToken,
  saveAppleElement,
  saveLoginState,
} from "../../../localStorageUtils";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../api/AuthProvider";
import { fbLogin, getFacebookLoginStatus } from "../../../utils/FacebookSDK";

const LoginFormulaire = (props: {
  loginType: LoginType;
  setLoginType: React.Dispatch<React.SetStateAction<LoginType>>;
  pathToGo: string
}) => {
  const { t } = useTranslation();
  let auth = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [loginInProgress, setLoginInProgress] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("md"));

  const appleResponse = async (response: FeedbackAppleLogin) => {
    console.log(response);
    const body = new BodySigninWithApple(
      " ",
      response.authorization.id_token,
      response.authorization.code,
      response.authorization.code
    );

    const loginWithApple = await apiClient
      .loginWithApple(body)
      .then((apiToken) => {
        console.log(apiToken);
        saveLoginState(LoginProvider.Apple, "", "", apiToken.data.token);
        saveAppleElement(response.authorization.id_token as string, apiToken.data.refreshToken);
        successLogin();
      })
      .catch((error) => {
        deleteLocalStorageAfterLogout();
        setLoginInProgress(false);
        setErrorLogin(t("unknownErrorDuringSignup"));
      });
  };

  function successLogin() {

  
      navigate(props.pathToGo, {
        replace: true,
        });

    auth?.setUserConnected(true);
    auth?.setToken(getDecodedToken());
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log(email, password);
    const data = new BodyLoginWithMail(email, password);
    setLoginInProgress(true);

    await apiClient
      .login(data)
      .then((response) => {
        saveLoginState(
          LoginProvider.MailPassword,
          email,
          password,
          response.data.token
        );
        setLoginInProgress(false);
        successLogin();
      })
      .catch((error) => {
        deleteLocalStorageAfterLogout();
        setLoginInProgress(false);
        switch ((error as AxiosError).response?.status) {
          case 497:
            setErrorLogin(t("tryUseSocialNetwork"));
            break;
          case 498:
            setErrorLogin(t("wrongPassword"));
            break;
          case 499:
            setErrorLogin(t("adressMailNotFound"));
            break;
          default:
            setErrorLogin(t("serverError"));
        }
      });
  };

  async function loginWithGoogle(credentialResponse: CredentialResponse) {
    let body = new BodyLoginWithGoogle(credentialResponse.credential);
    setLoginInProgress(true);

    try {
      const result = await apiClient.loginWithGoogle(body).then((login) => {
        saveLoginState(LoginProvider.Google, "", "", login.data.token);
      });
      successLogin();
      console.log(result);
    } catch (error) {
      deleteLocalStorageAfterLogout();
      setLoginInProgress(false);
      switch ((error as AxiosError).response?.status) {
        case 498:
          setErrorLogin(t("adressMailUsedWithPassword"));
          return;
        case 499:
          setErrorLogin(t("adressMailNotFound"));
          return;
        default:
          t("serverError");
          return;
      }
    }
  }

  async function loginWithFacebook() {
    console.log("LOGIN FACEBOOK");
    setLoginInProgress(true);
    var userId = -1;
    var accessToken = "";
    var logged = await getFacebookLoginStatus();
    if(logged.status == "connected"){
      console.log("LOGGED ANSWER", logged);
      userId = Number(logged.authResponse.userID);
      accessToken = logged.authResponse.accessToken;
    } else {
      var loginStatus = await fbLogin();
      userId = Number(loginStatus.authResponse.userID);
      accessToken = loginStatus.authResponse.accessToken;
    }
  
    console.log("LOGIN STATUS", loginStatus, logged);
    apiClient
      .loginWithFacebook(new BodyLoginWithFacebook(userId, accessToken))
      .then((apiToken) => {
        console.log(apiToken);
        saveLoginState(LoginProvider.Facebook, "", "", apiToken.data.token);
        successLogin();
        setLoginInProgress(false);
      })
      .catch((error) => {
        deleteLocalStorageAfterLogout();
        setLoginInProgress(false);
        switch ((error as AxiosError).response?.status) {
          case 497:
            setErrorLogin("Empty Mail address from Facebook. Did you give the authorization for accessing the mail address ?");
            return;
          case 498:
            setErrorLogin(t("adressMailUsedWithPassword"));
            return;
          case 499:
            setErrorLogin(t("adressMailNotFound"));
            return;
          default:
            t("serverError");
            return;
        }
      });
   
  }

  return props.loginType == LoginType.Login ? (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        paddingLeft: "24px",
        paddingRight: "24px",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      
      <img
        src="/assets/family_gift_logo_simple.svg"
        alt="Family Gift Logo"
        style={{ height: "150px", padding: "8px" }}
      />
      <Typography
        variant="h3"
        fontWeight={"bold"}
        color={"primary"}
        textAlign={"center"}
      >
        {t("login")}
      </Typography>
      <Typography
        variant="h5"
        padding={"16px"}
        fontWeight={"500"}
        color={"primary"}
        textAlign={"center"}
      >
        {t("usingYourSocialNetworks")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <GoogleLogin
          theme="outline"
          size="large"
          text="signin_with"
          type="icon"
          shape="pill"
          context="signin"
          cancel_on_tap_outside={true}
          useOneTap={false}
          onSuccess={(credentialResponse) => {
            loginWithGoogle(credentialResponse);
            console.log(credentialResponse);
          }}
          onError={() => {
            console.log("Login failed");
          }}
        />
      <Button
              sx={{
                marginLeft: "20px",
                marginRight: "20px",
                maxWidth: "35px",
                height: "35px",
                borderRadius: "50px",
                "&:hover": {
                  opacity: "0.7",
                },
              }}
              onClick={() => loginWithFacebook()}
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                minWidth: "35px",
                minHeight: "35px",
                backgroundImage: "url('./assets/Facebook_Logo_Primary.png')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></Button>
        
        <AppleLogin
          clientId="com.trempette.familyGiftReactService"
          redirectURI="https://family-gift.fr/login"
          usePopup={true}
          callback={appleResponse}
          scope="email name"
          responseMode="query"
          render={({ onClick }) => (
            <Button
              sx={{
                maxWidth: "35px",
                height: "35px",
                borderRadius: "50px",
                "&:hover": {
                  opacity: "0.5",
                },
              }}
              onClick={onClick}
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                minWidth: "35px",
                minHeight: "35px",
                backgroundImage: "url('./assets/appleid_button@2x.png')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></Button>
          )}
        />
      </Box>
      <Typography
        variant="h5"
        padding={"16px"}
        fontWeight={"500"}
        color={"primary"}
        textAlign={"center"}
      >
        {t("orUsingMailAdress")}
      </Typography>
      <Box
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "600px",
          display:"flex",
          flexDirection:"column",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <form onSubmit={handleSubmit} style={{display:"table-column-group"}}>
          
          <TextField
            required
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
            color="primary"
            type="email"
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            label={t("yourMailAdress")}
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
            }}
          />

          <TextField
            required
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            label={t("yourPassword2")}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "16px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            color="primary"
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
            }}
          />
          <Button fullWidth sx={{textTransform:"none", textDecoration:"underline"}} onClick={()=>{
            navigate("/resetPassword")
          }}>{t("forgottenPassword")}</Button>
          <LoadingButton
            sx={{
              marginTop: "24px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            loading={loginInProgress}
            loadingIndicator={t("connection")+" ..."}
            disableElevation
            style={{
              fontWeight: "bold",
              borderRadius: 50,
            }}
            variant="contained"
            color="primary"
            type="submit"
          >
            {t("connection")}
          </LoadingButton>
        </form>
        {errorLogin.length !== 0 ? (
          <Box
            sx={{
              border: 2,
              borderColor: "primary.main",
              bgcolor: "#FFFFFF",
              marginTop: "16px",
              borderRadius: "16px",
              padding: "16px",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <WarningAmberOutlined color="primary" sx={{ fontSize: 30 }} />
            <Typography
              color={"primary.main"}
              fontWeight={"600"}
              paddingLeft={"16px"}
            >
              {t("errorMessage",{value: errorLogin})}
            </Typography>
          </Box>
        ) : (
          <Box />
        )}
        {isMobile ? (
          <Box>
            <Divider
              sx={{
                padding: "16px",
              }}
            />
            <Typography
              variant="h6"
              fontWeight={"500"}
              color={"primary"}
              marginTop={"16px"}
            >
              {t("dontHaveAnAccountYet")}
            </Typography>
            <Button
              sx={{
                marginTop: "24px",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "24px",
                color: "primary.main",
              }}
              disableElevation
              style={{
                backgroundColor: "white",
                fontWeight: "bold",
                borderRadius: 50,
              }}
              variant="contained"
              onClick={() =>
                props.loginType == LoginType.Login
                  ? props.setLoginType(LoginType.Signup)
                  : props.setLoginType(LoginType.Login)
              }
            >
              {t("createAnAccount")}
            </Button>
          </Box>
        ) : (
          <Box height={"24px"} />
        )}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      <img
        src="/assets/family_gift_logo_white.svg"
        alt="Family Gift Logo"
        style={{ height: "150px", padding: "8px" }}
      />
      <Typography
        variant="h5"
        padding={"16px"}
        fontWeight={"500"}
        color={"primary.light"}
        textAlign={"center"}
      >
        {t("alreadyHere")}
      </Typography>
      <Button
        sx={{
          marginTop: "24px",
          marginLeft: "auto",
          marginRight: "auto",
          color: "primary.main",
        }}
        disableElevation
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          borderRadius: 50,
        }}
        variant="contained"
        onClick={() =>
          props.loginType == LoginType.Login
            ? props.setLoginType(LoginType.Signup)
            : props.setLoginType(LoginType.Login)
        }
      >
        {t("connection")}
        </Button>
    </Box>
  );
};

export default LoginFormulaire;
