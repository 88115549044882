import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { fetchData, postData } from "../../api/apiService";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "./Pages/HouseHolds/HouseDetailPage";
import MyWaitingDialog from "../ReusableComponents/MyWaitingDialog";
import { apiClient } from "../../api/api";
import { MailBodyResetPassword } from "../../models/apiBody/BodyLoginWithMail";

function AskResetPasswordPage() {
  const { t, i18n } = useTranslation();
  var languageCodeUsed = i18n.language.split("-")[0];
  const navigate = useNavigate();
  const [mail, setMail] = useState("");
  const [sendingMail, setSendingMail] = useState(false);
  const [errorCode, setErrorCode] = useState(undefined);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSendingMail(true);
    apiClient
      .sendMailToResetPassword(
        new MailBodyResetPassword(mail, languageCodeUsed)
      )
      .then(() => {
        setSendingMail(false);
        setErrorCode(
          t("resetPasswordMailSent")
        );
      })
      .catch((error) => {
        setSendingMail(false);
        var message = "";
        switch (error.response.status) {
          case 499:
            message = t("adressMailNotFound");
            break;
          case 498:
            message = t("adressDoesntRequirePassword");
            break;
          default:
            message = t("errorSendingMail");
            break;
        }
        setErrorCode(message);
      });
  };

  return (
    <Box
      sx={{
        padding: "16px",
      }}
    >
      <MyWaitingDialog
        open={sendingMail}
        message={t("sendingResetMail")}
      />

      <Box
        sx={{
          margin: "auto",
          marginTop: "16px",
          borderRadius: "40px",
          bgcolor: "primary.light",
          padding: "20px",
          width: "auto",
          maxWidth: "800px",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          fontSize={20}
          padding={2}
          fontWeight={"bold"}
          color={"primary"}
        >
          {t("reinitPassword")}
        </Typography>
        <img
          src="/assets/family_gift_logo_simple.svg"
          alt="Family Gift Logo"
          style={{ width: "100%", maxWidth: "200px", padding: "8px" }}
        />
        {errorCode == undefined ? (
          <Box>
            <Typography padding={1} fontSize={14}>
              {t("giveMailAdress")}
            </Typography>
            <Typography padding={1} fontSize={14} fontWeight={"bold"}>
              {t("linkAvailableOnly48hours")}
            </Typography>
            <Typography padding={1} fontSize={14}>
              {t("ifYouDontReceiveMail")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                required
                variant="outlined"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                value={mail}
                fullWidth
                color="primary"
                type="email"
                InputProps={{
                  style: {
                    borderRadius: "16px",
                  },
                }}
                label={t("yourMailAddress")}
                sx={{
                  margin: "8px",
                  background: "white",
                  borderRadius: "16px",
                }}
              />

              <PrimaryButton
                sx={{
                  marginTop: "24px",
                }}
                type="submit"
              >
                {t("sendMail")}
              </PrimaryButton>
            </form>
          </Box>
        ) : (
          <>
            <Typography fontSize={18} fontWeight={"bold"} color={"primary"}>
              {errorCode}
            </Typography>
            <PrimaryButton onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}>
              {t("goBack")}
            </PrimaryButton>
          </>
        )}
      </Box>
    </Box>
  );
}

export default AskResetPasswordPage;
