import { Routes, Route, redirect, Navigate, useNavigation, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import AppBarFG from "./Components/NavBar/AppBarFG";
import { ThemeProvider, Typography, createTheme, responsiveFontSizes, styled } from "@mui/material";
import HomePage from "./Components/HomePage/HomePage";
import FAQPage from "./Components/FAQPage/FAQPage";
import DownloadPage from "./Components/DownloadPage/DownloadPage";
import AboutPage from "./Components/AboutPage/AboutPage";
import Footer from "./Components/NavBar/Footer";
import ContactPage from "./Components/ContactPage/ContactPage";
import { QueryClient, QueryClientProvider } from "react-query";
import ResetPasswordPage from "./Components/ResetPasswordPage";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import FamilyGiftWebApp from "./Components/FamilyGiftWebApp/FamilyGiftWebApp";
import LoginPage from "./Components/FamilyGiftWebApp/LoginPage/LoginPage";
import AuthProvider, { AuthContext } from "./api/AuthProvider";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import SettingsProvider from "./utils/SettingsProvider";
import { useContext, useEffect } from "react";
import AskResetPasswordPage from "./Components/FamilyGiftWebApp/AskResetPasswordPage";


let theme = createTheme({
  palette: {
    primary: {
      main: "#845ac0",
      light: "#E1D5FFFF",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#e2b4bd",
      light: "#FFDFEFFF",
    },
  },
});

theme = createTheme(theme, {
  palette: {
    white: theme.palette.augmentColor({
      color: {
        main: "#FFFFFF",
      },
      name: "white",
    }),
  },
});

theme = responsiveFontSizes(theme);

const queryClient = new QueryClient();

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#E1D5FFFF',
    color:"#845ac0",
    fontWeight:"bold",
    boxShadow:"0px",
    borderRadius:"50px"
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: '#E1D5FFFF',
    color:"#845ac0",
    fontWeight:"bold",
    borderRadius:"50px"
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#845ac0',
    color:"#ffffff",
    fontWeight:"bold",
    borderRadius:"50px"
  },
  '&.notistack-MuiContent-error': {
    fontWeight:"bold",
    borderRadius:"50px"
  },
 
}))
function FamilyGiftApp() {
  document.title = "Family Gift"
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SettingsProvider>
        <SnackbarProvider 
        Components={{
          success: StyledMaterialDesignContent,
          default: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent
        }}
        maxSnack={10}>
          <QueryClientProvider client={queryClient}>
            <div className="App">
              <Routes>
                <Route path="login" element={<LoginPage />} />
                <Route
                  path="app/*"
                  element={
                    <div>
                      <FamilyGiftWebApp />
                    </div>
                  }
                />
                <Route
                  path="/"
                  element={
                    <div>
                      <AppBarFG />
                      <HomePage />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="about"
                  element={
                    <div>
                      <AppBarFG />
                      <AboutPage />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="download"
                  element={
                    <div>
                      <AppBarFG />
                      <DownloadPage />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="faq"
                  element={
                    <div>
                      <AppBarFG />
                      <FAQPage />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="contact"
                  element={
                    <div>
                      <AppBarFG />
                      <ContactPage />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="resetPasswordMail"
                  element={
                    <div>
                      <AppBarFG />
                      <ResetPasswordPage />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="resetPassword"
                  element={
                    <div>
                      <AppBarFG />
                      <AskResetPasswordPage/>
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="privacy"
                  element={
                    <div>
                      <AppBarFG />
                      <PrivacyPolicy />
                    </div>
                  }
                />
              </Routes>
            </div>
          </QueryClientProvider>
        </SnackbarProvider>
        </SettingsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default FamilyGiftApp;
