import { UserLightWithoutWishlist } from "./User";

export class Participation {
  amount: number;
  participationType: number;
  user: UserLightWithoutWishlist;

  constructor(amount: number,participationType: number,user: UserLightWithoutWishlist) {
      this.amount = amount;
      this.participationType = participationType;
      this.user = user;
  };
}

export class ParticipationGiftDto {
  giftId: number;
  participationType: number;
  amount: number;

  constructor(giftId: number, participationType: number, amount: number) {
    this.giftId = giftId;
    this.amount = amount;
    this.participationType = participationType;
  }
}
