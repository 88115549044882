import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const AccordionItemFAQ = (props) => {

  return (
    <Accordion sx={{
        bgcolor:"primary.light",
        marginTop:"8px",
        marginLeft: '16px', 
        marginRight:'16px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary'/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant='p' fontWeight={'bold'} color={'dark'}>
            {props.item.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography whiteSpace={"pre-line"}>
          {props.item.content}
          </Typography>
        </AccordionDetails>
      </Accordion>
  )
}

export default AccordionItemFAQ