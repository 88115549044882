import { useTheme } from "@emotion/react";
import {
  Box,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import LoginFormulaire from "./LoginFormulaire";
import SignupFormulaire from "./SignupFormulaire";
import { useLocation } from "react-router-dom";

export default function LoginPage() {
  const [loginType, setLoginType] = useState(LoginType.Login);
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("md"));
  var location = useLocation();
  var pathToGo = (location.state?.pathToGo ?? "/app/myList") as string;

  return isMobile ? (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        bgcolor: "primary.light",
        transition: "all 500ms",
      }}
    >
      {loginType == LoginType.Login ? (
        <LoginFormulaire
          loginType={loginType}
          setLoginType={setLoginType}
          pathToGo={pathToGo}
        />
      ) : (
        <SignupFormulaire
          loginType={loginType}
          setLoginType={setLoginType}
          pathToGo={pathToGo}
        />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: loginType == LoginType.Login ? "75%" : "25%",
          minHeight: "100vh",
          bgcolor:
            loginType == LoginType.Login ? "primary.light" : "primary.main",
          transition: "all 500ms",
          display: "flex",
          paddingLeft: "12px",
          paddingRight: "12px",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <LoginFormulaire
          loginType={loginType}
          setLoginType={setLoginType}
          pathToGo={pathToGo}
        />
      </Box>
      <Box
        sx={{
          width: loginType == LoginType.Signup ? "75%" : "25%",
          minHeight: "100vh",
          paddingLeft: "12px",
          paddingRight: "12px",
          bgcolor:
            loginType == LoginType.Signup ? "primary.light" : "primary.main",
          transition: "all 500ms",
        }}
      >
        <SignupFormulaire
          loginType={loginType}
          setLoginType={setLoginType}
          pathToGo={pathToGo}
        />
      </Box>
    </Box>
  );
}

export enum LoginType {
  Login = "login",
  Signup = "signup",
}
