import { useTheme } from "@emotion/react";
import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import UploadableNetworkImage from "../../../ReusableComponents/UploadableNetworkImage";
import { CreateHouseDto } from "../../../../models/House";
import { apiClient } from "../../../../api/api";
import { AuthContext } from "../../../../api/AuthProvider";
import MyWaitingDialog from "../../../ReusableComponents/MyWaitingDialog";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { useNavigate, useNavigation } from "react-router-dom";
import { PrimaryButton } from "./HouseDetailPage";
import { useTranslation } from "react-i18next";

function CreateHouseholdPage() {

  const { t } = useTranslation();

  document.title = t("createHousehold");

  let theme = useTheme();
  let [houseImageUrl, setHouseImageUrl] = useState("");
  let [houseName, setHouseName] = useState("");
  let auth = useContext(AuthContext);
  let [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const createHouseSubmitForm = async (event: any) => {
    event.preventDefault();
    var dto = new CreateHouseDto(
      houseName, houseImageUrl
    );
    setLoading(true);
    apiClient
      .createHousehold(dto, auth?.token ?? "")
      .then((data) => {
        auth?.refreshUser();
        setLoading(false);
        enqueueSnackbar(t("householdCreated"), {
          variant: "success",
        });
        navigate(`/app/households/${data.data.id}`, {
          replace:true,
          state: {house: data.data}});

      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  return (
    <Box
      sx={{
        display:'flex',
        flexDirection:"column",
        maxWidth: "800px",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <MyWaitingDialog open={loading} message="Création de votre foyer en cours..."/>
      <Typography textAlign={"center"} fontSize={24} fontWeight={"bold"} color={"primary"}>
        {t("createNewHousehold")}
      </Typography>
      <Typography textAlign={"center"} paddingTop={4} fontSize={18} fontWeight={"bold"} color={"primary"}>
        {t("byCreatingAHousehold")}
      </Typography>
      <Typography textAlign={"center"} paddingBottom={4} fontSize={16}>
        {t("housholdOnlyMethodToShare")}
      </Typography>
      <form 
      onSubmit={createHouseSubmitForm}
      style={{ width: "100%", maxWidth: "800px", alignItems:"center", flexDirection:"column", display:"flex" }}>
      <Box sx={{
        width: "40%",
        maxWidth:"200px",
        aspectRatio:"1",
      }}>
      <UploadableNetworkImage
        initialUrl={houseImageUrl}
        alt={"Photo de votre foyer"}
        onUrlChange={(url) => {
          setHouseImageUrl(url);
        }}
      />
      </Box>

      <TextField
                required
                variant="outlined"
                onChange={(e) => setHouseName(e.target.value)}
                error={houseName.length == 0}
                value={houseName}
                fullWidth
                color="primary"
                type="text"
                InputProps={{
                  style: {
                    borderRadius: "16px",
                  },
                }}
                label={t("householdName")}
                sx={{
                  maxWidth:"600px",
                  marginTop: "16px",
                  background: "white",
                  borderRadius: "16px",
                }}
              />
              <PrimaryButton
            sx={{
              marginTop:"24px",
              maxWidth:"400px"
            }}
            type="submit"
          >
              {t("createThisHousehold")}
          </PrimaryButton>
      </form>
      
    </Box>
  );
}

export default CreateHouseholdPage;
