import React from 'react'
import Carousel from 'react-material-ui-carousel'
import CarouselItem from './CarouselItem'
import { useTranslation } from 'react-i18next';

function MyCarousel() {
    
    const { t } = useTranslation();

    var items = [
        {
            title: t("carouselItemTitle1"),
            subtitle: t("carouselItemSubtitle1"),
            image:"/assets/background_1.jpg",
            screenshot: t("screenshot1"),
        },
        {
            title: t("carouselItemTitle2"),
            subtitle: t("carouselItemSubtitle2"),
            image:"/assets/background_3.jpg",
            screenshot: t("screenshot2"),
        },
        {
            title: t("carouselItemTitle3"),
            subtitle: t("carouselItemSubtitle3"),
            image:"/assets/background_2.jpg",
            screenshot: t("screenshot3"),
        }
    ]

    return (
        <Carousel className='carousel' indicators={false}>
            {
                items.map( (item, i) => <CarouselItem key={i} item={item} /> )
            }
        </Carousel>
    )
}


export default MyCarousel