import { Fab, Typography, createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { IconHouseUser } from "../FamilyGiftWebApp/WebAppNavBar/WebAppNavBar";
import { useTheme } from "@emotion/react";
import { color } from "framer-motion";

function MyFab(props: {
  onClick: () => void;
  Icon: React.ElementType;
  text: string;
}) {
  let theme = useTheme();
  let innerTheme = createTheme(theme);
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("sm"));

  return (
    <Fab
      onClick={props.onClick}
      sx={{
        position: "fixed",
        background: "linear-gradient(90deg, rgba(225,213,255,1) 0%, rgba(255,255,255,1) 50%, rgba(225,213,255,1) 100%)",
        bottom: 16,
        right: { xs: 16, sm: 16, md: 16, lg: 16, xl: 240 },
        "& .icon": {
            color: "primary.main",
        },
        border:"2px solid",
        borderColor:"primary.light",
        transition:"all 1250ms cubic-bezier(0.19, 1, 0.22, 1)",
        ":hover" : {
            scale:"1.1"
            }
        
      }}
      variant="extended"
    >

      {isMobile ? <></> :
        <Typography
          textTransform={"none"}
          fontSize={16}
          color={"primary"}
          fontWeight={"bold"}
          paddingRight={"12px"}
        >
          {props.text}
        </Typography>
      }
      <props.Icon className="icon"/>
    </Fab>
  );
}

export default MyFab;
