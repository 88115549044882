import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Rating, Typography, createTheme } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const UserCommentItem = (props) => {

    const theme = useTheme();
    const innerTheme = createTheme(theme);
    const {t} = useTranslation();

    const timestamp = props.commentItem.date
    const time = new Intl.DateTimeFormat(t.languageCode,
      {
       year: 'numeric',
       month:'long',
       day:"2-digit"
     }).format(timestamp);
    
    const MyIcon = styled('img')(({ theme }) => ({
      height: '20px',
      width:'20px',
      objectFit:"contain",
      position:"relative",
      top: 8,
      right: 0,
  }));

  return (
    <Grid item xs={10} sm={6} md={4} lg={4} xl={3} paddingLeft={'4px'} paddingRight={'4px'} paddingBottom={'32px'} alignItems={"stretch"} alignContent={'center'} justifyContent={'center'}> 

    <Box theme={innerTheme} sx={{ 
                marginBottom:"0px",
                display: "flex",
                 flexDirection: "column", 
                 borderRadius: '24px',
                 bgcolor:'primary.light', 
                 padding: '10px', 
                 width:"auto", 
                 alignContent:"center", 
                 justifyContent:"center", 
                 justifyItems:"center"}}>
    <Box sx={{ 
                marginBottom:"0px",
                display: "flex",
                 flexDirection: "row", 
                 width:"auto", 
                 alignContent:"center", 
                 justifyContent:"center", 
                 justifyItems:"center"}}>
      <Rating 
        sx={{
          marginTop:"8px",
          width:"100%"
        }}
        value={props.commentItem.rate}
        
        readOnly/>
      <MyIcon src={props.commentItem.isAndroid ? "/assets/play-store-logo.png" : "/assets/apple-icon.png"}/>

    </Box>
    <Typography 
      sx={{
        marginTop:"8px"
      }}
      variant='p'>{props.commentItem.comment}</Typography>
    </Box>
    <Box sx={{
      display: 'flex',
      flexDirection:"row",
      margin:"4px",
      alignContent:"center", 
      justifyContent:"space-between", 
      justifyItems:"center"
    }}>
      <Typography variant='p' fontSize={13} fontWeight={'bold'} color={'primary'} textAlign={'center'} fontStyle={'italic'}>{props.commentItem.user}</Typography>
      <Typography   variant='p' fontSize={12} fontWeight={'500'} color={'primary'} textAlign={'center'} fontStyle={'italic'}>{time}</Typography>

    </Box>
    </Grid>
  )
}

UserCommentItem.propTypes = {
    languageCode: PropTypes.string,
    comment: PropTypes.string,
    rate: PropTypes.number,
    isAndroid: PropTypes.bool,
    user: PropTypes.string,
    date: PropTypes.number
}

export default UserCommentItem
