import React from 'react'
import Paper from '@mui/material/Paper';
import { useMediaQuery, useTheme } from '@mui/material';

import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

const CarouselItem = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const CarouselPaper = styled(Paper) (({ theme }) => ({
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      margin: '16px',
      alignItems: 'center',
      borderRadius: '32px'
  }));
    
      const backgroundStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 25,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${props.item.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.2,
      };
    
      const contentStyle = {
        padding: '8px',
        zIndex: 5,
        color: 'primary',
      };
      
      const Screenshot = styled('img')(({ theme }) => ({
        height: isSmallMobile ? '350px' : isMobile ? '80%':'120%',
        transform:isMobile ? '':'rotate(10deg)',
        aspectRatio: isMobile ? 1/2 : 1,
        objectFit: 'cover',
        objectPosition: 'top'
    }));
    
      const rowStyle = {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: isSmallMobile ? 'column-reverse':'row',  
        height: isSmallMobile ? 'auto': 400,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25
      };
  return (
    <CarouselPaper theme={theme}>
      <div className = "background-image-carousel" style={backgroundStyle}></div>
      <div style = {rowStyle}>
        <div style={contentStyle}>
          <Typography 
            variant="h3"
            color={'primary'}
            fontWeight={'bold'}
            padding={'8px'}
            textAlign={'center'}
            >{props.item.title}</Typography>
          <Typography variant="h6"
          color={'primary'}
          fontWeight={'600'}
          padding={'8px'}
          textAlign={'center'}

          >{props.item.subtitle}</Typography> </div>
    <Screenshot src={props.item.screenshot} theme={theme} />
        
</div>
    </CarouselPaper>
  );
};

export default CarouselItem;