import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { LoginType } from "./LoginPage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { CalendarToday, Visibility, VisibilityOff, Warning, WarningAmberOutlined, WarningOutlined, WarningRounded } from "@mui/icons-material";
import {
  CalendarIcon,
  DateField,
  DatePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import {
  BodyLoginWithFacebook,
  BodyLoginWithGoogle,
  BodyLoginWithMail,
  BodySigninWithApple,
  BodySignupWithMail,
  FeedbackAppleLogin,
  LoginProvider,
} from "../../../models/apiBody/BodyLoginWithMail";
import { apiClient } from "../../../api/api";
import axios, { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import {
  CredentialResponse,
  GoogleLogin,
  googleLogout,
  useGoogleLogin,
} from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { hover } from "@testing-library/user-event/dist/hover";
import AppleLogin from "react-apple-login";
import { getDecodedToken, saveAppleElement, saveLoginState } from "../../../localStorageUtils";
import { AuthContext } from "../../../api/AuthProvider";
import { fbLogin, getFacebookLoginStatus } from "../../../utils/FacebookSDK";
const SignupFormulaire = (props: {
  loginType: LoginType;
  setLoginType: React.Dispatch<React.SetStateAction<LoginType>>;
  pathToGo: string
}) => {
  const { t, i18n } = useTranslation();
  var languageCodeUsed = i18n.language.split("-")[0];
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState<Dayjs | null>(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorSignup, setErrorSignup] = useState("");
  const [signupInProgress, setSignupInProgress] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("md"));
  const today = dayjs();
  let auth = useContext(AuthContext);

  function successLogin() {
    navigate(props.pathToGo, {
      replace: true,
      });
   auth?.setUserConnected(true);
   auth?.setToken(getDecodedToken());
  }

  const appleResponse = async (response: FeedbackAppleLogin) => {
    console.log(response)
    const body = new BodySigninWithApple(
      ' ',
      response.authorization.id_token,
      response.authorization.code,
      response.authorization.code
    );
    const loginWithApple = await apiClient.signinWithApple(body).then( responseApi => {
      console.log(response)
      saveLoginState(LoginProvider.Apple, "" , "", responseApi.data.token);
      saveAppleElement(response.authorization.id_token as string, responseApi.data.refreshToken);
      setSignupInProgress(false);
      successLogin();
    }
      )
      .catch((error) => {
        switch ((error as AxiosError).response?.status) {
          case 498:
            setErrorSignup(
              t("adressMailUsedWithPassword")
            );
            return;
          case 499:
            setErrorSignup(t("addressMailAlreadyUsed"));
            return;
          default:
            t("serverError");
            return;
        }
      });
  };

  async function signupWithFacebook() {
    console.log("LOGIN FACEBOOK");
    setSignupInProgress(true);
    var userId = -1;
    var accessToken = "";
    var logged = await getFacebookLoginStatus();
    if(logged.status == "connected"){
      console.log("LOGGED ANSWER", logged);
      userId = Number(logged.authResponse.userID);
      accessToken = logged.authResponse.accessToken;
    } else {
      var loginStatus = await fbLogin();
      userId = Number(loginStatus.authResponse.userID);
      accessToken = loginStatus.authResponse.accessToken;
    }
      await apiClient
      .signinWithFacebook(new BodyLoginWithFacebook(userId, accessToken))
      .then((apiToken) => {
        apiClient
      .loginWithFacebook(new BodyLoginWithFacebook(userId, accessToken))
      .then((apiToken) => {
        console.log(apiToken);
        saveLoginState(LoginProvider.Facebook, "", "", apiToken.data.token);
        successLogin();
        setSignupInProgress(false);
      })
      .catch((error) => {
        setSignupInProgress(false);
        switch ((error as AxiosError).response?.status) {
          case 497:
            setErrorSignup("Empty Mail address from Facebook. Did you give the authorization for accessing the mail address ?");
            return;
          case 498:
            setErrorSignup(t("adressMailUsedWithPassword"));
            return;
          case 499:
            setErrorSignup(t("adressMailNotFound"));
            return;
          default:
            t("serverError");
            return;
        }
      });
      })
      .catch((error) => {
        setSignupInProgress(false);
        switch ((error as AxiosError).response?.status) {
          case 497:
            setErrorSignup("Empty Mail address from Facebook. Did you give the authorization for accessing the mail address ?");
            return;
          case 498:
            setErrorSignup(t("adressMailUsedWithPassword"));
            return;
          case 499:
            setErrorSignup(t("adressMailNotFound"));
            return;
          default:
            t("serverError");
            return;
        }
      });
   
  }

  const signinWithMailSubmit = async (event: any) => {
    event.preventDefault();
    setSignupInProgress(true);
    const birthdayString = birthday?.format();
    if (password.length < 7) {
      setSignupInProgress(false);
      setErrorPassword(true);
    } else if (password !== confirmPassword) {
      setSignupInProgress(false);
      setErrorConfirmPassword(true);
    } else {
      const data = new BodySignupWithMail(
        name,
        email,
        password,
        birthdayString
      );
      try {
        const response = await apiClient.signupWithMail(data);
        const loginAfterSignup = await apiClient.login(
          new BodyLoginWithMail(email, password)
        ).then(response => {
          saveLoginState(LoginProvider.MailPassword, email, password, response.data.token);
          successLogin();
        });
        setSignupInProgress(false);
      } catch (error) {
        switch ((error as AxiosError).response?.status) {
          case 499:
            setErrorSignup(t("addressMailAlreadyUsed"));
            setSignupInProgress(false);
            return;
          default:
            setErrorSignup(t("serverError"));
            setSignupInProgress(false);
            return;
        }
      
      }
    }
  };

  async function loginWithGoogle(credentialResponse: CredentialResponse) {
    setSignupInProgress(true)
    let body = new BodyLoginWithGoogle(credentialResponse.credential);
    try {
      await apiClient.signinWithGoogle(body).then(async response => {
        await apiClient.loginWithGoogle(body).then(login => {
          saveLoginState(LoginProvider.Google, "" , "", login.data.token);
          setSignupInProgress(false);
          successLogin();
        })
      });
    } catch (error) {
      setSignupInProgress(false)
      switch ((error as AxiosError).response?.status) {
        case 498:
          t("serverError");
          return;
        case 499:
          setErrorSignup(
            t("addressMailAlreadyUsed")
          );
          return;
        default:
          t("serverError");
          return;
      }
    }
    const result = await apiClient.loginWithGoogle(body);
  }

  return props.loginType == LoginType.Signup ? (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        paddingLeft: "24px",
        paddingRight: "24px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      <img
        src="/assets/family_gift_logo_simple.svg"
        alt="Family Gift Logo"
        style={{ height: "150px", padding: "8px" }}
      />
      <Typography
        variant="h3"
        fontWeight={"bold"}
        color={"primary"}
        textAlign={"center"}
      >
        {t("registerForFree")}
      </Typography>
      <Typography
        variant="h5"
        padding={"16px"}
        fontWeight={"500"}
        color={"primary"}
        textAlign={"center"}
      >
        {t("usingYourSocialNetworks")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <GoogleLogin
          theme="outline"
          size="large"
          text="signup_with"
          type="icon"
          shape="pill"
          context="signup"
          cancel_on_tap_outside={true}
          useOneTap={false}
          onSuccess={(credentialResponse) => {
            loginWithGoogle(credentialResponse);
            console.log(credentialResponse);
          }}
          onError={() => {
            console.log("Login failed");
          }}
        />
        <Button
              sx={{
                marginLeft: "20px",
                marginRight: "20px",
                maxWidth: "35px",
                height: "35px",
                borderRadius: "50px",
                "&:hover": {
                  opacity: "0.7",
                },
              }}
              onClick={() => signupWithFacebook()}
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                minWidth: "35px",
                minHeight: "35px",
                backgroundImage: "url('./assets/Facebook_Logo_Primary.png')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></Button>
        <AppleLogin 
          clientId="com.trempette.familyGiftReactService"
          redirectURI="https://family-gift.fr/login"
          usePopup={true}
          callback={appleResponse}
          scope="email name"
          responseMode="query"
          render={({ onClick }) => (
            <Button
          sx={{
            maxWidth:'35px',
            height:'35px',
            borderRadius:"50px",
            "&:hover": {
              opacity:"0.5"
            },
          }}
          onClick={onClick}
          style=
            {{
              maxWidth: '35px', maxHeight: '35px', minWidth: '35px', minHeight: '35px',
              backgroundImage:"url('./assets/appleid_button@2x.png')",
              backgroundPosition:"center",
              backgroundRepeat:"no-repeat",
              backgroundSize:"contain"}}></Button>
          )}
        />
      </Box>
      <Typography
        variant="h5"
        padding={"16px"}
        fontWeight={"500"}
        color={"primary"}
        textAlign={"center"}
      >
        {t("orManually")}
      </Typography>
      <Box
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "600px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <form onSubmit={signinWithMailSubmit}>
          <TextField
            required
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            value={name}
            fullWidth
            color="primary"
            type="text"
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            label={t("yourName")}
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
            }}
          />

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={languageCodeUsed}
          >
            <DatePicker
              localeText={{
                toolbarTitle: t("yourBirthdate"),
                okButtonLabel: t("validate"),
                cancelButtonLabel: t("cancel"),
                clearButtonLabel: t("clear"),
              }}
              maxDate={today}
              formatDensity="spacious"
              label= {t("yourBirthdate")}
              value={birthday}
              slotProps={{
                actionBar: {
                  actions: ["clear", "cancel", "accept"],
                },
                textField: {
                  InputProps: {
                    style: {
                      borderRadius: "16px",
                    },
                  },
                  fullWidth: true,
                  sx: {
                    marginTop: "16px",
                    background: "white",
                    borderRadius: "16px",
                  },
                },
                toolbar: {
                  hidden: false,
                },
              }}
              onChange={(newValue) => setBirthday(newValue)}
            />
          </LocalizationProvider>
          
          <TextField
            required
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
            color="primary"
            type="email"
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            label={t("yourMailAdress")}
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
            }}
          />

          <TextField
            required
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            label={t("yourPassword2")}
            onChange={(e) => {
              setErrorConfirmPassword(false);
              setErrorPassword(false);
              setPassword(e.target.value);
            }}
            value={password}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "16px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={errorPassword}
            helperText={errorPassword ? t("mustBeAtLeast8characters") : ""}
            color="primary"
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
            }}
          />
          <TextField
            required
            type={showPassword ? "text" : "password"}
            variant="outlined"
            label={t("confirmYourPassword")}
            onChange={(e) => {
              setErrorConfirmPassword(false);
              setErrorPassword(false);
              setConfirmPassword(e.target.value);
            }}
            value={confirmPassword}
            fullWidth
            error={errorConfirmPassword}
            helperText={errorConfirmPassword ? t("unidenticalPasswords") : ""}
            InputProps={{
              style: {
                borderRadius: "16px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            color="primary"
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
            }}
          />
          <LoadingButton
            sx={{
              marginTop: "24px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            disableElevation
            loading={signupInProgress}
            loadingIndicator={t("registration")}
            style={{
              fontWeight: "bold",
              borderRadius: 50,
            }}
            variant="contained"
            color="primary"
            type="submit"
          >
            {t("registration")}
          </LoadingButton>
        </form>
        { errorSignup.length !== 0 ?
        <Box 
        sx={{
          border:2,
          borderColor:"primary.main",
          bgcolor: "#FFFFFF",
          marginTop:"16px",
          borderRadius:"16px",
          padding: "16px",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignItems:"center"
        }}
        >
          <WarningAmberOutlined color="primary" sx={{fontSize: 30}}/>
          <Typography color={"primary.main"} fontWeight={"600"} paddingLeft={"16px"}>{t("errorMessage", {value: errorSignup})}</Typography>
        </Box> : <Box/>}
        {isMobile ? (
          <Box>
            <Divider
              sx={{
                padding: "16px",
              }}
            />
            <Typography
              variant="h6"
              fontWeight={"500"}
              color={"primary"}
              marginTop={"16px"}
            >
              {t("youAlreadyHaveAnAccount")}
            </Typography>
            <Button
              sx={{
                marginTop: "24px",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "24px",
                color: "primary.main",
              }}
              disableElevation
              style={{
                backgroundColor: "white",
                fontWeight: "bold",
                borderRadius: 50,
              }}
              variant="contained"
              onClick={() =>
                props.loginType == LoginType.Login
                  ? props.setLoginType(LoginType.Signup)
                  : props.setLoginType(LoginType.Login)
              }
            >
              {t("login2")}
            </Button>
          </Box>
        ) : (
          <Box height={"24px"} />
        )}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      <img
        src="/assets/family_gift_logo_white.svg"
        alt="Family Gift Logo"
        style={{ height: "150px", padding: "8px" }}
      />
      <Typography
        variant="h5"
        padding={"16px"}
        fontWeight={"500"}
        color={"primary.light"}
        textAlign={"center"}
      >
       {t("youReNewHere")}
      </Typography>
      <Button
        sx={{
          marginTop: "24px",
          marginLeft: "auto",
          marginRight: "auto",
          color: "primary.main",
        }}
        disableElevation
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          borderRadius: 50,
        }}
        variant="contained"
        onClick={() =>
          props.loginType == LoginType.Login
            ? props.setLoginType(LoginType.Signup)
            : props.setLoginType(LoginType.Login)
        }
      >
        {t("registration")}
      </Button>
    </Box>
  );
};

export default SignupFormulaire;
