import { UserLightWithoutWishlist } from "./User";

export class SecretSantaUserPath {
  participants: UserLightWithoutWishlist[];
  forbiddenUsers: UserLightWithoutWishlist[][];

  constructor(
    participants: UserLightWithoutWishlist[],
    forbiddenUsers: UserLightWithoutWishlist[][]
  ) {
    this.participants = participants;
    this.forbiddenUsers = forbiddenUsers;
    this.calculatePath();
  }

  isTherePath: boolean = true;
  edges: Edge[] = [];

  edgesArrayContainsThisOne(edge: Edge): boolean {
    var index = this.edges.findIndex((element: Edge) => {  
        return edge.equivalent(element)
    })
    return index != -1
  }

  updateForbiddentGroups(forbiddenUsers: UserLightWithoutWishlist[][]) {
    this.forbiddenUsers = forbiddenUsers;
    this.calculatePath();
  }

  hamiltonianPaths(
    participant: UserLightWithoutWishlist,
    discoveredUser: UserLightWithoutWishlist[],
    path: UserLightWithoutWishlist[]
  ) {
    if (
      path.length == this.participants.length &&
      this.edgesArrayContainsThisOne(new Edge(
        path.at(0) as UserLightWithoutWishlist,
        path.at(path.length - 1) as UserLightWithoutWishlist
      ))
    ) {
      return;
    }

    this.getAdjacentForUser(participant).forEach((adj, index) => {
      if (!discoveredUser.includes(adj)) {
        discoveredUser.push(adj);
        path.push(adj);
        this.hamiltonianPaths(adj, discoveredUser, path);
        if (
          path.length == this.participants.length &&
          this.edgesArrayContainsThisOne(new Edge(
            path.at(0) as UserLightWithoutWishlist,
            path.at(path.length - 1) as UserLightWithoutWishlist
          ))){
          return;
        } else {
          var pathIndex = path.indexOf(adj);
          var discoveredUserIndex = discoveredUser.indexOf(adj);
          path.splice(pathIndex, 1);
          discoveredUser.splice(discoveredUserIndex, 1);
        }
      } 
    }
  );
  }

  calculatePath() {
    let discoveredUserLight: UserLightWithoutWishlist[] = [];
    let path: UserLightWithoutWishlist[] = [];
    this.calculateNodes();
    for (let i = 0; i < this.participants.length; i++) {
      discoveredUserLight = [];
      path = [];
      path.push(this.participants[i]);
      discoveredUserLight.push(this.participants[i]);
      this.hamiltonianPaths(this.participants[i], discoveredUserLight, path);
      if (
        path.length == this.participants.length &&
        this.edgesArrayContainsThisOne(new Edge(
          path.at(0) as UserLightWithoutWishlist,
          path.at(path.length - 1) as UserLightWithoutWishlist
        ))
        
      ) {
        this.isTherePath = true;
        return;
      } else {
        this.isTherePath = false;
      }
    }
  }

  calculateNodes() {
    this.edges = [];
    this.participants.forEach((user) => {
      this.getAdjacentForUser(user).forEach((adjacent) => {
        var node = new Edge(user, adjacent);
        if (!this.edgesArrayContainsThisOne(node)) {
          this.edges.push(node);
        }
      });
    });
  }

  getAdjacentForUser(
    user: UserLightWithoutWishlist
  ): UserLightWithoutWishlist[] {
    let feedbackList = this.participants;
    feedbackList = feedbackList.filter((value) => value.id != user.id);
    this.forbiddenUsers.forEach((list) => {
      if (list.includes(user)) {
        list.forEach(
          (userTemp) =>
            (feedbackList = feedbackList.filter(
              (value) => value.id != userTemp.id
            ))
        );
      }
    });

    return feedbackList;
  }
}

class Edge {
  first: UserLightWithoutWishlist;
  second: UserLightWithoutWishlist;

  constructor(
    first: UserLightWithoutWishlist,
    second: UserLightWithoutWishlist
  ) {
    this.first = first;
    this.second = second;
  }

  equivalent(edge:Edge): boolean {
    return (this.first.id == edge.first.id && this.second.id == edge.second.id) || (this.second.id == edge.first.id && this.first.id == edge.second.id)
  }
}




