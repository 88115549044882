import { useMemo, useState, createContext, useEffect } from "react";
import {
  getAddGiftNotifications,
  getBirthdayNotifications,
  getDeleteGiftNotifications,
  getGeneralNotifications,
  getSecretSantaNotifications,
  getShowNotifDialog,
  getSortOrderStorage,
  hideParticipationsStorage,

} from "../localStorageUtils";

interface ISettingsContext {
  hideParticipation: boolean;
  setHideParticipations: React.Dispatch<
    React.SetStateAction<ISettingsContext["hideParticipation"]>
  >;
  generalNotifications: boolean;
  setGeneralNotifications: React.Dispatch<
    React.SetStateAction<ISettingsContext["generalNotifications"]>
  >;
  birthdayNotifications: boolean;
  setBirthdayNotifications: React.Dispatch<
    React.SetStateAction<ISettingsContext["birthdayNotifications"]>
  >;
  addGiftNotifications: boolean;
  setAddGiftNotifications: React.Dispatch<
    React.SetStateAction<ISettingsContext["addGiftNotifications"]>
  >;
  deleteGiftNotifications: boolean;
  setDeleteGiftNotifications: React.Dispatch<
    React.SetStateAction<ISettingsContext["deleteGiftNotifications"]>
  >;
  secretSantaNotifications: boolean;
  setSecretSantaNotifications: React.Dispatch<
    React.SetStateAction<ISettingsContext["secretSantaNotifications"]>
  >;
  sortOrder: SortOrder;
  setSortOrder: React.Dispatch<
    React.SetStateAction<ISettingsContext["sortOrder"]>
  >;
  showNotifDialog: boolean;
  setShowNotifDialog: React.Dispatch<
    React.SetStateAction<ISettingsContext["showNotifDialog"]>
  >;
}

export const SettingsContext = createContext<ISettingsContext | null>(null);

const SettingsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [hideParticipation, setHideParticipations] = useState<
    ISettingsContext["hideParticipation"]
  >(hideParticipationsStorage());
  const [generalNotifications, setGeneralNotifications] = useState<
    ISettingsContext["generalNotifications"]
  >(getGeneralNotifications());
  const [birthdayNotifications, setBirthdayNotifications] = useState<
    ISettingsContext["birthdayNotifications"]
  >(getBirthdayNotifications());
  const [addGiftNotifications, setAddGiftNotifications] = useState<
    ISettingsContext["addGiftNotifications"]
  >(getAddGiftNotifications());
  const [deleteGiftNotifications, setDeleteGiftNotifications] = useState<
    ISettingsContext["deleteGiftNotifications"]
  >(getDeleteGiftNotifications());
  const [secretSantaNotifications, setSecretSantaNotifications] = useState<
    ISettingsContext["secretSantaNotifications"]
  >(getSecretSantaNotifications());
  const [sortOrder, setSortOrder] = useState<
    ISettingsContext["sortOrder"]
  >(getSortOrderStorage());
  const [showNotifDialog, setShowNotifDialog] = useState<
  ISettingsContext["showNotifDialog"]
>(getShowNotifDialog());

  const value = useMemo(
    () => ({
      hideParticipation,
      setHideParticipations,
      generalNotifications,
      setGeneralNotifications,
      birthdayNotifications,
      setBirthdayNotifications,
      addGiftNotifications,
      setAddGiftNotifications,
      deleteGiftNotifications,
      setDeleteGiftNotifications,
      secretSantaNotifications,
      setSecretSantaNotifications,
      sortOrder,
      setSortOrder,
      showNotifDialog,
      setShowNotifDialog
    }),
    [hideParticipation, generalNotifications, birthdayNotifications, addGiftNotifications, deleteGiftNotifications, secretSantaNotifications, sortOrder, showNotifDialog]
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;

export enum SortOrder {
    ALPHABETICALLY = "alpha",
    OLD_TO_RECENT = "old_to_recent",
    RECENT_TO_OLD = "recent_to_old",
    PRICE = "price",
    RATE = "rate"    
  }

