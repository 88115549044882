import { useEffect, useState } from "react";
import { House } from "../../../../../models/House";
import { UserLightWithoutWishlist } from "../../../../../models/User";
import {
  Box,
  Grid,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import AvatarPicture from "../../../../ReusableComponents/AvatarPicture";
import { OutlinedButton, PrimaryButton } from "../HouseDetailPage";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import { Flipped, Flipper } from "react-flip-toolkit";
import { SecretSantaUserPath } from "../../../../../models/SecretSantaUserPath";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

function ForbiddenGroupsPage(props: {
  house: House;
  participants: UserLightWithoutWishlist[];
  forbidenGroups: UserLightWithoutWishlist[][];
  onValidate: (list: UserLightWithoutWishlist[][]) => void;
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [tempForbiddenGroup, setTempForbiddenGroup] = useState<
    UserLightWithoutWishlist[][]
  >(clone(props.forbidenGroups));

  var path = new SecretSantaUserPath(props.participants, [
    ...tempForbiddenGroup,
  ]);
  const [isTherePath, setIsTherePath] = useState(true);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 10 },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 50,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  useEffect(() => {
    path = new SecretSantaUserPath(
      props.participants,
      clone(tempForbiddenGroup)
    );
    setIsTherePath(path.isTherePath);
  }, [tempForbiddenGroup]);

  useEffect(() => {
    if (!isTherePath) {
      enqueueSnackbar(t("tooManyConstraints"), {
        key: "snack_no_path",
        variant: "error",
        persist: !isTherePath,
      });
    } else {
      closeSnackbar("snack_no_path");
    }
    return () => {
      closeSnackbar("snack_no_path");
    };
  }, [isTherePath]);

  const [activeId, setActiveId] = useState<{
    id: UserLightWithoutWishlist;
    groupIndex: number;
  } | null>(null);

  const addParticipantsInGroup = (e: DragEndEvent) => {
    const newItem = activeId?.id as UserLightWithoutWishlist;
    const originUser = activeId?.groupIndex as number;
    const indexItem = e.over?.data?.current?.index as number;
    setActiveId(null);
    var lists = clone(tempForbiddenGroup);
    if (indexItem != null && newItem != null) {
      if (indexItem === -1) {
        lists.push([newItem]);
      } else {
        if (!lists.at(indexItem)?.includes(newItem)) {
          lists.at(indexItem)?.push(newItem);
        }
      }
    }
    setTempForbiddenGroup(clone(lists));
  };

  function removeParticipantsInGroup(
    indexOrigin: number,
    user: UserLightWithoutWishlist
  ) {
    const newItem = user;
    var lists = clone(tempForbiddenGroup);
    const indexItem = indexOrigin;
    if (indexItem != null && newItem != null) {
      if (indexItem > -1) {
        var group = lists.at(indexItem) ?? [];
        if (group.includes(newItem)) {
          group = group?.filter((value) => value.id !== newItem.id);
          lists.splice(indexItem, 1);
          if (group.length > 0) {
            lists = [
              ...lists.slice(0, indexItem),
              group,
              ...lists.slice(indexItem),
            ];
          }
        }
      }
    }
    setTempForbiddenGroup(clone(lists));
  }

  function handleDragStart(event: DragStartEvent) {
    const newItem = event.active.data.current?.user as UserLightWithoutWishlist;
    const originUser = event.active.data.current?.indexGroup as number;
    setActiveId({ id: newItem, groupIndex: originUser });
    removeParticipantsInGroup(originUser, newItem);
  }

  return (
    <DndContext
      onDragEnd={addParticipantsInGroup}
      onDragStart={handleDragStart}
      autoScroll={true}
      sensors={sensors}
    >
      {isMobile ? (
        <Box
          sx={{
            marginBottom: "130px",
            width: "100%",
            left: "0",
            right: "0",
            top: "64px",
            zIndex: "2",
          }}
          display={"flex"}
          flexDirection={"column"}
        >
          <ParticpantsForbiddenGroupsHorizontal
            participants={props.participants}
          />

          <Typography
            sx={{ alignSelf: "stretch" }}
            textAlign={"center"}
            fontSize={20}
            fontWeight={"bold"}
            color={"primary"}
            padding={"4px"}
          >
            {t("forbiddenGroups")}
          </Typography>
          <Typography textAlign={"center"} fontSize={18} padding={"4px"}>
            {t("dragNdropExplanation")}
          </Typography>
          <Box
            sx={{
              padding: "8px",
              marginLeft: "12px",
              marginRight: "12px",
              boxSizing: "border-box",
              borderRadius: "16px",
              bgcolor: "primary.light",
              marginBottom: "100px",
              flex: "1",
            }}
          >
            <Flipper flipKey={`${tempForbiddenGroup.length}`}>
              <Grid container>
                {tempForbiddenGroup.map((item, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={4}>
                      <ForbiddenGroup list={item} index={i} />
                    </Grid>
                  );
                })}
                <Grid key={-1} item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <EmptyForbiddenGroup />
                </Grid>
              </Grid>
            </Flipper>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            minHeight: "calc(100vh - 64px)",
            width: "100vw",
            left: "0",
            right: "0",
            top: "64px",
            bottom: "0",
          }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            sx={{ alignSelf: "stretch" }}
            textAlign={"center"}
            fontSize={20}
            fontWeight={"bold"}
            color={"primary"}
            padding={"4px"}
          >
            {t("forbiddenGroups")}
          </Typography>
          <Typography textAlign={"center"} fontSize={18} padding={"4px"}>
            {t("dragNdropExplanation")}
          </Typography>
          <Box
            sx={{
              boxSizing: "border-box",
              overflow: "hidden",
              display: "flex",
              flex: 1,
            }}
          >
            <ParticpantsForbiddenGroupsVertical
              participants={props.participants}
            />
            <Box
              sx={{
                padding: "8px",
                marginLeft: "12px",
                marginRight: "12px",
                boxSizing: "border-box",
                borderRadius: "16px",
                bgcolor: "primary.light",
                marginBottom: "100px",
                flex: "1",
              }}
            >
              <Flipper flipKey={`${tempForbiddenGroup.length}`}>
                <Grid container>
                  {tempForbiddenGroup.map((item, i) => {
                    return (
                      <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={4}>
                        <ForbiddenGroup list={item} index={i} />
                      </Grid>
                    );
                  })}
                  <Grid key={-1} item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <EmptyForbiddenGroup />
                  </Grid>
                </Grid>
              </Flipper>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          height: "70px",
          width: "100%",
          flexDirection: "row",
          bgcolor: "#ffffff",
          position: "fixed",
          alignContent: "center",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
          justifyItems: "center",
          bottom: "0",
          zIndex: "3",
        }}
      >
        <OutlinedButton
          sx={{ margin: "8px", boxSizing: "border-box" }}
          onClick={(event) => {
            event.preventDefault();
            navigate(-1);
          }}
        >
          {t("cancel")}
        </OutlinedButton>
        <PrimaryButton
          sx={{ margin: "8px", boxSizing: "border-box" }}
          disabled={!isTherePath}
          onClick={(event) => {
            event.preventDefault();
            navigate(-1);
            var lists = clone(tempForbiddenGroup);
            lists = lists.filter((item) => item.length > 1);
            props.onValidate(lists);
          }}
        >
          {t("validate")}
        </PrimaryButton>
      </Box>

      <DragOverlay dropAnimation={null}>
        {activeId ? (
          <UserItemForbiddenGroups
            key={activeId.id.id}
            user={activeId.id}
            indexGroup={activeId.groupIndex}
          />
        ) : (
          <></>
        )}
      </DragOverlay>
    </DndContext>
  );
}

function ForbiddenGroup(props: {
  list: UserLightWithoutWishlist[];
  index: number;
}) {
  const { isOver, setNodeRef } = useDroppable({
    id: "group_user_" + props.index,
    data: {
      index: props.index,
    },
  });
  const {t} = useTranslation();

  var errorOnePerson = props.list.length < 2;
  if (errorOnePerson) {
    var text = t("groupMustContainTwoPersons");
  } else {
    var text = t("xWontExchangeGifts", {value: props.list.map((item, i) => item.username).join(", ")});
  }

  return (
    <Flipped
      key={`${props.list.map((item, i) => item.id).join("-")}`}
      flipId={`${props.list.map((item, i) => item.id).join("-")}`}
      spring={"stiff"}
    >
      <div ref={setNodeRef}>
        <Box
          sx={{
            margin: "4px",
            padding: "0.5rem",
            borderStyle: "dashed",
            borderWidth: "4px",
            borderRadius: "24px",
            borderColor: errorOnePerson ? "red" : "primary.main",
            bgcolor: "#ffffff",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "4px 4px 4px 4px",
              borderRadius: "20px",
              borderColor: errorOnePerson ? "red" : "primary.light",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: isOver ? "primary.light" : "#ffffff",
              justifyContent: "space-evenly",
              transition: "all 0.3s",
            }}
          >
            <Typography
              color={errorOnePerson ? "red" : "primary"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              {text}
            </Typography>
            <Flipper flipKey={`${props.list.map((item) => item.id).join("-")}`}>
              <Box
                sx={{
                  padding: "4px 4px 4px 4px",
                  borderRadius: "20px",
                  borderColor: errorOnePerson ? "red" : "primary.light",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  transition: "all 0.3s",
                }}
              >
                {props.list.map((item, i) => {
                  return (
                    <Flipped key={`${i}`} flipId={`${i}`}>
                      <UserItemForbiddenGroups
                        key={i}
                        user={item}
                        indexGroup={props.index}
                      />
                    </Flipped>
                  );
                })}
              </Box>
            </Flipper>
          </Box>
        </Box>
      </div>
    </Flipped>
  );
}

function EmptyForbiddenGroup() {
  const { isOver, setNodeRef } = useDroppable({
    id: "empty_group_user",
    data: {
      index: -1,
    },
  });
  const {t} = useTranslation();
  return (
    <Flipped
      key={"empty_group_user"}
      flipId={"empty_group_user"}
      spring={"stiff"}
    >
      <div ref={setNodeRef}>
        <Box
          sx={{
            margin: "4px",
            padding: "0.5rem",
            borderStyle: "dashed",
            borderWidth: "4px",
            borderRadius: "24px",
            borderColor: "primary.main",
            bgcolor: "#ffffff",
            height: "180px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyItems: "stretch",
            justifyContent: "stretch",
          }}
        >
          <Box
            sx={{
              padding: "4px 4px 4px 4px",
              borderRadius: "20px",
              borderColor: "primary.main",
              height: "100%",
              width: "100%",
              bgcolor: isOver ? "primary.main" : "#ffffff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              transition: "all 0.3s",
            }}
          >
            <Box
              sx={{
                aspectRatio: 1,
                borderRadius: "200px",
                borderStyle: "solid",
                borderWidth: "4px",
                borderColor: isOver ? "#ffffff" : "primary.main",
                bgcolor: isOver ? "primary.main" : "#ffffff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                transition: "all 0.3s",
              }}
            >
              <Typography
                textAlign={"center"}
                color={isOver ? "#ffffff" : "primary"}
                fontWeight={"bold"}
                fontSize={32}
              >
                +
              </Typography>
            </Box>
            <Typography
              textAlign={"center"}
              color={isOver ? "#ffffff" : "primary"}
              fontWeight={"bold"}
            >
              {t("pleaseDragAndDrop")}
            </Typography>
          </Box>
        </Box>
      </div>
    </Flipped>
  );
}

function ParticpantsForbiddenGroupsVertical(props: {
  participants: UserLightWithoutWishlist[];
}) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const listener = (event: any) => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", listener, { passive: true });
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  const {t} = useTranslation();

  return (
    <Box
      sx={{
        marginTop: `${scrollPosition}px`,
        height: "calc(100vh - 250px)",
        marginLeft: "12px",
        transition: "all 200ms",
      }}
    >
      <Typography textAlign={"center"} fontWeight={"bold"} color={"primary"}>
        {t("participants")}
      </Typography>

      <Box
        sx={{
          height: "calc(100vh - 300px)",
          display: "flex",
          flexFlow: "column wrap",
        }}
      >
        {props.participants.map((item, i) => {
          return (
            <UserItemForbiddenGroups
              key={item.id}
              user={item}
              indexGroup={-1}
            />
          );
        })}
      </Box>
    </Box>
  );
}

function ParticpantsForbiddenGroupsHorizontal(props: {
  participants: UserLightWithoutWishlist[];
}) {
  return (
    <Box
      sx={{
        position: "fixed",
        height: "auto",
        padding: "4px",
        bottom: "70px",
        width: "100vw",
        zIndex: "5000",
        bgcolor: "white.main",
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
        overflowY: "clip",
      }}
    >
      {props.participants.map((item, i) => {
        return (
          <UserItemForbiddenGroups key={item.id} user={item} indexGroup={-1} />
        );
      })}
    </Box>
  );
}

function UserItemForbiddenGroups(props: {
  user: UserLightWithoutWishlist;
  indexGroup: number;
}) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.user.id + "_" + props.indexGroup,
    data: { user: props.user, indexGroup: props.indexGroup },
  });

  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "4px",
          padding: "8px",
          boxSizing: "border-box",
          borderRadius: "8px",
          border: "solid 2px",
          borderColor: "primary.main",
          bgcolor: "primary.light",
          width: "100px",
          aspectRatio: "3/4",
          transition: "all 0.3s",
          "&:hover": {
            scale: "1.1",
          },
        }}
      >
        <Box sx={{ aspectRatio: "1/1", flex: "1" }}>
          <AvatarPicture url={props.user.photoUrl} alt={props.user.username} />
        </Box>
        <Typography textAlign={"center"}>{props.user.username}</Typography>
      </Box>
    </div>
  );
}

export function clone(
  list: UserLightWithoutWishlist[][]
): UserLightWithoutWishlist[][] {
  return list.map((arr) => arr.slice());
}

export default ForbiddenGroupsPage;
