import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function ErrorWidget(props: { errorCode: number; errorMessage: string }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={"/assets/sad.svg"}
        alt="error_image"
        style={{
          padding: "4px",
          height: "200px",
          aspectRatio: "1/1",
        }}
      />
      <Typography
        fontSize={18}
        textAlign={"center"}
        fontWeight={"bold"}
        color={"primary"}
        padding={"4px"}
      >
        {t("errorX", { value: props.errorCode })}
      </Typography>
      <Typography
        fontSize={18}
        textAlign={"center"}
        fontWeight={"bold"}
        color={"primary"}
        padding={"4px"}
      >
        {props.errorMessage}
      </Typography>
    </Box>
  );
}

export default ErrorWidget;
