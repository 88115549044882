import React, { useEffect, useState } from "react";
import {
  getLocalizationStorage,
  setLocalisationStorage,
} from "../../localStorageUtils";
import {
  Box,
  IconButton,
  Menu,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from "@mui/material";

interface Language {
  code: string;
  label: string;
  flags: string;
}

function LanguageDropdown() {
  const languages: Language[] = [
    { code: "en", label: "English", flags: "/assets/en.svg" },
    { code: "fr", label: "Français", flags: "/assets/fr.svg" },
    { code: "es", label: "Español", flags: "/assets/es.svg" },
    { code: "it", label: "Italiano", flags: "/assets/it.svg" },
    { code: "nl", label: "Nederlands", flags: "/assets/nl.svg" },
    { code: "pt", label: "Português", flags: "/assets/pt.svg" },
    { code: "de", label: "Deutsch", flags: "/assets/de.svg" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    getLocalizationStorage() ?? "en"
  );

  const [selectedLang, setSelectedLang] = useState<Language>();

  useEffect(() => {
    const selectedLangTemp = languages.find(
      (lang) => lang.code === selectedLanguage
    );
    if (selectedLangTemp != undefined) {
      setSelectedLang(selectedLangTemp);
    }
  }, [selectedLanguage]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedCode = event.target.value as string;
    setSelectedLanguage(event.target.value as string);
    console.log(`Langue sélectionnée : ${selectedCode}`);
    setLocalisationStorage(selectedCode);
    window.location.reload();
  };

  return (
    <Select
      value={selectedLanguage}
      variant="standard"
      disableUnderline
      onChange={handleChange}
      renderValue={(selected) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", paddingLeft:"8px"}}>
            <img
              src={selectedLang?.flags}
              alt={selectedLang?.code}
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </Box>
        );
      }}
      IconComponent={() => null} // Retirer la flèche
      style={{ padding: 0 }}
      inputProps={{ sx: { padding: "0 !important" } }}
      sx={{
        padding: 0,
        border: "none", // Retirer la bordure
        boxShadow: "none",
        transition: "all 0.2s",
        "&:hover": {
          scale: "1.5",
        },
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "& .MuiSelect-icon": {
          width: "0px",
          display: "none", // Optionnel : pour cacher l'icône de sélection
        },
      }}
    >
      {languages.map((lang) => (
        <MenuItem key={lang.code} value={lang.code}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={lang.flags}
              alt={lang.code}
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
              }}
            />

            <Typography>{lang.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}

export default LanguageDropdown;
