import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { apiClient } from "../../../api/api";
import { Participation } from "../../../models/Participation";
import { AuthContext } from "../../../api/AuthProvider";
import { UserLightWithoutWishlist } from "../../../models/User";
import { Gift, getAffiliatedLink } from "../../../models/Gift";
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import MyNetworkImage from "../../ReusableComponents/MyNetworkImage";
import AvatarPicture from "../../ReusableComponents/AvatarPicture";
import PriceTag, {
  TagSize,
  getPriceWithCurrency,
} from "../../ReusableComponents/PriceTag";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../../utils/SettingsProvider";
import { enqueueSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { OutlinedButton, PrimaryButton } from "./HouseHolds/HouseDetailPage";
import useSessionStorage from "../../../utils/useSessionStorage";
import { Warning, WarningOutlined, WarningRounded } from "@mui/icons-material";

function MyParticipationsPage() {
  var auth = useContext(AuthContext);
  const { t } = useTranslation();

  const [users, setUsers] = useState<Gift[][]>([]);
  const [totalAmont, setTotalAmout] = useState<number>(0.0);
  const [showWarning, setShowWarning] = useState(false);

  document.title = t("myParticipations");

  const { isLoading, error, data } = useQuery({
    queryKey: ["myParticipation"],
    queryFn: () => apiClient.getMyParticipations(auth?.token ?? ""),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (data !== undefined) {
      var myMap: Gift[][] = [];
      getListOfUsers(data).forEach((item) => {
        myMap.push(getGiftsIParticipateForThisUser(item.id, data));
      });
      setUsers(myMap);
      var amount = 0.0;
      data.forEach((item) => {
        var thisPart = getMyParticipationForThisGift(auth?.user?.id ?? 0, item);
        amount = amount + (thisPart?.amount ?? 0.0);
        if (item.price == 0.0) {
          setShowWarning(true);
        }
      });
      setTotalAmout(amount);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("errorRetrievingParticipations"), { variant: "error" });
    }
  }, [error]);

  return (
    <div className="list-page">
      {isLoading ? (
        <></>
      ) : (
        <div>
          <Typography variant="h4" color="primary" padding={"8px"}>
            {t("myParticipations")}
          </Typography>
          <Typography variant="h6" color="primary" paddingLeft={"8px"}>
            {t("resumeMyParticipations")}
          </Typography>

          {users.length > 0 && !isLoading ? (
            <>
              <ResumeParticipation
                totalAmount={totalAmont}
                showWarning={showWarning}
              />
              <List sx={{ width: "100%" }}>
                {users.map((item, i) => {
                  return <MyParticipationItemWithUser key={i} gifts={item} />;
                })}
              </List>
            </>
          ) : (
            <Box
              sx={{
                padding: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={"../assets/sad.svg"}
                style={{
                  padding: "4px",
                  height: "150px",
                  aspectRatio: "1/1",
                }}
              />
              <Typography
                fontSize={18}
                textAlign={"center"}
                fontWeight={"bold"}
                color={"primary"}
                padding={"16px"}
              >
                {t("noParticipationYet")}
              </Typography>
            </Box>
          )}
        </div>
      )}
    </div>
  );
}

function ResumeParticipation(props: {
  totalAmount: number;
  showWarning: boolean;
}) {
  var { t, i18n } = useTranslation();
  return (
    <div>
      <Box
        sx={{
          background: "linear-gradient(25deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
          borderRadius:"16px",
          border: "solid 2px",
              borderColor: "primary.main",
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop:"8px",
          padding:"16px",
          marginRight:"auto",
          marginLeft:"auto"
        }}
      >
        <Typography fontSize={18}>
          <Trans
            i18nKey="participationTotalAmount"
            components={[
              <Typography
                display="inline"
                fontWeight={"bold"}
                color={"primary"}
              ></Typography>,
            ]}
            values={{
              value: getPriceWithCurrency(i18n.language, props.totalAmount),
            }}
          />
        </Typography>
        {props.showWarning ? (
          <Box
            display={"flex"}
            marginTop={1}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <WarningRounded color="primary" fontSize="large" />
            <Typography fontStyle={"italic"} fontSize={16} paddingLeft={1}>
              {t("explanationSomeGiftDontHavePrice")}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
}

function MyParticipationItemWithUser(props: { gifts: Gift[] }) {
  var auth = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  var userPart = props.gifts[0].owner;
  return (
    <ListItem>
      <Box sx={{ bgcolor: "white", width: "100%", marginBottom:"32px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px",
          }}
        >
          <Box
            sx={{
              width: "50px",
              height: "50px",
            }}
          >
            <AvatarPicture url={userPart.photoUrl} alt={userPart.username} />
          </Box>
          <Typography
            fontSize={20}
            fontWeight={"bold"}
            color={"primary"}
            marginInlineStart={2}
          >
            {t("yourParticipationForX", {
              count: props.gifts.length,
              value: userPart.username,
            })}
          </Typography>
        </Box>
        <Divider variant="middle" component="li" />
        <Grid container columnSpacing={2}>
          {props.gifts.map((gift) => {
            return (
              <MyParticipationItemGift
                gift={gift}
                key={gift.id}
                participation={getMyParticipationForThisGift(
                  auth?.user?.id ?? 0,
                  gift
                )}
              />
            );
          })}
        </Grid>
      </Box>
    </ListItem>
  );
}

function MyParticipationItemGift(props: {
  gift: Gift;
  participation: Participation | undefined;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();

  const [linkOpened, setLinkOpened] = useSessionStorage("link_opened", []);
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <ButtonBase
        sx={{
          margin: "4px",
          padding: "4px",
          width: "100%",
          border: "solid 2px",
              borderColor: "primary.light",
          background:
            "linear-gradient(120deg, #E1D5FF, #ffffff 50%,#E1D5FF 250%)",
          borderRadius: "20px",
          transition: "all 0.3s",

          "&:hover": {
            scale: "1.1",
            zIndex: 120,
            borderRadius: "16px",
          },
        }}
        onClick={() => {
          navigate(`/app/gift/${props.gift.id}`, {
            state: { gift: props.gift },
          });
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "150px",
              height: "150px",
            }}
          >
            <Box
              sx={{
                width: "150px",
                height: "150px",
              }}
            >
              <MyNetworkImage
                url={props.gift.photoUrl}
                alt={props.gift.name}
                cover={true}
              />
            </Box>
            <PriceTag
              line={false}
              priceToShow={props.gift.price}
              big={isMobile ? TagSize.S : TagSize.M}
              sx={{
                rotate: "8deg",
                position: "absolute",
                right: "6px",
                top: "6px",
                zIndex: "50",
              }}
            />
          </Box>
          <Box
            sx={{
              marginLeft: "4px",
              width: "auto",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Typography
              fontSize={18}
              fontWeight={"bold"}
              color={"primary"}
              textOverflow={"ellipsis"}
              textAlign={"left"}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {props.gift.name}
            </Typography>
            <Typography
              fontSize={16}
              padding={"4px 0px 4px 0px"}
              textAlign={"left"}
            >
              {props.participation?.participationType == 1
                ?<Trans 
                  i18nKey={"youParticipatePartially"}
                  count={props.participation?.amount??0}
                  components={[
                    <Typography
                    fontSize={18}
                      display="inline"
                      fontWeight={"bold"}
                      color={"primary"}
                    ></Typography>,
                  ]}
                  values={{
                    value: getPriceWithCurrency(i18n.language, props.participation?.amount ?? 0),
                  }}
                /> 
               
                :<Trans 
                i18nKey={"youParticipateTotally"}
                count={props.participation?.amount??0}
                components={[
                  <Typography
                    display="inline"
                    fontSize={18}

                    fontWeight={"bold"}
                    color={"primary"}
                  ></Typography>,
                ]}
                values={{
                  value: getPriceWithCurrency(i18n.language, props.participation?.amount ?? 0),
                }}
              /> 
}
            </Typography>
            {getAffiliatedLink(props.gift) ? (
              <Box
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                <OutlinedButton
                  sx={{
                    width: "auto",
                    fontSize: "14px",
                    padding: "2px",
                    margin: "12px 12px 0px 12px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (getAffiliatedLink(props.gift)) {
                      (linkOpened as string[]).push(
                        getAffiliatedLink(props.gift) ?? ""
                      );
                      setLinkOpened(linkOpened);
                      window.open(
                        getAffiliatedLink(props.gift) ?? "",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                >
                  {t("openGiftLink", { count: 1 })}
                </OutlinedButton>
                {linkOpened.includes(getAffiliatedLink(props.gift)) ? (
                  <Typography fontSize={13} fontStyle={"italic"}>
                    {t("youAlreadyOpenedThisLinkDuringSession")}
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </ButtonBase>
    </Grid>
  );
}

function getMyParticipationForThisGift(
  myUserId: number,
  gift: Gift
): Participation | undefined {
  return gift.participations.find((item) => item.user.id === myUserId);
}

function getGiftsIParticipateForThisUser(
  userId: number,
  gifts: Gift[]
): Gift[] {
  return gifts.filter((gift) => gift.owner.id === userId);
}

function getListOfUsers(gifts: Gift[]): UserLightWithoutWishlist[] {
  var users = gifts
    .map((item) => item.owner)
    .sort(function (a, b) {
      if (a.username < b.username) {
        return -1;
      }
      if (a.username > b.username) {
        return 1;
      }
      return 0;
    });

  return users.reduce((accumulator: UserLightWithoutWishlist[], current) => {
    let exists = accumulator.find((item) => {
      return item.id === current.id;
    });
    if (!exists) {
      accumulator = accumulator.concat(current);
    }
    return accumulator;
  }, []);
}

export default MyParticipationsPage;
