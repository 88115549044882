import { Typography, Box, useTheme, useMediaQuery, styled, ButtonBase } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import InfoRateStores from './InfoRateStores';
import UserComments from './UserComments';

function DownloadPage() {

  const {t} = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const Box1 = styled(Box) (({ theme }) => ({
    display: 'flex',
    width:'100%',
    flexDirection:isMobile ? 'column':'row',
    alignItems: 'center',
    justifyContent:'center',
    justifyItems: 'center'
}));

const ScreenContainer = styled(Box) (({ theme }) => ({
  display: 'flex',
  alignContent:'center',
  width: isMobile ? "100%":"700px",
  overflowX:"hidden",
  aspectRatio: 1,
  position: 'relative',
  marginRight: isMobile ? '0px':'64px',
  alignItems: 'center',
}));

const Screenshot1 = styled('img')(({ theme }) => ({
  height: '100%',
  width: 'auto',
  display: 'block',
  position:"absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  objectFit: 'contain',
  zIndex: 8
}));

const Screenshot2 = styled('img')(({ theme }) => ({
  height: '80%',
  width: 'auto',
  display: 'block',
  position:"absolute",
  right: 24,
  top: 0,
  bottom: 0,
  margin: 'auto',
  objectFit: 'contain',
  transform:'rotate(15deg)',

  zIndex: 6
}));

const Screenshot3 = styled('img')(({ theme }) => ({
  height: '80%',
  width: 'auto',
  display: 'block',
  position:"absolute",
  left: 24,
  top: 0,
  bottom: 0,
  margin: 'auto',
  objectFit: 'contain',
  transform:'rotate(-15deg)',

  zIndex: 5
}));

const DownloadButton = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'contain',
}));

const DownloadContainer = styled(Box) (({ theme }) => ({
  display: 'flex',
  width:'100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: isMobile ? 'center':'start',
  justifyItems: 'center'
}));

const RateStoreContainer = styled(Box) (({ theme }) => ({
  display: 'flex',
  width:'100%',
  flexDirection: isMobile ? 'column':'row',
  alignItems: 'center',
  justifyContent: 'center',
  justifyItems: 'center'
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  width: "50%",
  maxWidth:"300px",
  padding:"16px",
}));

  return (
    <div className='container-page'>
      <Box1>
        <ScreenContainer>
          <Screenshot1 src={t("screenshot1")}/>
          <Screenshot2 src={t("screenshot2")}/>
          <Screenshot3 src={t("screenshot3")}/> 
        </ScreenContainer>
        <Box xs={{
          width: "auto"
        }}>
          <Typography variant='h2' textAlign={isMobile ? "center":"left"} padding={'16px'} fontWeight={'bold'} color={'primary'}>{t("downloadFamilyGiftNow")}</Typography>
          <Typography variant='h4' textAlign={isMobile ? "center":"left"} padding={'16px'} fontWeight={'500'} color={'dark'} >{t("dontWaitToPleaseYourLovedOnes")}</Typography>
          <DownloadContainer>
          <ImageButton href={t('androidUrl')} target="_blank">
          <DownloadButton src={t("androidDownload")}/>
          </ImageButton>
          <ImageButton href={t('iosUrl')} target="_blank">
          <DownloadButton src={t("iosDownload")}/>
          </ImageButton>
          </DownloadContainer>
        </Box>
        </Box1>
        <Typography variant='h4' textAlign={"center"} padding={'16px'} fontWeight={'bold'} color={'primary'}>{t("whatUsersThinks")}</Typography>

        <RateStoreContainer>
          <InfoRateStores isAndroid={true}/>
          <InfoRateStores isAndroid={false}/>
        </RateStoreContainer>
        <Typography variant='h6' textAlign={"center"} padding={'16px'} fontWeight={'bold'} color={'primary'}>{t("moreThan50000Users")}</Typography>
        <UserComments />
        </div>
  )
}



export default DownloadPage