import React from "react";
import MyNetworkImage from "./MyNetworkImage";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";

function AvatarPicture(props: {
  alt: String;
  url: String | undefined | null;
}) {

    let theme = useTheme();
  return (
 
    <Box
      sx={{
        height:"100%",
          width:"100%",
        aspectRatio:"1/1",
        bgcolor:"primary.main",
        borderRadius: "150px",
        alignItems:"center",
        justifyContent:"center",
        boxSizing:"border-box",
        border:"3px solid",
        borderColor:"primary.main",
        display:"flex",
        flexDirection:"column",
      }}
    >
      <Box
        sx={{
          height:"100%",
          width:"100%",
          alignContent:"center",
          alignItems:"center",
          justifyContent:"center",
          justifyItems:"center",
          justifySelf:"center",
          alignSelf:"center",
          overflow:"hidden",
          clipPath:"border-box",
          borderRadius: "550px",
        }}
      >
        <MyNetworkImage url={props.url} alt={props.alt} showBorder={false} cover={true}/>
      </Box>
    </Box>
  );
}

export default AvatarPicture;
