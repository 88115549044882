import styled from '@emotion/styled'
import React from 'react'
import { Box, Grid, Typography, createTheme, useTheme } from '@mui/material';

const InfoCard = (props) => {

    const theme = useTheme();
    const innerTheme = createTheme(theme);

    let MyIcon = styled('img')(({ theme }) => ({
        height: '75px',
        padding: '16px',
        objectFit: 'contain',
        objectPosition: 'top'
    }));

    const MyItem = styled(Box) (({ theme }) => ({
        display: 'flex',
        flexDirection:'column',
        position: 'relative',
        margin: '8px',
        padding: '8px',
        alignItems: 'center',
        borderRadius: '32px',
        background: theme.palette.primary.light
    }));


  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>    
        <MyItem theme={innerTheme}>
            <Box sx={{ borderRadius: '500px', bgcolor:'primary.main', padding: '4px', marginTop: '16px'}}>
                <MyIcon src={props.item.icon}/>
            </Box>
            <Typography variant='h6' fontWeight={'600'} whiteSpace={"pre-line"} textAlign={'center'} paddingTop={'16px'} color={'primary'}>{props.item.title}</Typography>
            <Typography variant='p' fontWeight={'500'} textAlign={'justify'} padding={'16px'} color={'dark.main'}>{props.item.subtitle}</Typography>
        </MyItem>
    </Grid>

  )
}

export default InfoCard