import { LoginProvider } from "./models/apiBody/BodyLoginWithMail";
import AES from 'crypto-js/aes';
import { enc } from 'crypto-js';
import { User } from "./models/User";
import { SortOrder } from "./utils/SettingsProvider";
import { Sort } from "@mui/icons-material";

export const lsLoginProviderKey = "loginProvider";
export const lsMailKey = "mailAdress";
export const lsPasswordKey = "password";
export const lsTokenKey = "token";
export const lsTokenExpiration = "tokenExp";
export const lsAppleIdIdentifier = "appleIdentifier";
export const lsAppleRefreshToken = "appleRefreshToken";
export const lsFcmToken = "lsFcmToken";
export const lsUser = "user";
export const lsHideParticipations = "lsHideParticipation"
export const lsGeneralNotifications = "lsGeneralNotifications"
export const lsBirthdayNotifications = "lsBirthdayNotifications"
export const lsAddGiftNotifications = "lsAddGiftNotifications"
export const lsDeleteGiftNotifications = "lsDeleteGiftNotifications"
export const lsSecretSantaNotifications = "lsSecretSantaNotifications"
export const lsSortOrder = "lsSortOrder"
export const lsShowNotifDialog = "lsShowNotifDialog"
export const keyLocalization = "i18nextLng"

export function getLocalizationStorage(): string | null{
    return localStorage.getItem(keyLocalization);
}

export function setLocalisationStorage(value: string) {
    localStorage.setItem(keyLocalization, value);
}

const secretKey = "my_awesome_secret_key"

export function getFcmToken(): string | null{
    return localStorage.getItem(lsFcmToken);
}

export function storeFcmToken(value: string){
    localStorage.setItem(lsFcmToken, value);
}

export function getShowNotifDialog() : boolean {
    var str = localStorage.getItem(lsShowNotifDialog) ?? "true"
    return JSON.parse(str) === true;
}

export function setNotifDialogShown(value: boolean) {
    var str = localStorage.setItem(lsShowNotifDialog, `${false}`);
}

export function hideParticipationsStorage() : boolean {
    var str = localStorage.getItem(lsHideParticipations) ?? "false"
    return JSON.parse(str) === true;
}

export function saveParticipationsStorage(value: boolean) {
    var str = localStorage.setItem(lsHideParticipations, `${value}`);
}

export function getGeneralNotifications() : boolean {
    var str = localStorage.getItem(lsGeneralNotifications) ?? "true"
    return JSON.parse(str) === true;
}

export function saveGeneralNotifications(value: boolean) {
    var str = localStorage.setItem(lsGeneralNotifications, `${value}`);
}

export function getBirthdayNotifications() : boolean {
    var str = localStorage.getItem(lsBirthdayNotifications) ?? "true"
    return JSON.parse(str) === true;
}

export function saveBirthdayNotifications(value: boolean) {
    var str = localStorage.setItem(lsBirthdayNotifications, `${value}`);
}

export function getAddGiftNotifications() : boolean {
    var str = localStorage.getItem(lsAddGiftNotifications) ?? "true"
    return JSON.parse(str) === true;
}

export function saveAddGiftNotifications(value: boolean) {
    var str = localStorage.setItem(lsAddGiftNotifications, `${value}`);
}

export function getDeleteGiftNotifications() : boolean {
    var str = localStorage.getItem(lsDeleteGiftNotifications) ?? "true"
    return JSON.parse(str) === true;
}

export function saveDeleteGiftNotifications(value: boolean) {
    var str = localStorage.setItem(lsDeleteGiftNotifications, `${value}`);
}

export function getSecretSantaNotifications() : boolean {
    var str = localStorage.getItem(lsSecretSantaNotifications) ?? "true"
    return JSON.parse(str) === true;
}

export function saveSecretSantaNotifications(value: boolean) {
    var str = localStorage.setItem(lsSecretSantaNotifications, `${value}`);
}

export function getSortOrderStorage() : SortOrder {
    switch(localStorage.getItem(lsSortOrder)){
        case SortOrder.OLD_TO_RECENT : return SortOrder.OLD_TO_RECENT
        case SortOrder.RECENT_TO_OLD : return SortOrder.RECENT_TO_OLD
        case SortOrder.PRICE : return SortOrder.PRICE
        case SortOrder.ALPHABETICALLY : return SortOrder.ALPHABETICALLY
        case SortOrder.RATE : return SortOrder.RATE
        default : return SortOrder.OLD_TO_RECENT

    }

}



export function saveSortOrderStorage(value: SortOrder) {
    localStorage.setItem(lsSortOrder, value);
}

export function deleteLocalStorageAfterLogout(){
    var currentLanguage = getLocalizationStorage();
    //localStorage.removeItem(lsLoginProviderKey);
    //localStorage.removeItem(lsMailKey);
    //localStorage.removeItem(lsPasswordKey);
    //localStorage.removeItem(lsTokenKey);
    //localStorage.removeItem(lsFcmToken);
    //localStorage.removeItem(lsTokenExpiration);
    //localStorage.removeItem(lsAppleIdIdentifier);
    //localStorage.removeItem(lsAppleRefreshToken);
    localStorage.clear();
    sessionStorage.clear();
    if(currentLanguage){
        setLocalisationStorage(currentLanguage);
    }
}

export function saveLoginState(provide: LoginProvider, mail: string, password: string, token: string){
    localStorage.setItem(lsLoginProviderKey, provide);
    localStorage.setItem(lsMailKey, encrypt(mail));
    localStorage.setItem(lsPasswordKey, encrypt(password));
    localStorage.setItem(lsTokenKey, encrypt(token));
    var currentDateTime = new Date();
    var expDate = currentDateTime.setTime(currentDateTime.getTime() + 24 * 60 * 60 * 1000);
    //var expDate = currentDateTime.setTime(currentDateTime.getTime() + 2 * 60 *1000);

    localStorage.setItem(lsTokenExpiration, expDate.toString());
}

export function saveAppleElement(clientId: string, appleRefreshToken: string){
    localStorage.setItem(lsAppleIdIdentifier, encrypt(clientId));
    localStorage.setItem(lsAppleRefreshToken, encrypt(appleRefreshToken));
}

export function getAppleidentifier() : string | undefined {
    var item = localStorage.getItem(lsAppleIdIdentifier);
    if(item === null) return undefined;
    else return decrypt(item);
}

export function getAppleRefreshToken() : string | undefined {
    var item = localStorage.getItem(lsAppleRefreshToken);
    if(item === null) return undefined;
    else return decrypt(item);
}

export function getDecodedMail() : string | undefined {
    var item = localStorage.getItem(lsMailKey);
    if(item === null) return undefined;
    else return decrypt(item);
}

export function getDecodedPassword() : string | undefined {
    var item = localStorage.getItem(lsPasswordKey);
    if(item === null) return undefined;
    else return decrypt(item);
}

export function getDecodedToken() : string | null {
    var item = localStorage.getItem(lsTokenKey);
    if(item === null) return null;
    else return decrypt(item);
}

export function isUserConnected(): Boolean {
    return getDecodedToken() !== undefined && getDecodedToken() !== null && getDecodedToken()?.length !== 0
}

export function saveUser(user: User) {
    var userStr = JSON.stringify(user);
    localStorage.setItem(lsUser, encrypt(userStr));
}

export function getUser(): User | null {
    var userStr = localStorage.getItem(lsUser);
    if(userStr === null) return null;
    var userDecrypted = decrypt(userStr);
    var user = JSON.parse(userDecrypted);
    return user;
}

const encrypt = (txt: string) => {
    return AES.encrypt(txt, secretKey).toString()
}

const decrypt = (value: string) => {
    return AES.decrypt(value, secretKey).toString(enc.Utf8);
}

