import React, { useState } from 'react'
import UserCommentItem from './UserCommentItem'
import { Box, Button, Grid, Typography } from '@mui/material'
import commentsJson from './comment.json'
import { useTranslation } from 'react-i18next'

function UserComments() {

  const {t, i18n} = useTranslation();
  var languageCodeUsed = i18n.language.split("-")[0];

  console.log("Language code used ====> " + languageCodeUsed);
  
  var list = commentsJson;

  var commentsInMyLanguage = list.filter( comment => comment.languageCode === languageCodeUsed);
  var commentsNotInMyLanguage = list.filter( comment => comment.languageCode !== languageCodeUsed);
  commentsInMyLanguage.sort((a,b) => b.date - a.date)
  commentsNotInMyLanguage.sort((a,b) => b.date - a.date)

  const newlist = [...commentsInMyLanguage, ...commentsNotInMyLanguage];

  newlist.forEach(comment => {
    console.log(comment.comment)
    console.log(comment.date)
  } )

  const [commentsToShow, setCommentsToShow] = useState(8);

  var listToDisplay = newlist.slice(0, commentsToShow);

  return (
    <Box>
    <Grid 
          container
          marginTop={'32px'}
          marginBottom={"32px"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          alignContent={"center"}
          columnSpacing={'8px'}
          columns={12}
          >
            {
              listToDisplay.map ((item, i) => <UserCommentItem commentItem={item} key={i}/>)
            }
        </Grid>
        <Typography align='center'>
        <Button 
          disabled = {listToDisplay.length >= list.length}
          color='primary'
          size='large'
          type='submit'
          variant='contained'
          onClick={() => setCommentsToShow(commentsToShow + 8)}
          > {t("seeMoreComments")} </Button> </Typography>
        </Box>
  )
}

export default UserComments