import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  ListItem,
  Toolbar,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  SettingsOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import SelectableButtonWithIcon from "./SelectableButtonWithIcon";
import { Menu } from "@mui/icons-material";
import { AuthContext } from "../../../api/AuthProvider";

function WebAppNavBar() {
  let auth = useContext(AuthContext);
  let location = useLocation();
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("sm"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showBar, setShowBar] = useState(true);
  const hiddenPath = ["/app/gift/", "/app/households/join", "app/households/create"];

  useEffect(() => {
    var value = true;
    hiddenPath.forEach((item, i) => {
      if (value) value = !location.pathname.includes(item);
    });
    setShowBar(value);
  }, [location]);

  const listOfButtons = [
    <SelectableButtonWithIcon
      key={1}
      setCloseDrawer={setOpenDrawer}
      label={auth?.user?.children?.length === 0 ? t("myList") : t("myLists")}
      path="/app/myList"
      pathSelected={["/app/myList"]}
      Icon={IconGift}
    />,
    <SelectableButtonWithIcon
      key={2}
      setCloseDrawer={setOpenDrawer}
      label={t("myHouseholds", {count:auth?.user?.houses.length})}
      path="/app/households"
      pathSelected={["/app/households", "/app/user/"]}
      Icon={IconHouseUser}
    />,
    <SelectableButtonWithIcon
      key={3}
      setCloseDrawer={setOpenDrawer}
      label={t("myParticipations")}
      path="/app/participations"
      pathSelected={["/app/participations"]}
      Icon={HandHoldingHeart}
    />,
    <SelectableButtonWithIcon
      key={4}
      setCloseDrawer={setOpenDrawer}
      label={t("myProfile")}
      path="/app/profile"
      pathSelected={["/app/profile"]}
      Icon={IconProfile}
    />,
    <SelectableButtonWithIcon
      key={5}
      setCloseDrawer={setOpenDrawer}
      label={t("settings")}
      path="/app/settings"
      pathSelected={["/app/settings"]}
      Icon={SettingsOutlined}
    />,
  ];

  return (
    <AppBar
      sx={{
        height:"64px",
        bgcolor: "#ffffff",
        boxShadow: "none",
        overflow: "visible",
      }}
      position="sticky"
    >
      <Toolbar>
        <img
          src="/assets/family_gift_logo_simple.svg"
          alt="Family Gift Logo"
          style={{
            position: "absolute",
            left: "0",
            height: "52px",
            padding: "8px",
          }}
          onClick={() => navigate("/app/myList")}
        />
        {
          <>
            {isMobile ? (
              <>
                <Drawer
                  sx={{
                    visibility: showBar ? "visible" : "collapse",
                    opacity: showBar ? "100%" : "0%",
                    transition: "all 0.3s",
                  }}
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                  anchor="right"
                >
                  <ListItem>
                    <img
                      src="/assets/family_gift_logo_simple.svg"
                      alt="Family Gift Logo"
                      onClick={() => {
                        navigate("/app/myList");
                        setOpenDrawer(!openDrawer);
                      }}
                      style={{
                        height: "100px",
                        width: "100px",
                        padding: "24px",
                        margin: "auto",
                      }}
                    />
                  </ListItem>
                  {listOfButtons.map((item, i) => item)}
                </Drawer>
                <IconButton
                  key={"menukey"}
                  onClick={() => setOpenDrawer(!openDrawer)}
                  sx={{
                    visibility: showBar ? "visible" : "collapse",
                    opacity: showBar ? "100%" : "0%",
                    transition: "all 0.3s",
                  }}
                  style={{
                    position: "fixed",
                    right: "0px",
                    color: innerTheme.palette.primary.main,
                  }}
                >
                  <Menu>{t("menu")}</Menu>
                </IconButton>
              </>
            ) : (
              <Box
                sx={{
                  bgcolor: innerTheme.palette.primary.light,
                  borderRadius: "120px",
                  padding: "8px 16px",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  visibility: showBar ? "visible" : "collapse",
                  opacity: showBar ? "100%" : "0%",
                  transition: "all 0.3s",
                }}
              >
                {listOfButtons.map((item, i) => item)}
              </Box>
            )}
          </>
        }
      </Toolbar>
    </AppBar>
  );
}

export function IconGift(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="1.7em"
      width="1.7em"
      {...props}
    >
      <path d="M880 310H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v200c0 4.4 3.6 8 8 8h40v344c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V550h40c4.4 0 8-3.6 8-8V342c0-17.7-14.3-32-32-32zm-334-74c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70h-70v-70zm-138-70c38.6 0 70 31.4 70 70v70h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70zM180 482V378h298v104H180zm48 68h250v308H228V550zm568 308H546V550h250v308zm48-376H546V378h298v104z" />
    </svg>
  );
}

export function HandHoldingHeart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 576 512"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M148 76.6C148 34.3 182.3 0 224.6 0c20.3 0 39.8 8.1 54.1 22.4l9.3 9.3 9.3-9.3C311.6 8.1 331.1 0 351.4 0 393.7 0 428 34.3 428 76.6c0 20.3-8.1 39.8-22.4 54.1L302.1 234.1c-7.8 7.8-20.5 7.8-28.3 0L170.4 130.7C156.1 116.4 148 96.9 148 76.6zm420.2 259.7c13.1 17.8 9.3 42.8-8.5 55.9l-126.6 93.3c-23.4 17.2-51.6 26.5-80.7 26.5H32c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32h36.8l44.9-36c22.7-18.2 50.9-28 80-28H352c17.7 0 32 14.3 32 32s-14.3 32-32 32h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h120.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384h-.9.9z" />
    </svg>
  );
}

export function IconProfile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 9a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM3 12c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0112.065 14a8.984 8.984 0 017.092 3.458A9 9 0 103 12zm9 9a8.963 8.963 0 01-5.672-2.012A6.992 6.992 0 0112.065 16a6.991 6.991 0 015.689 2.92A8.964 8.964 0 0112 21z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function IconHouseUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M21.664 10.252l-9-8a.999.999 0 00-1.328 0l-9 8a1 1 0 001.328 1.496L4 11.449V21a1 1 0 001 1h14a1 1 0 001-1v-9.551l.336.299a1 1 0 001.328-1.496zM9.184 20a2.982 2.982 0 015.632 0zm1.316-5.5A1.5 1.5 0 1112 16a1.502 1.502 0 01-1.5-1.5zM18 20h-1.101a5 5 0 00-2.259-3.228 3.468 3.468 0 00.86-2.272 3.5 3.5 0 00-7 0 3.468 3.468 0 00.86 2.272A5 5 0 007.1 20H6V9.671l6-5.333 6 5.333z" />
    </svg>
  );
}

export default WebAppNavBar;
