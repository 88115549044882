import styled from '@emotion/styled'
import React from 'react'
import { Box, Grid, Rating, Typography, createTheme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InfoRateStores = ({isAndroid}) => {

    const theme = useTheme();
    const innerTheme = createTheme(theme);
    const {t} = useTranslation();

    const MyIcon = styled('img')(({ theme }) => ({
        height: '100%',
        width:'75px',
        padding: '16px',
        objectFit:"contain",
        objectPosition:'center',
        margin: 'auto'
    }));


  return (
            <Box theme={innerTheme} sx={{ 
                margin: '16px',
                display: "flex",
                 flexDirection: "row", 
                 borderRadius: '40px',
                 bgcolor:'primary.light', 
                 padding: '20px', 
                 width:"auto", 
                 alignContent:"center", 
                 justifyContent:"center", 
                 justifyItems:"center"}}>

                <MyIcon src={isAndroid ? "/assets/play-store-logo.png" : "/assets/apple-icon.png"}/>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent:"center", alignContent:"center", padding: '4px', width:"100%", height:"100%", margin:"auto"}}>
                    <Rating name="half-rating-read" defaultValue={isAndroid ? 4.8:4.9} precision={0.1} readOnly />
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent:"start", alignContent:"end", alignItems:"end",padding: '4px'}}>
                        <Typography variant='h3' fontWeight={'bold'} color={'dark.main'}>{isAndroid ? '4.8':'4.9'} </Typography>
                        <Typography variant='h4' fontWeight={'500'} color={'dark.main'} paddingLeft={"10px"}> / 5 </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent:"center", alignContent:"center", padding: '4px', width:"100%", margin:"auto"}}>
                        <Typography variant='h6' fontWeight={'bold'} color={'dark.main'} textAlign={'center'}>{isAndroid ? t("onPlayStore"):t("onAppStore")}</Typography>
                    </Box>
                </Box>
        </Box>

  )
}

export default InfoRateStores