export class BodyLoginWithMail {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

export class MailBodyResetPassword {
  email: string;
  languageCode: string;

  constructor(email: string, languageCode: string) {
    this.email = email;
    this.languageCode = languageCode;
  }
}

export class BodySignupWithMail {
  username: String;
  email: String;
  password: String;
  photoUrl: String;
  birthdayDate?: string;

  constructor(
    username: String,
    email: String,
    password: String,
    birthdayDate?: string
  ) {
    this.username = username;
    this.email = email;
    this.password = password;
    this.photoUrl = "";
    this.birthdayDate = birthdayDate;
  }
}

export class BodyLoginWithGoogle {
  googleToken: String | undefined;

  constructor(googleToken: String | undefined) {
    this.googleToken = googleToken;
  }
}

export class BodyLoginWithFacebook {
  id: number;
  facebookToken: String | undefined;

  constructor(id: number, facebookToken: String | undefined) {
    this.id = id;
    this.facebookToken = facebookToken;
  }
}

export class BodySigninWithApple {
  givenName: String | undefined;
  appleIdToken: String | undefined;
  authorizationCode: String | undefined;
  userIdentifier: String | undefined;

  constructor(
    givenName: String | undefined,
    appleIdToken: String | undefined,
    authorizationCode: String | undefined,
    userIdentifier: String | undefined
  ) {
    this.givenName = givenName;
    this.appleIdToken = appleIdToken;
    this.authorizationCode = authorizationCode;
    this.userIdentifier = userIdentifier;
  }
}

export class BodyRefreshTokenWithApple {
  appleIdToken: String | undefined;
  refreshToken: String | undefined;

  constructor(
    appleIdToken: String | undefined,
    refreshToken: String | undefined
  ) {
    this.appleIdToken = appleIdToken;
    this.refreshToken = refreshToken;
  }
}

export enum LoginProvider {
  MailPassword = "mail",
  Facebook = "facebook",
  Google = "google",
  Apple = "apple"
}

export type FeedbackAppleLogin = {
  authorization: AuthorizationFeedbackAppleLogin
  user: UserAppleLogin
}

export type UserAppleLogin = {
  email: String
  name: UserNameApple
}

export type UserNameApple = {
  firstName: String,
  lastName: String
}

export type AuthorizationFeedbackAppleLogin = {
  code: String,
  id_token: String
}

export class ApiToken {
  token: string = "";
}

export class ApiTokenApple {
  token: string = "";
  refreshToken: string = "";
}

export class ExcludeHouseDto {
  houseId: number;
  userId: number;
  constructor(houseId: number, userId: number) {
    this.houseId = houseId;
    this.userId = userId;
  }
}

export class UpdateUserDto {
  userName: string;
  profilePictureUrl: string;
  birthdayDate: string;
  constructor(userName: string, profilePictureUrl: string, birthdayDate: string) {
    this.userName = userName;
    this.profilePictureUrl = profilePictureUrl;
    this.birthdayDate = birthdayDate;

  }
}

export class UserChildDto {
  userName: string;
  profilePictureUrl: string;
  birthdayDate: string;
  listHouseVisible: number[];
  
  constructor(userName: string, profilePictureUrl: string, birthdayDate: string, listHouseVisible: number[]) {
    this.userName = userName;
    this.profilePictureUrl = profilePictureUrl;
    this.birthdayDate = birthdayDate;
    this.listHouseVisible = listHouseVisible;
  }
}

