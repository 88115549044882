import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {
  getSortOrderStorage,
  saveSortOrderStorage,
} from "../../localStorageUtils";
import { SettingsContext, SortOrder } from "../../utils/SettingsProvider";
import { Box, Button, Fab, IconButton, Typography } from "@mui/material";
import MyFab from "./MyFab";
import {
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  CheckRounded,
  Favorite,
  FilterAlt,
  KeyboardArrowDown,
  SortByAlpha,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";




function SortOrderMenu(props: { large: boolean }) {
  const settings = useContext(SettingsContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  console.log("Sort order launch page : ", settings?.sortOrder);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    sortOrder: SortOrder
  ) => {
    settings?.setSortOrder(sortOrder);
    saveSortOrderStorage(sortOrder);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const {t} = useTranslation();

  
   function getStringForSortOrder(
    sortOrder: SortOrder | undefined
  ): string {
    switch (sortOrder) {
      case SortOrder.OLD_TO_RECENT:
        return t("oldestToRecent");
      case SortOrder.RECENT_TO_OLD:
        return t("recentToOldest");
      case SortOrder.PRICE:
        return t("byPrice");
      case SortOrder.RATE:
        return t("byRate");
      case SortOrder.ALPHABETICALLY:
        return t("alphabeticalOrder");
      default:
        return "";
    }
  }
  return (
    <div>
      {props.large ? (
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClickListItem}
          startIcon={getIconForSortOrder(settings?.sortOrder, "inherit")}
          endIcon={<KeyboardArrowDown />}
        >
          {getStringForSortOrder(settings?.sortOrder)}
        </Button>
      ) : (
        <IconButton
          sx={{
            borderRadius: "18px",
            bgcolor: "primary.light",
            transition: "all 0.3s",
            "&:hover": {
              scale: "1.1",
              bgcolor: "primary.light",
              zIndex: "150",
            },
          }}
          color="primary"
          size="large"
          onClick={handleClickListItem}
        >
          <FilterAlt fontSize={"large"} />
        </IconButton>
      )}

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <Typography
          fontWeight={"bold"}
          color={"primary"}
          textAlign={"center"}
          padding={2}
        >
          {t("sortGiftList")}
        </Typography>
        <MyMenuItem
          key={0}
          sortOrder={SortOrder.OLD_TO_RECENT}
          onClick={handleMenuItemClick}
        />
        <MyMenuItem
          key={1}
          sortOrder={SortOrder.RECENT_TO_OLD}
          onClick={handleMenuItemClick}
        />
        <MyMenuItem
          key={2}
          sortOrder={SortOrder.RATE}
          onClick={handleMenuItemClick}
        />
        <MyMenuItem
          key={3}
          sortOrder={SortOrder.PRICE}
          onClick={handleMenuItemClick}
        />
        <MyMenuItem
          key={4}
          sortOrder={SortOrder.ALPHABETICALLY}
          onClick={handleMenuItemClick}
        />
      </Menu>
    </div>
  );
}

export function getIconForSortOrder(
  sortOrder: SortOrder | undefined,
  color:
    | "disabled"
    | "action"
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
): React.ReactNode {
  switch (sortOrder) {
    case SortOrder.OLD_TO_RECENT:
      return <CalendarTodayOutlined color={color} />;
    case SortOrder.RECENT_TO_OLD:
      return <CalendarTodayOutlined color={color} />;
    case SortOrder.PRICE:
      return <AttachMoneyOutlined color={color} />;
    case SortOrder.RATE:
      return <Favorite color={color} />;
    case SortOrder.ALPHABETICALLY:
      return <SortByAlpha color={color} />;
    default:
      return <CalendarTodayOutlined color={color} />;
  }
}

function MyMenuItem(props: {
  sortOrder: SortOrder;
  onClick: (event: React.MouseEvent<HTMLElement>, sortOrder: SortOrder) => void;
}) {

  const settings = useContext(SettingsContext);
  let selected = props.sortOrder === settings?.sortOrder;

 const {t} = useTranslation();

  
   function getStringForSortOrder(
    sortOrder: SortOrder | undefined
  ): string {
    switch (sortOrder) {
      case SortOrder.OLD_TO_RECENT:
        return t("oldestToRecent");
      case SortOrder.RECENT_TO_OLD:
        return t("recentToOldest");
      case SortOrder.PRICE:
        return t("byPrice");
      case SortOrder.RATE:
        return t("byRate");
      case SortOrder.ALPHABETICALLY:
        return t("alphabeticalOrder");
      default:
        return "";
    }
  }

  return (
    <MenuItem
      key={5}
      selected={settings?.sortOrder === SortOrder.RATE}
      onClick={(event) => props.onClick(event, props.sortOrder)}
    >
      {getIconForSortOrder(props.sortOrder, "primary")}
      <Typography padding={1}>
        {getStringForSortOrder(props.sortOrder)}
      </Typography>
      {selected ? <CheckRounded color="primary" /> : <></>}
    </MenuItem>
  );
}

export default SortOrderMenu;
