import { use } from "i18next";
import { Gift } from "./Gift";
import { House, HouseWithoutUsers } from "./House";


export class User {
  id: number;
  username: string;
  email: string;
  photoUrl: string;
  wishList: Gift[];
  houses: House[];
  parent: UserLightWithoutWishlist | null;
  children: UserLightWithoutWishlist[];
  birthdayDate: string;

  constructor(
    id: number,
    username: string,
    email: string,
    photoUrl: string,
    wishList: Gift[],
    houses: House[],
    parent: UserLightWithoutWishlist | null,
    children: UserLightWithoutWishlist[],
    birthdayDate: string
  ) {
    this.id = id;
    this.username = username;
    this.birthdayDate = birthdayDate;
    this.email = email;
    this.photoUrl = photoUrl;
    this.wishList = wishList;
    this.houses = houses;
    this.parent = parent;
    this.children = children;
  }

  public equals(object: UserLightWithoutWishlist|User): boolean {
    return this.id == object.id;
  }
}

export function areTheyInCommonSecretSante(user: User | undefined | null, other: User | UserLightWithoutWishlist) {
  var listIdsParticipating = user?.houses.flatMap((item) => item.secretSanta.flatMap((item) => item.giver.id)) ?? [];
  return listIdsParticipating.includes(user?.id??-1) && listIdsParticipating.includes(other.id)
}

export function daysUntilToday(birthdayDate: Date): number | null {
  var currentDate = new Date();
  if (birthdayDate === undefined) return null;
  if (
    birthdayDate.getDay() == currentDate.getDay() &&
    birthdayDate.getMonth() == currentDate.getMonth()
  ) {
    return 0;
  } else {
    birthdayDate.setHours(0);
    birthdayDate.setMinutes(0);
    birthdayDate.setSeconds(0);
    birthdayDate.setMilliseconds(0);

    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);    
    
    birthdayDate.setFullYear(currentDate.getFullYear());
    if (birthdayDate.getTime() < currentDate.getTime()) {
      birthdayDate.setFullYear(birthdayDate.getFullYear() + 1);
    }
    return (
      Math.round(
        (birthdayDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
      )
    );
  }
}

export function daysBeforeNextBirthday(
  user: UserLightWithoutWishlist | User
): number | null {
  var birthdayDate = new Date(user.birthdayDate);
  var currentDate = new Date();
  if (birthdayDate === undefined) return null;
  if (
    birthdayDate.getDate() == currentDate.getDate() &&
    birthdayDate.getMonth() == currentDate.getMonth()
  ) {
  
    return 0;
  } else {
    birthdayDate.setHours(0);
    birthdayDate.setMinutes(0);
    birthdayDate.setSeconds(0);
    birthdayDate.setMilliseconds(0);

    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);    
    
    birthdayDate.setFullYear(currentDate.getFullYear());
    if (birthdayDate.getTime() < currentDate.getTime()) {
      birthdayDate.setFullYear(birthdayDate.getFullYear() + 1);
    }
    return (
      Math.round(
        (birthdayDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
      )
    );
  }
}

export function getBirthdayDate(user: UserLightWithoutWishlist | User): string {
  return new Intl.DateTimeFormat("fr", {
    month: "long",
    day: "2-digit",
  }).format(new Date(user.birthdayDate));
}

export class UserLightWithoutWishlist {
  id: number;
  username: string;
  email: string;
  photoUrl: string;
  houses: HouseWithoutUsers[];
  parent: UserLightWithoutWishlist | null;
  birthdayDate: string;

  constructor(
    id: number,
    username: string,
    email: string,
    photoUrl: string,
    houses: HouseWithoutUsers[],
    parent: UserLightWithoutWishlist | null,
    birthdayDate: string
  ) {
    this.id = id;
    this.username = username;
    this.birthdayDate = birthdayDate;
    this.email = email;
    this.photoUrl = photoUrl;
    this.houses = houses;
    this.parent = parent;
  }

  public equals(object: UserLightWithoutWishlist|User): boolean {
    return this.id == object.id;
  }

}

export class UserInfoWithResetToken {
  token: string
  user: UserLightWithoutWishlist
  constructor(token: string, user: UserLightWithoutWishlist){
    this.token = token;
    this.user = user;
  }
}

export class PasswordBody {
  password: string
  constructor(password: string){
    this.password = password;
  }
}
