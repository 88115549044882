import { useTheme } from '@emotion/react';
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import InfoCard from '../HomePage/InfoCard';
import AccordionItemFAQ from './AccordionItemFAQ';

function FAQPage() {

  const { t } = useTranslation();

  const theme = useTheme();

  var howItWorksItem = [
    {
        title: t('titleStep1'),
        subtitle: t('subtitleStep1'),
        icon: "/assets/family_gift_logo_white.svg",
      },
    {
      title: t('titleStep2'),
      subtitle: t('subtitleStep2'),
      icon: "./assets/house.svg",
  
    },
    {
      title: t('titleStep3'),
      subtitle: t('subtitleStep3'),
      icon: "/assets/tick.svg",
    },
  ]

  var faqItems = [
    {
        title: t('faqQuestion1'),
        content: t("faqAnswer1"),
    },
    {
      title: t('faqQuestion2'),
      content: t("faqAnswer2"),
    },
    {
      title: t('faqQuestion6'),
      content: t("faqAnswer6"),
    },
    {
      title: t('faqQuestion3'),
      content: t("faqAnswer3"),
    },
    {
      title: t('faqQuestion4'),
      content: t("faqAnswer4"),
    },
    {
      title: t('faqQuestion15'),
      content: t("faqAnswer15"),
    },
    {
      title: t('faqQuestion5'),
      content: t("faqAnswer5"),
    },
    
    {
      title: t('faqQuestion7'),
      content: t("faqAnswer7"),
    },
    {
      title: t('faqQuestion16'),
      content: t("faqAnswer16"),
    },
    {
      title: t('faqQuestion8'),
      content: t("faqAnswer8"),
    },
    {
      title: t('faqQuestion9'),
      content: t("faqAnswer9"),
    },
    {
      title: t('faqQuestion10'),
      content: t("faqAnswer10"),
    },
    {
      title: t('faqQuestion11'),
      content: t("faqAnswer11"),
    },
    {
      title: t('faqQuestion12'),
      content: t("faqAnswer12"),
    },
    {
      title: t('faqQuestion13'),
      content: t("faqAnswer13"),
    },
    {
      title: t('faqQuestion14'),
      content: t("faqAnswer14"),
    },

    
  ]
  
  return (
    <div className='container-page'>
        <Typography 
          variant='h3'
          textAlign={"center"} 
          color={'primary'}
          margin='8px'
          padding='8px'
          fontWeight={'600'}>{t("howItWorks")}</Typography>
        <Grid 
          container
          marginTop={'32px'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          >
          {
            howItWorksItem.map((item, i) => <InfoCard key={i} item={item}  xs={1}/>)
          }
        </Grid>
        <Typography 
          variant='h3'
          textAlign={"center"} 
          color={'primary'}
          margin='8px'
          padding='8px'
          paddingTop="32px"
          fontWeight={'600'}>{t("faq")}</Typography>
        {
          faqItems.map((item, i) => <AccordionItemFAQ key={i} item={item}/>)
        }
    </div>
  )
}

export default FAQPage