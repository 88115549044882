import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext } from "react";
import DrawerComponent from "./DrawerComponent";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../api/AuthProvider";
import { OutlinedButton } from "../FamilyGiftWebApp/Pages/HouseHolds/HouseDetailPage";
import AvatarPicture from "../ReusableComponents/AvatarPicture";
import LanguageDropdown from "../ReusableComponents/LanguageDropdown";

const AppBarFG = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  let auth = useContext(AuthContext);

  const MyButtonStyle = styled(NavLink)(({ theme }) => ({
    color: "white",
    fontWeight: "bold",
    alignItems: "center",
    borderRadius: "32px",
    marginLeft: "16px",
    fontSize: 16,
    margin: "0px",
    padding: "16px",
    textDecoration: "none",
    transition:"all 0.2s",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "white",
      color: theme.palette.primary.main,
    },
  }));

  const FamilyGiftButtonStyle = styled(NavLink)(({ theme }) => ({
    flexGrow: 1,
    color: "white",
    fontWeight: "bold",
    borderRadius: "32px",
    fontSize: 20,
    margin: "8px",
    padding: "8px",
    textDecoration: "none",
  }));

  const LoginButtonStyle = styled(NavLink)(({ theme }) => ({
    backgroundColor: "white",
    paddingLeft: "32px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    borderRadius: "32px",
    fontSize: 16,
    margin: "0px",
    padding: "16px 24px",
    width: "auto",
    textDecoration: "none",
    transition:"all 0.2s",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "white",
      color: theme.palette.primary.main,
    },
  }));
  return (
    <AppBar position="sticky">
      <Toolbar
        
        >
        <img
          src="/assets/family_gift_logo_white.svg"
          alt="Family Gift Logo"
          style={{ height: "52px", padding: "8px" }}
          onClick={() => navigate("/")}
        />
        <FamilyGiftButtonStyle to="/">Family Gift</FamilyGiftButtonStyle>
        {isMobile ? (
          <DrawerComponent isUserConnected={auth?.userConnected} />
        ) : (
          <Box sx={{
            display:"flex",
            alignItems:"center",
            alignContent:"center",
            justifyItems:"center",
            justifyContent:"center"
          }}>
            <MyButtonStyle to="/">{t("home")}</MyButtonStyle>
            <MyButtonStyle to="/download">{t("theApplication")}</MyButtonStyle>
            <MyButtonStyle to="/faq">{t("howItWorksTitle")}</MyButtonStyle>
            <MyButtonStyle to="/contact">{t("contact")}</MyButtonStyle>
            <MyButtonStyle to="/about">{t("about")}</MyButtonStyle>
            {auth?.userConnected && !auth?.userReconnected ? "Reconnexion" :  auth?.userReconnected ? (
              <OutlinedButton
              sx={{paddingLeft:"16px", paddingRight:"16px"}}  
              onClick={() => navigate("/app/myList")}
              endIcon={
                  <Box width={"35px"} height={"35px"} >
                    <AvatarPicture
                      alt={auth?.user?.username as string}
                      url={auth.user?.photoUrl}
                    />
                  </Box>
                }
              >
                <Typography fontSize={18} fontWeight={"bold"}>
                  {t("myApp")}
                </Typography>
              </OutlinedButton>
            ) : (
              <LoginButtonStyle to="/login">{t("login")}</LoginButtonStyle>
            )}
            <LanguageDropdown/>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppBarFG;
