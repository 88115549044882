import { Box, Typography, createTheme, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { House } from "../../../../../models/House";
import { useTheme } from "@emotion/react";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../../../../../api/AuthProvider";
import { apiClient } from "../../../../../api/api";
import InfoSecretSanta from "./InfoSecretSanta";
import CreateSecretSanta from "./CreateSecretSanta";
import { useTranslation } from "react-i18next";

function SecretSantaPage() {
  const {t} = useTranslation();
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  const queryClient = useQueryClient();
  var auth = useContext(AuthContext);
  let { houseId } = useParams();
  const location = useLocation();

  let [house, setHouse] = useState<House | null | undefined>(
    location.state?.house ?? null
  );

  let [amIInThisHouse, setIAmInThisHouse] = useState(true);

  let [secretSantaInProgress, setSecretSantaInProgress] = useState(
    (house?.secretSanta ?? []).length > 0
  );

  const { data, isLoading } = useQuery({
    queryKey: ["house", houseId],
    queryFn: () => apiClient.getHouseById(auth?.token ?? "", houseId),
    refetchOnWindowFocus: true,
  });

  const handleRefetch = () => {
    queryClient.refetchQueries(["house", houseId]);
  };

  useEffect(() => {
    if (data !== undefined) {
      setHouse(data);
    }
  }, [data]);

  useEffect(() => {
    var usersId = house?.users.map((item, id) => item.id as number);
    setIAmInThisHouse(
      usersId?.includes((auth?.user?.id as number) ?? -1) ?? false
    );
    setSecretSantaInProgress((house?.secretSanta ?? []).length > 0);
  }, [house]);

  document.title = t("secretSantaForX", {value: house?.name});

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      {house ? (
        secretSantaInProgress ? (
          <InfoSecretSanta house={house} />
        ) : (
          <CreateSecretSanta house={house} />
        )
      ) : (
        <Typography> {t("householdNotFound")} </Typography>
      )}
    </Box>
  );
}


export default SecretSantaPage;
