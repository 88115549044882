import { useTheme } from "@emotion/react";
import { LabelRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type ButtonProps = {
  label: string;
  path: string;
  pathSelected: string[];
  Icon: React.ElementType;
  setCloseDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

function SelectableButtonWithIcon({
  label,
  path,
  pathSelected,
  Icon,
  setCloseDrawer,
}: ButtonProps) {
  const theme = useTheme();
  const innerTheme = createTheme(theme);
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    var value = false;
    pathSelected.forEach((item, i) => {
      if (!value) value = location.pathname.includes(item);
    });
    setSelected(value);
  }, [location]);

  const isMedium = useMediaQuery(innerTheme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(innerTheme.breakpoints.down("sm"));

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [ref]);

  return (
    <Button
      sx={{
        backgroundColor: isMobile
          ? selected
            ? "primary.light"
            : "#ffffff"
          : selected
          ? "#ffffff"
          : "primary.light",
        borderRadius: "120px",
        transition: "all 0.5s",
        marginLeft: "4px",
        marginRight: "4px",
        
        marginTop:isMobile ? "12px" : "0px",
        "&:hover": {
          backgroundColor: "primary.main",
          "& .buttonText": {
            color: "#ffffff",
          },
          "& .icon": {
            color: "#ffffff",
          },
        },
      }}
      onClick={() => {
        navigate(path);
        setCloseDrawer(false);
      }}
      autoCapitalize="false"
    >
      <Box
        sx={{
          flexDirection: "row",
          display: "inline-flex",
          alignContent: "center",
          alignItems: "center",
          paddingRight:"8px",
            paddingLeft:"8px",
          transition: "all 0.5s",
        }}
      >
        <Icon className="icon" />

        <Typography
          sx={{
            width:
              selected || isMobile || !isMedium
                ? ((ref.current?.offsetWidth ?? 0) + 45).toString()
                : "0px",
            opacity: selected || isMobile || !isMedium ? "1" : "0",
            padding: selected || isMobile || !isMedium ? "0px 8px" : "0px",
            transition: "all 0.5s",
          }}
          ref={ref}
          fontSize={"14px"}
          fontWeight={"600"}
          className="buttonText"
          textTransform={"none"}
          color={"primary"}
        >
          {selected || isMobile || !isMedium ? label : ""}
        </Typography>
      </Box>
    </Button>
  );
}

export default SelectableButtonWithIcon;
