import { useContext, useState } from 'react'
import { CreateHouseDto, House } from '../../../../models/House';
import { useTheme } from '@emotion/react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, createTheme } from '@mui/material';
import { AuthContext } from '../../../../api/AuthProvider';
import MyWaitingDialog, { MyPaperPropsDialog } from '../../../ReusableComponents/MyWaitingDialog';
import UploadableNetworkImage from '../../../ReusableComponents/UploadableNetworkImage';
import { OutlinedButton } from './HouseDetailPage';
import { enqueueSnackbar } from 'notistack';
import { apiClient } from '../../../../api/api';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

function EditHouseDialog(props: {
    open: boolean;
    onClose: (house: House|null) => void;
    house: House;
  }) {
    const {t} = useTranslation();
    let [houseName, setHouseName] = useState(props.house.name);
    let [housePicture, setHousePicture] = useState(props.house.pictureUrl ?? "");
    
    let [editInProgress, setEditInProgress] = useState(false);
    let theme = useTheme();
    const innerTheme = createTheme(theme);
    const auth = useContext(AuthContext);
  
    return (
      <Dialog PaperProps={MyPaperPropsDialog} open={props.open} scroll="body" fullWidth>
  
        <MyWaitingDialog
          open={editInProgress}
          message={t("updatingYoutHousehold")}
        />
        <DialogTitle id="responsive-dialog-title">
          <Typography fontWeight={"bold"} color={"primary"} textAlign={"center"}>
            {t("editX", {value: props.house.name})}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            overflow={"visible"}
          >
            <Box width={"150px"} height={"150px"} overflow={"visible"}>
              <UploadableNetworkImage
                initialUrl={housePicture}
                alt={"Upload image of your household"}
                onUrlChange={(url) => {
                  setHousePicture(url);
                }}
              />
            </Box>
          </Box>
  
          <TextField
            required
            error = {(houseName??"").length == 0}
            variant="outlined"
            value={houseName}
            onChange={(e) => {
              setHouseName(e.target.value);
            }}
            fullWidth
            color="primary"
            type="text"
            InputProps={{
              style: {
                borderRadius: "16px",
              },
            }}
            label={t("householdName")}
            sx={{
              marginTop: "16px",
              background: "white",
              borderRadius: "16px",
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
          />
        
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            variant="outlined"
            onClick={() => {
              props.onClose(null);
            }}
          >
            {t("cancel")}
          </OutlinedButton>
          <OutlinedButton
            color="primary"
            variant="outlined"
            onClick={() => {
              setEditInProgress(true);
              
              apiClient
                .editHousehold(new CreateHouseDto(houseName, housePicture), props.house.code, auth?.token ?? "")
                .then((response) => {
                  var newHouse = response.data;
                  setEditInProgress(false);
                  props.onClose(newHouse);
                  auth?.refreshUser()
                  enqueueSnackbar(t("houseHoldUpdated"), { variant: "success" });
                })
                .catch((error: AxiosError) => {
                  setEditInProgress(false);
                  enqueueSnackbar(t("errorX", {value: error.response?.status}) + t("errorUpdatingHousehold"), {
                    variant: "error",
                  });
                });
            }}
            disabled={(houseName??"").length == 0}
          >
            {t("validate")}
          </OutlinedButton>
        </DialogActions>
      </Dialog>
    );
}

export default EditHouseDialog